import React, { useEffect, useState, useRef } from 'react';

import ItemInput from '../ItemInput';
import ItemDate from '../ItemDate';
import { costsControlApi } from '../../../../api';

const ShoppingDetails = (props) => {
   const { item, onEditItem } = props;

   const [materialsOptions, setMaterialsOptions] = useState([]);
   const [toolsOptions, setToolsOptions] = useState([]);
   const [isLoadingMaterials, setIsLoadingMaterials] = useState(true);
   const [isLoadingTools, setIsLoadingTools] = useState(true);
   const [selectedMaterial, setSelectedMaterial] = useState('Seleccione un material');
   const [selectedTool, setSelectedTool] = useState('Seleccione una herramienta');
   const lastValidValue = useRef('');

   const tableTHeads = ['Código', 'Nombre', 'Cantidad', 'Precio', 'Importe'];
   const isLoading = false;
   const isRemovingItem = false;

   const defaultValues = {
      id: 0,
      invoice: '',
      date: `${String(new Date().getDate()).padStart(2, '0')}/${String(
         new Date().getMonth() + 1
      ).padStart(2, '0')}/${new Date().getFullYear()}`,
      provider: '',
      comments: '',
      subtotal: 0,
      iva: 0,
      total: 0,
      materials: [],
   };

   const fetchMaterialsAndTools = async () => {
      const materials = await costsControlApi.getMaterialsAndTools();
      setMaterialsOptions(materials.materials);
      setToolsOptions(materials.tools);
   };

   const validateNumberWithDecimals = (value) => {
      const pattern = /^\d+(\.\d{0,2})?$/;
      return pattern.test(value);
   };

   const handleNumberInput = (e) => {
      let value = e.target.value;

      if (value.length === 0 && /^\d$/.test(lastValidValue.current)) {
         value = '0';
         e.target.value = value;
         return;
      }
      // Elimina el cero inicial si no hay un punto decimal presente
      if (value.startsWith('0') && !value.includes('.')) {
         value = value.slice(1);
         e.target.value = value;
      }

      const isValid = validateNumberWithDecimals(value);

      if (!isValid) {
         e.target.value = lastValidValue.current;
      } else {
         lastValidValue.current = value;
      }
   };

   const handleEditQuantityCell = (currentItem, newValue) => {
      const updatedMaterials = item.materials.map((item) => {
         if (item === currentItem) {
            return { ...item, quantity: newValue };
         } else {
            return item;
         }
      });
      onEditItem(updatedMaterials, 'materials');
   };

   const handleEditPriceCell = (currentItem, newValue) => {
      const updatedMaterials = item.materials.map((item) => {
         if (item === currentItem) {
            return { ...item, price: newValue || 0 };
         } else {
            return item;
         }
      });
      onEditItem(updatedMaterials, 'materials');
   };

   const handleSelectMaterial = (newValue) => {
      const selected = materialsOptions.find((item) => item.id === parseInt(newValue));
      setSelectedMaterial(selected);
   };

   const handleAddMaterial = (selectedItemId) => {
      const selectedItem = materialsOptions.find(
         (item) => item.id === parseInt(selectedItemId)
      );
      if (!selectedItem) return;
      const newSelectedItem = { ...selectedItem, price: 0, quantity: 0 };
      onEditItem(
         item.materials ? [...item.materials, newSelectedItem] : [newSelectedItem],
         'materials'
      );
      setSelectedMaterial('Seleccione un material');
   };

   const handleSelectTool = (newValue) => {
      const selected = toolsOptions.find((item) => item.id === parseInt(newValue));
      setSelectedTool(selected);
   };

   const handleAddTool = (selectedItemId) => {
      const selectedItem = toolsOptions.find(
         (item) => item.id === parseInt(selectedItemId)
      );
      if (!selectedItem) return;
      const newSelectedItem = { ...selectedItem, price: 0, quantity: 0 };
      onEditItem(
         item.materials ? [...item.materials, newSelectedItem] : [newSelectedItem],
         'materials'
      );
      setSelectedTool('Seleccione una herramienta');
   };

   const handleDeleteItem = (selectedItemId) => {
      const selectedItem = item.materials.find(
         (item) => item.id === parseInt(selectedItemId)
      );
      if (!selectedItem) return;
      const filteredItems = item.materials.filter((item) => item.id !== selectedItem.id);
      onEditItem(filteredItems, 'materials');
   };

   useEffect(() => {
      fetchMaterialsAndTools();
      Object.keys(defaultValues).forEach((key) => {
         if (!item[key]) {
            onEditItem(defaultValues[key], key);
         }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (!materialsOptions || materialsOptions.length === 0) return;
      setIsLoadingMaterials(false);
   }, [materialsOptions]);

   useEffect(() => {
      if (!toolsOptions || toolsOptions.length === 0) return;
      setIsLoadingTools(false);
   }, [toolsOptions]);

   useEffect(() => {
      if (!item.materials || item.materials.length === 0) return;
      const total = item.materials.reduce(
         (acc, material) => acc + (material.quantity * material.price || 0),
         0
      );
      onEditItem(total.toFixed(2), 'subtotal');
      onEditItem(0, 'iva');
      onEditItem(total.toFixed(2), 'total');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [item?.materials]);

   return (
      <>
         <div className="flex-container gap-15">
            <ItemInput
               label="Proveedor"
               value={item.provider}
               onValueUpdate={onEditItem}
               attributeToChange="provider"
            />
            <ItemInput
               label="N° Folio"
               value={item.invoice}
               onValueUpdate={onEditItem}
               attributeToChange="invoice"
            />
         </div>
         <div className="white-space-16" />
         <React.Fragment>
            <div className="flex-container gap-15">
               <div className="row full align-center">
                  <div className="row full align-center">
                     <div className="column full">
                        {isLoadingMaterials ? (
                           <div className="justify-center">
                              <i className="fas fa-spinner fa-spin"></i>
                           </div>
                        ) : (
                           <select
                              className="input input-modals"
                              value="0"
                              onChange={(event) =>
                                 handleSelectMaterial(event.target.value)
                              }>
                              <option value={0} disabled>
                                 {selectedMaterial?.name
                                    ? selectedMaterial.name
                                    : selectedMaterial}
                              </option>
                              {materialsOptions?.map((option, key) => {
                                 if (
                                    item.materials?.some(
                                       (selected) => selected.id === option.id
                                    )
                                 ) {
                                    return null;
                                 } else {
                                    return (
                                       <option key={key} value={option.id}>
                                          {option.name}
                                       </option>
                                    );
                                 }
                              })}
                           </select>
                        )}
                     </div>
                     &nbsp; &nbsp;
                     <button
                        type="button"
                        className="color-black"
                        onClick={() => handleAddMaterial(selectedMaterial.id)}
                        disabled={selectedMaterial === 'Seleccione un material'}>
                        <i className="fas fa-plus-circle" />
                     </button>
                  </div>
               </div>
               <div className="row full align-center">
                  <div className="row full align-center">
                     <div className="column full">
                        {isLoadingTools ? (
                           <div className="justify-center">
                              <i className="fas fa-spinner fa-spin"></i>
                           </div>
                        ) : (
                           <select
                              className="input input-modals"
                              value="0"
                              onChange={(event) => handleSelectTool(event.target.value)}>
                              <option value={0} disabled>
                                 {selectedTool?.name ? selectedTool.name : selectedTool}
                              </option>
                              {toolsOptions?.map((option, key) => {
                                 if (
                                    item.materials?.some(
                                       (selected) => selected.id === option.id
                                    )
                                 ) {
                                    return null;
                                 } else {
                                    return (
                                       <option key={key} value={option.id}>
                                          {option.name}
                                       </option>
                                    );
                                 }
                              })}
                           </select>
                        )}
                     </div>
                     &nbsp; &nbsp;
                     <button
                        type="button"
                        className="color-black"
                        onClick={() => handleAddTool(selectedTool.id)}
                        disabled={selectedTool === 'Seleccione una herramienta'}>
                        <i className="fas fa-plus-circle" />
                     </button>
                  </div>
               </div>
            </div>

            <div className="white-space-16"></div>
            <div className="row full align-center">
               <div className="column card-table full align-center">
                  <div className="table-responsive full">
                     <table className="editable-table dinamic-table-scroll">
                        <thead>
                           <tr>
                              {tableTHeads.map((head, index) => {
                                 return <th key={index}>{head}</th>;
                              })}
                              <th className="text-center">Eliminar</th>
                           </tr>
                        </thead>
                        <tbody className="dinamic-tbody">
                           {isLoading ? (
                              <tr>
                                 <td colSpan="3">
                                    <div className="white-space-8"></div>
                                    <div className="justify-center">
                                       <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    <div className="white-space-8"></div>
                                 </td>
                              </tr>
                           ) : item.materials?.length === 0 ? (
                              <tr>
                                 <td className="text-center" colSpan="3">
                                    Sin datos que mostrar
                                 </td>
                              </tr>
                           ) : (
                              item?.materials?.map((item, index) => {
                                 // eslint-disable-next-line no-unused-expressions
                                 item.amount !== item.price * item.quantity
                                    ? (item.amount = (
                                         item.price * item.quantity || 0
                                      ).toFixed(2))
                                    : null;
                                 return (
                                    <tr key={item.name + item.id + index}>
                                       <td>{item.code || ''}</td>
                                       <td>{item.name || ''}</td>
                                       <td className="custom-td">
                                          <input
                                             className="custom-input "
                                             type="text"
                                             value={item.quantity || 0}
                                             onChange={(e) =>
                                                handleEditQuantityCell(
                                                   item,
                                                   parseInt(e.target.value)
                                                )
                                             }
                                          />
                                       </td>
                                       <td className="custom-td">
                                          <div style={{ display: 'flex' }}>
                                             <label
                                                htmlFor={`price-input-${item.id}`}
                                                style={{ marginRight: '5px' }}>
                                                $
                                             </label>
                                             <input
                                                id={`price-input-${item.id}`}
                                                className="custom-input"
                                                type="text"
                                                value={item.price || 0}
                                                onChange={(e) =>
                                                   handleEditPriceCell(
                                                      item,
                                                      e.target.value
                                                   )
                                                }
                                                onInput={handleNumberInput}
                                             />
                                          </div>
                                       </td>
                                       <td>{`$${item.amount}`}</td>

                                       <td className="text-center">
                                          {isRemovingItem ===
                                          item.IdEquipoTipoProblema ? (
                                             <i className="fas fa-spinner fa-spin"></i>
                                          ) : (
                                             <i
                                                className="fas fa-times-circle color-red cursor-pointer space-4"
                                                onClick={() =>
                                                   handleDeleteItem(item.id)
                                                }></i>
                                          )}
                                       </td>
                                    </tr>
                                 );
                              })
                           )}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </React.Fragment>
         <div className="white-space-16" />
         <ItemDate
            label="Fecha"
            date={item.date}
            onValueUpdate={onEditItem}
            attributeToChange="date"
         />{' '}
         <div className="white-space-16" />
         <ItemInput
            label="Comentarios"
            value={item.comments}
            onValueUpdate={onEditItem}
            attributeToChange="comments"
         />
         <div className="column label-medium">
            <p>
               <b>Total: ${item.total || 0}</b>
            </p>
         </div>
      </>
   );
};

export default ShoppingDetails;

import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import GraphCard from './GraphCard';
import { costsGraphApi } from '../../../api';
import { formatMoney } from '../../../utils/formatMoney';

// Código para la actualización dinámica de porcentajes:
/*label: (tooltipItem, data) => {
                  const { datasetIndex, index: currentSectionIndex } =
                     tooltipItem;
                  const dataset = data.datasets[datasetIndex];
                  const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                  const total = dataset.data.reduce(
                     (acc, value, index) =>
                        meta.data[index].hidden ? acc : acc + value,
                     0
                  );
                  const value = dataset.data[currentSectionIndex];
                  if (meta.data[currentSectionIndex].hidden) {
                     return `${data.labels[currentSectionIndex]}: Hidden`;
                  }
                  const percentage = calculatePercentage(value, total);
                  return `${data.labels[currentSectionIndex]}: ${percentage}%`;
               }, */

const GraphDashboard = (props) => {
   const { startDate, endDate, cardsData } = props;
   const [showGraph, setShowGraph] = useState(false);
   const [isGraphLoading, setIsGraphLoading] = useState(
      new Array(cardsData.length).fill(false)
   );
   const [graph, setGraph] = useState({});

   useEffect(() => {
      setShowGraph(false);
      setIsGraphLoading(new Array(cardsData.length).fill(false));
   }, [cardsData]);

   const handleShowGraph = async (graphData, index) => {
      if (graphData.Cantidad === 0) return;

      setLoadingStateForCard(index, true);

      const graphDetails = await fetchGraphDetails(graphData.KPI);
      const graphDataWithDetails = createGraphDataWithDetails(graphData, graphDetails);

      updateGraph(graphDataWithDetails);

      setLoadingStateForCard(index, false);
   };

   const fetchGraphDetails = async (graphDataKPI) => {
      return await costsGraphApi.getKpiDetails(graphDataKPI, startDate, endDate);
   };

   const createGraphDataWithDetails = (graphData, graphDetails) => {
      return {
         ...graphData,
         details: graphDetails,
      };
   };

   const updateGraph = (graphData) => {
      setGraph(graphData);
      setShowGraph(true);
   };
   const generateGraph = () => {
      if (!graph || !graph.details) return;
      const labels = graph.details.map((entry) => entry.name);
      const values = graph.details.map((entry) => entry.value);
      const colors = generateColors(graph.details.length);

      return {
         labels: labels,
         datasets: [
            {
               data: values,
               backgroundColor: colors,
            },
         ],
      };
   };

   const generateGraphOptions = () => {
      return {
         legend: {
            position: 'bottom',
            labels: {
               generateLabels: (chart) => {
                  const { labels } = chart.data;
                  const { data } = chart.data.datasets[0];
                  return labels.map((label, index) => ({
                     text: `${label?.replace('$', '')}: ${
                        label.includes('$') ? formatMoney(data[index]) : data[index]
                     }`,
                     fillStyle: chart.data.datasets[0].backgroundColor[index],
                     hidden: isNaN(chart.data.datasets[0].data[index]),

                     // Extra data used for toggling the correct item
                     index,
                  }));
               },
            },
         },
         title: {
            display: true,
            text: graph.Nombre?.replace('$', ''),
            fontSize: 20,
         },
         tooltips: {
            callbacks: {
               label: (tooltipItem, data) => {
                  const { index: currentSectionIndex } = tooltipItem;
                  const dataset = data.datasets[0];
                  const total = dataset.data.reduce((acc, value) => acc + value, 0);
                  const value = dataset.data[currentSectionIndex];
                  const percentage = calculatePercentage(value, total);
                  return `${data.labels[currentSectionIndex]?.replace(
                     '$',
                     ''
                  )}: ${percentage}%`;
               },
            },
         },
      };
   };

   const setLoadingStateForCard = (index, state) => {
      setIsGraphLoading((prev) => {
         const copy = [...prev];
         copy[index] = state;
         return copy;
      });
   };

   const getGraphDetails = async (graphDataKPI) => {
      const graphDetails = await costsGraphApi.getKpiDetails(
         graphDataKPI,
         startDate,
         endDate
      );
      return graphDetails;
   };

   const generateColors = (count) => {
      const palette = [];
      const hueIncrement = 360 / count;
      let hue = 0;

      for (let i = 0; i < count; i++) {
         const color = `hsl(${hue}, 60%, 70%)`;
         palette.push(color);
         hue += hueIncrement;
      }

      return palette;
   };

   const calculatePercentage = (value, total) => {
      const percentage = (value / total) * 100;
      return percentage.toFixed(2);
   };

   return (
      <React.Fragment>
         {cardsData?.map((graphCardData, index) => (
            <GraphCard
               key={graphCardData.KPI}
               data={graphCardData}
               onDrawGraphClick={() => handleShowGraph(graphCardData, index)}
               onGraphDetailsClick={getGraphDetails}
               isLoading={isGraphLoading[index]}
            />
         ))}
         {showGraph && (
            <Pie data={generateGraph()} options={generateGraphOptions()} width={400} />
         )}
      </React.Fragment>
   );
};

export default GraphDashboard;

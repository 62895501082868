import React from 'react';
import DinamicModal from './DinamicModal';
import advertencia from '../../images/advertencia.svg';

const DeleteModal = props => {
   return (
      <DinamicModal
         idModal={props.id}
         sizeModalContainer={'mini'}
         title={props.title}
         btnTextSuccess={'Eliminar'}
         success={props.acceptMethod}
         showBtnSuccess={true}
         btnTextCancel={'Cancelar'}
         showBtnCancel={true}
         loadingSave={props.savingModal}
      >
         <div className="img-responsive img-trash full row align-center justify-center">
            <img src={advertencia} alt="Eliminar" />
         </div>
         <div className="white-space-16"></div>
         <div className="full row align-center justify-center">
            <p className="text-center color-black font-huge weight-bold">
               {props.message}
            </p>
         </div>
         <div className="white-space-16"></div>
      </DinamicModal>
   );
};
export default DeleteModal;

import React, { useEffect, useState } from 'react';

const TableFolder = props => {
   const [folders, setFolders] = useState([]);
   const [loadingFolders, setLoadingFolders] = useState(false);

   useEffect(() => {
      setFolders(props.folders);
      setLoadingFolders(props.loadingFolders);
   }, [props.folders, props.loadingFolders]);

   return (
      <div className="table-responsive full">
         <table>
            <thead>
               <tr>
                  <th className="text-left">Carpeta</th>
                  <th className="text-left">Proyecto</th>
                  <th className="text-center">Documentos</th>
                  <th className="text-center th-md"></th>
                  <th className="text-center th-md"></th>
               </tr>
            </thead>
            <tbody>
               {loadingFolders ? (
                  <tr>
                     <td className="text-center" colSpan="4">
                        <i className="fas fa-spinner fa-spin"></i>
                     </td>
                  </tr>
               ) : folders ? (
                  folders.length === 0 ? (
                     <tr>
                        <td className="text-center" colSpan="4">
                           Sin datos que mostrar
                        </td>
                     </tr>
                  ) : (
                     folders
                        .slice((props.page - 1) * 15, props.page * 15)
                        .map((folder, key) => (
                           <tr key={key}>
                              <td className="">{folder.NombreFolder}</td>
                              <td className="text-left">
                                 {folder.NombreProyecto}
                              </td>
                              <td className="text-center">
                                 <button
                                    type="button"
                                    className=" btn-small btn-empty"
                                    onClick={() => {
                                       props.showDocuments(folder);
                                    }}
                                 >
                                    <i className="fas fa-tasks"></i>
                                 </button>
                              </td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.setFolder(folder);
                                    }}
                                 >
                                    <i className="font-small fas fa-edit" />
                                 </button>
                              </td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.setDeleteFolder(folder);
                                    }}
                                 >
                                    <i className="font-small fas fa-trash-alt" />
                                 </button>
                              </td>
                           </tr>
                        ))
                  )
               ) : null}
            </tbody>
         </table>
      </div>
   );
};
export default TableFolder;

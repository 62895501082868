/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import DinamicModal from '../components/modal/DinamicModal';
import ResetPassword from '../components/modal/resetPassword';
import { Consumer } from '../context';
import Request from '../core/httpClient';

import Logo from '../images/logo_st.png';
import user from '../images/user_placeholder.jpg';

const req = new Request();

const Sidebar = props => {
   const [menus, setMenus] = useState(props.context.menu);
   const [activo, setActivo] = useState(0);
   const [parentOpen, setParentOpen] = useState(0);
   const [savingModal, setSavingModal] = useState(false);
   const [oldPassword, setOldPassword] = useState('');
   const [newPassword, setNewPassword] = useState('');

   useEffect(() => {
      async function loadMenu() {
         let menuResponse = await props.context.getMenu();
         setMenus(menuResponse);
      }
      async function loadMenuActivo() {
         let storage = await JSON.parse(
            window.localStorage.getItem('menuActivo')
         );
         if (storage) {
            setActivo(storage.IdMenu);
         }
      }

      async function getParent() {
         let storage = await JSON.parse(
            window.localStorage.getItem('menuParent')
         );
         if (storage) {
            setParentOpen(storage.IdMenu);
         }
      }

      loadMenu();
      loadMenuActivo();
      getParent();
   }, []);

   async function openModal(idModal) {
      let modal = document.getElementById(idModal);

      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async function closeModal(idModal) {
      let modal = document.getElementById(idModal);
      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   async function openResetPassword() {
      openModal('modalPassword');
      setNewPassword('');
      setOldPassword('');
   }

   async function resetPassword(event) {
      event.preventDefault();
      let data = {
         OldPassword: oldPassword,
         Password: newPassword,
         IdPersona: props.context.Usuario.IdPersona,
      };
      const requestApi = await req.post('users/update/password', data);
      if (requestApi.updated) {
         closeModal('modalPassword');
         cogoToast.success('Contraseña actualizada con éxito.', {
            position: 'top-right',
         });
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
   }

   async function setAct(IdMenu) {
      let parent = menus.filter(menu => menu.IdMenu === IdMenu);
      if (parent.length > 0) {
         let open = IdMenu;
         if (IdMenu === parentOpen) {
            open = 0;
         }
         localStorage.setItem(
            'menuParent',
            JSON.stringify({
               IdMenu: IdMenu,
            })
         );
         setParentOpen(open);
      }
      localStorage.setItem(
         'menuActivo',
         JSON.stringify({
            IdMenu: IdMenu,
         })
      );
      setActivo(IdMenu);
   }

   function openSubmenu() {
      const leftbar = document.getElementById('submenu');
      if (leftbar) {
         leftbar.classList.toggle('open');
      }
   }
   return (
      <React.Fragment>
         <div className="column sidebar" id="sidebar">
            <div className="bg-sidebar"></div>
            <div className="row">
               <div className="column full content-side">
                  <div className="white-space-16"></div>
                  <div className="row full justify-center">
                     <div className="container">
                        <div className="column full align-center justify-center">
                           <div className="logo ">
                              <Link to="/" className="responsive-img auto">
                                 <div className="responsive-img justify-center logo">
                                    <img src={Logo} alt="ELEVA" title="ELEVA" />
                                 </div>
                              </Link>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="white-space-16"></div>
                  <div className="row full justify-center">
                     <div className="container container-name">
                        <div className="column half align-center justify-center">
                           <div className="logo ">
                              <Link to="/" className="responsive-img auto">
                                 <div className="responsive-img justify-center img-sidebar">
                                    <img src={user} alt="ELEVA" title="ELEVA" />
                                 </div>
                              </Link>
                           </div>
                        </div>
                        <div className="column justify-center full column-name">
                           <span className="color-white weight-bold">
                              {props.context.Usuario.Usuario}
                           </span>
                           <div className="white-space-8"></div>
                           <span className="color-gray font-mini weight-bold">
                              {props.context.Usuario.NombrePerfil}
                           </span>
                        </div>
                        <div className="column half justify-center align-center">
                           <Link
                              to="#"
                              className="color-white"
                              onClick={() => {
                                 openSubmenu();
                              }}
                           >
                              <i className="fas fa-ellipsis-v"></i>
                           </Link>
                        </div>
                     </div>
                  </div>
                  <div className="white-space-16"></div>
                  {/* menu  */}
                  {menus.map((menu, key) => {
                     return (
                        <React.Fragment key={key}>
                           <Link
                              to={menu.Submenu.length > 0 ? '#' : menu.Ruta}
                              onClick={() => {
                                 setAct(menu.IdMenu);
                              }}
                           >
                              <div
                                 className={`panel ${
                                    activo === menu.IdMenu ? 'active' : ''
                                 } `}
                              >
                                 <div className={`row item auto `}>
                                    <div className="column">
                                       <i className={`fas ${menu.Icono} `}></i>
                                    </div>
                                    <div className="column full">
                                       <span className=" ">
                                          {' '}
                                          &nbsp; {menu.Menu}
                                       </span>
                                    </div>
                                    <div className="column caret-column">
                                       {menu.Submenu.length > 0 ? (
                                          <i
                                             className={`fas ${
                                                parentOpen === menu.IdMenu
                                                   ? 'fa-caret-up'
                                                   : 'fa-caret-down'
                                             } justify-end align-center`}
                                          ></i>
                                       ) : (
                                          ''
                                       )}
                                    </div>
                                 </div>
                              </div>
                           </Link>

                           {menu.Submenu.map((sub, key) => {
                              return (
                                 <Link
                                    key={key}
                                    to={sub.Ruta}
                                    onClick={() => {
                                       setAct(sub.IdMenu);
                                    }}
                                 >
                                    <div
                                       className={`panel ${
                                          activo === sub.IdMenu ? 'active' : ''
                                       } ${
                                          parentOpen === menu.IdMenu
                                             ? 'open'
                                             : 'close'
                                       }`}
                                    >
                                       <div className={`row subitem auto `}>
                                          <span className=" ">
                                             {' '}
                                             &nbsp; {sub.Menu}
                                          </span>
                                       </div>
                                    </div>
                                 </Link>
                              );
                           })}
                        </React.Fragment>
                     );
                  })}
               </div>
            </div>
         </div>
         <DinamicModal
            idModal={'modalPassword'}
            sizeModalContainer={'mini'}
            title={'Cambio de contraseña'}
            btnTextSuccess={'Guardar'}
            success={resetPassword}
            showBtnSuccess={true}
            btnTextCancel={'Cerrar'}
            showBtnCancel={true}
            loadingSave={savingModal}
         >
            <ResetPassword
               newPassword={newPassword}
               oldPassword={oldPassword}
               setOldPassword={setOldPassword}
               setNewPassword={setNewPassword}
            />
         </DinamicModal>
         {/* submenu */}
         <div className="column">
            <div id="submenu" className="submenu row">
               <div className="column container">
                  <Link
                     className="color-white"
                     to="/login"
                     onClick={() => {
                        props.context.logOut();
                     }}
                  >
                     <i className="fas fa-power-off "></i>
                     &nbsp;Logout
                  </Link>
                  <div className="white-space-16"></div>
                  <Link
                     className="color-white"
                     to="#"
                     onClick={() => {
                        openResetPassword();
                     }}
                  >
                     <i className="fas fa-key"></i>
                     &nbsp;Cambiar Contraseña
                  </Link>

                  <div className="white-space-64"></div>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
};

export default Consumer(Sidebar);

import React from 'react';
import Helmet from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import WOW from 'wowjs';

import { Consumer } from '../context';
import Request from '../core/httpClient';
import DinamicModal from '../components/modal/DinamicModal';
import Controls from '../components/Controls';
import Paginator from '../components/Paginator';
import Sidebar from '../components/Sidebar';

import TableUsuarios from '../components/usuarios/tables/tableUsuarios';
import TablePerfiles from '../components/usuarios/tables/tablePerfiles';
import ModalUsuarios from '../components/usuarios/modal/modalUsuarios';
import ModalPerfiles from '../components/usuarios/modal/modalPerfiles';
import DeleteModal from '../components/modal/DeleteModal';

const req = new Request();

class Usuarios extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         loadingUsuarios: false,
         loadingPerfiles: false,
         showUsuarios: true,
         showPerfiles: false,
         savingModal: false,
         menus: [],
         usuarios: [],
         perfiles: [],
         proyectos: [],
         unidades: [],
         usuario: {},
         perfil: {},
         idModal: 'modalUsuario',
         openNewModal: this.openNewUsuario.bind(this),
         saveUsuario: this.createUsuario.bind(this),
         savePerfil: this.createPerfil.bind(this),
      };
   }

   componentDidMount() {
      this.loadUsuarios();
      this.loadPerfiles();
      this.loadProyectos();
      this.loadUnidades();
      this.loadMenus();
      new WOW.WOW({
         live: false,
      }).init();
   }

   async selectPage(page) {
      this.setState({ page: page });
   }

   async openModal() {
      if (this.state.idModal === 'modalPerfil') {
         document.body.style.cursor = 'wait';

         const response = await req.get('users/get/perfiles', {});

         if (!response.empty && !response.error) {
            response.data.map(row => (row.Permisos = JSON.parse(row.Permisos)));
            this.setState({
               perfiles: response.data,
            });
         } else {
            this.setState({
               perfiles: [],
            });
         }

         document.body.style.cursor = 'default';
      }

      let modal = document.getElementById(this.state.idModal);

      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async updateState(json) {
      this.setState(json);
   }

   async closeModal() {
      let modal = document.getElementById(this.state.idModal);
      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   // trae usuarios
   async loadUsuarios() {
      this.setState({
         loadingUsuarios: true,
      });
      const apiRequest = await req.get('users/get', {});
      if (!apiRequest.empty && !apiRequest.error) {
         this.setState({
            usuarios: apiRequest.data,
         });
      } else {
         this.setState({
            usuarios: [],
         });
      }
      this.setState({
         loadingUsuarios: false,
      });
   }

   async loadProyectos() {
      this.setState({
         loadingProyectos: true,
      });
      const response = await req.get('proyectos/get', {});
      if (!response.empty && !response.error) {
         this.setState({
            proyectos: response.data,
         });
      } else {
         this.setState({
            proyectos: [],
         });
      }
      this.setState({
         loadingProyectos: false,
      });
   }

   async loadUnidades() {
      this.setState({
         loadingUnidades: true,
      });
      const response = await req.get('proyectos/get/all/unidades', {});
      if (!response.empty && !response.error) {
         this.setState({
            unidades: response.data,
         });
      } else {
         this.setState({
            unidades: [],
         });
      }
      this.setState({
         loadingUnidades: false,
      });
   }
   // traer menus
   async loadMenus() {
      const apiRequest = await req.get('menu/get', {});
      if (!apiRequest.empty && !apiRequest.error) {
         this.setState({
            menus: apiRequest.menu,
         });
      } else {
         this.setState({ menus: [] });
      }
   }

   // traer perfiles
   async loadPerfiles() {
      this.setState({
         loadingPerfiles: true,
      });
      const response = await req.get('users/get/perfiles', {});
      if (!response.empty && !response.error) {
         response.data.map(row => (row.Permisos = JSON.parse(row.Permisos)));
         this.setState({
            perfiles: response.data,
         });
      } else {
         this.setState({
            perfiles: [],
         });
      }
      this.setState({
         loadingPerfiles: false,
      });
   }

   // usuarios
   openNewUsuario() {
      this.setState({
         usuario: {},
         saveUsuario: this.createUsuario.bind(this),
      });
      this.openModal();
   }

   activeUsuarios() {
      this.setState({
         showUsuarios: true,
         showPerfiles: false,
         openNewModal: this.openNewUsuario.bind(this),
      });
   }

   async setUsuario(usuario) {
      await this.setState({
         usuario: {
            Apellidos: usuario.Apellidos,
            Email: usuario.Email,
            FechaAlta: usuario.FechaAlta,
            IdPerfil: usuario.IdPerfil,
            IdPersona: usuario.IdPersona,
            Nombre: usuario.Nombre,
            NombrePerfil: usuario.NombrePerfil,
            Telefono: usuario.Telefono,
            IdProyecto: usuario.IdProyecto,
            IdUnidad: usuario.IdUnidad,
            Propietario: usuario.Propietario,
         },
         idModal: 'modalUsuario',
         saveUsuario: this.updateUsuario.bind(this),
      });
      this.openModal();
   }

   async setUsuarioDelete(usuario) {
      await this.setState({
         usuario: usuario,
         deleteHandler: this.deleteUsuario.bind(this),
         messageDelete: '¿Está seguro de eliminar el usuario?',
         idModal: 'deleteModal',
      });
      this.openModal();
   }

   async createUsuario(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let usuario = this.state.usuario;
      let data = {
         Apellidos: usuario.Apellidos,
         Email: usuario.Email,
         FechaAlta: usuario.FechaAlta,
         IdPerfil: usuario.IdPerfil ? usuario.IdPerfil : 0,
         IdUnidad: usuario.IdUnidad ? Number.parseInt(usuario.IdUnidad) : 0,
         Nombre: usuario.Nombre,
         NombrePerfil: usuario.NombrePerfil,
         Telefono: usuario.Telefono,
      };
      const response = await req.post('users/create', data);
      if (response.created) {
         cogoToast.success('El usuario ha sido guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ usuario: {} });
         this.closeModal();
         this.loadUsuarios();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${response.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async updateUsuario(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let usuario = this.state.usuario;
      let data = {
         IdPersona: usuario.IdPersona,
         Nombre: usuario.Nombre,
         Apellidos: usuario.Apellidos,
         Email: usuario.Email,
         Telefono: usuario.Telefono,
         FechaAlta: usuario.FechaAlta,
         IdPerfil: usuario.IdPerfil ? usuario.IdPerfil : 0,
         IdUnidad: usuario.IdUnidad ? Number.parseInt(usuario.IdUnidad) : 0,
         NombrePerfil: usuario.NombrePerfil,
         Status: 1,
      };

      const apiRequest = await req.post('users/update', data);
      if (apiRequest.response) {
         cogoToast.success('El usuario ha sido guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ usuario: {} });
         this.closeModal();
         this.loadUsuarios();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${apiRequest.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async deleteUsuario(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let usuario = this.state.usuario;
      let data = {
         IdPersona: usuario.IdPersona,
         Nombre: usuario.Nombre,
         Apellidos: usuario.Apellidos,
         Email: usuario.Email,
         Telefono: usuario.Telefono,
         FechaAlta: usuario.FechaAlta,
         IdPerfil: usuario.IdPerfil ? usuario.IdPerfil : 0,
         IdUnidad: usuario.IdUnidad ? Number.parseInt(usuario.IdUnidad) : 0,
         NombrePerfil: usuario.NombrePerfil,
         Status: 0,
      };

      const apiRequest = await req.post('users/update', data);
      if (apiRequest.response) {
         cogoToast.success('El usuario ha sido guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ usuario: {} });
         this.closeModal();
         this.loadUsuarios();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${apiRequest.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   // perfiles
   activePerfiles() {
      this.setState({
         showUsuarios: false,
         showPerfiles: true,
         openNewModal: this.openNewPerfil.bind(this),
      });
   }

   async openNewPerfil() {
      await this.setState({
         perfil: {},
         savePerfil: this.createPerfil.bind(this),
         idModal: 'modalPerfil',
      });
      this.openModal();
   }

   async setPerfil(perfil) {
      await this.setState({
         perfil: {
            IdPerfil: perfil.IdPerfil,
            NombrePerfil: perfil.NombrePerfil,
            Permisos: perfil.Permisos,
         },
         idModal: 'modalPerfil',
         savePerfil: this.updatePerfil.bind(this),
      });
      this.openModal();
   }

   async setPerfilDelete(perfil) {
      await this.setState({
         perfil: {
            IdPerfil: perfil.IdPerfil,
            NombrePerfil: perfil.NombrePerfil,
            Permisos: perfil.Permisos,
         },
         deleteHandler: this.deletePerfil.bind(this),
         messageDelete: '¿Está seguro de eliminar el perfil?',
         idModal: 'deleteModal',
      });
      this.openModal();
   }

   async createPerfil(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let perfil = this.state.perfil;
      if (Object.keys(perfil.Permisos).length < 1) {
         return;
      }
      let data = {
         NombrePerfil: perfil.NombrePerfil,
         Permisos: JSON.stringify(perfil.Permisos),
      };
      const apiRequest = await req.post('users/create/perfil', data);
      if (apiRequest.response) {
         cogoToast.success('El perfil se ha guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ perfil: {} });
         this.closeModal();
         this.loadPerfiles();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${apiRequest.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async updatePerfil(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let perfil = this.state.perfil;
      let data = {
         IdPerfil: perfil.IdPerfil,
         NombrePerfil: perfil.NombrePerfil,
         Permisos: JSON.stringify(perfil.Permisos),
         Status: 1,
      };
      const apiRequest = await req.post('users/update/perfil', data);
      if (apiRequest.response) {
         cogoToast.success('El perfil se ha guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ perfil: {} });
         this.closeModal();
         this.loadPerfiles();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${apiRequest.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async deletePerfil(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let perfil = this.state.perfil;
      let data = {
         IdPerfil: perfil.IdPerfil,
         NombrePerfil: perfil.NombrePerfil,
         Permisos: JSON.stringify(perfil.Permisos),
         Status: 0,
      };
      const apiRequest = await req.post('users/update/perfil', data);
      if (apiRequest.response) {
         cogoToast.success('El perfil se ha guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ perfil: {} });
         this.closeModal();
         this.loadPerfiles();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${apiRequest.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   render() {
      return (
         <div className="column justify-center align-center usuarios">
            <Helmet>
               <title>ELEVA Capital Group | Usuarios</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>
                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Configuración</h3>
                        <h3 className="color-secondary">&nbsp;/&nbsp;</h3>
                        <h4 className="color-darkgray">Usuarios</h4>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls
                              newHandler={this.state.openNewModal}
                              showFilter={true}
                           />
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center">
                           <div className="container">
                              <div className="column quart list-side wow fadeIn">
                                 <Link
                                    to="#"
                                    onClick={() => {
                                       this.activeUsuarios();
                                    }}
                                 >
                                    <div
                                       className={`row list-item ${
                                          this.state.showUsuarios
                                             ? 'active'
                                             : ''
                                       } `}
                                    >
                                       Usuarios
                                    </div>
                                 </Link>
                                 <Link
                                    to="#"
                                    onClick={() => {
                                       this.activePerfiles();
                                    }}
                                 >
                                    <div
                                       className={`row list-item ${
                                          this.state.showPerfiles
                                             ? 'active'
                                             : ''
                                       } `}
                                    >
                                       Perfiles
                                    </div>
                                 </Link>
                              </div>
                              <div
                                 className="column card-table full align-center table-side wow fadeIn"
                                 data-wow-delay="0.3s"
                              >
                                 {this.state.showUsuarios ? (
                                    <TableUsuarios
                                       usuarios={this.state.usuarios}
                                       loadingUsuarios={
                                          this.state.loadingUsuarios
                                       }
                                       page={this.state.page}
                                       setUsuario={this.setUsuario.bind(this)}
                                       setUsuarioDelete={this.setUsuarioDelete.bind(
                                          this
                                       )}
                                    />
                                 ) : this.state.showPerfiles ? (
                                    <TablePerfiles
                                       perfiles={this.state.perfiles}
                                       loadingPerfiles={
                                          this.state.loadingPerfiles
                                       }
                                       page={this.state.page}
                                       setPerfil={this.setPerfil.bind(this)}
                                       setPerfilDelete={this.setPerfilDelete.bind(
                                          this
                                       )}
                                    />
                                 ) : null}

                                 <div className="row">
                                    <div className="white-space-8"></div>
                                 </div>
                                 <div className="row justify-center align-center">
                                    <Paginator
                                       pages={Math.ceil(
                                          (this.state.showUsuarios
                                             ? this.state.usuarios.length
                                             : this.state.perfiles.length) / 15
                                       )}
                                       setPage={this.selectPage.bind(this)}
                                    />
                                 </div>
                                 <div className="row">
                                    <div className="white-space-8"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                     </div>
                  </div>
               </div>

               <DinamicModal
                  idModal={'modalUsuario'}
                  sizeModalContainer={'small'}
                  title={'Usuario'}
                  btnTextSuccess={'Guardar'}
                  success={this.state.saveUsuario}
                  showBtnSuccess={true}
                  btnTextCancel={'Cancelar'}
                  showBtnCancel={true}
                  loadingSave={this.state.savingModal}
               >
                  <ModalUsuarios
                     usuario={this.state.usuario}
                     updateState={this.updateState.bind(this)}
                     perfiles={this.state.perfiles}
                     proyectos={this.state.proyectos}
                     unidades={this.state.unidades}
                  />
               </DinamicModal>
               <DinamicModal
                  idModal={'modalPerfil'}
                  sizeModalContainer={'normal'}
                  title={'Perfil'}
                  btnTextSuccess={'Guardar'}
                  success={this.state.savePerfil}
                  showBtnSuccess={true}
                  btnTextCancel={'Cancelar'}
                  showBtnCancel={true}
                  loadingSave={this.state.savingModal}
               >
                  <ModalPerfiles
                     perfil={this.state.perfil}
                     updateState={this.updateState.bind(this)}
                     menus={this.state.menus}
                  />
               </DinamicModal>
               <DeleteModal
                  id="deleteModal"
                  title="Eliminar"
                  message={this.state.messageDelete}
                  acceptMethod={this.state.deleteHandler}
                  savingModal={this.state.savingModal}
               />
            </div>
         </div>
      );
   }
}
export default withRouter(Consumer(Usuarios));

import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

import ModalContainer from '../../controlCostos/ModalContainer';
import DetailsSelector from '../../controlCostos/components/DetailsSelector';
import { costsControlApi } from '../../../api';
import cogoToast from 'cogo-toast';
import ServiceOrder from '../../controlCostos/components/details/ServiceOrder/ServiceOrder';

const TableAbiertas = (props) => {
   const [solicitudes, setSolicitudes] = useState([]);
   const [loadingSolicitudes, setLoadingSolicitudes] = useState(false);
   const [serviceModal, setServiceModal] = useState(false);
   const [selectedItem, setSelectedItem] = useState({});
   const [enableOrderList, setEnableOrderList] = useState([]);

   const actions = ['Añadir Servicio', 'Editar Servicio'];

   useEffect(() => {
      setSolicitudes(props.solicitudes);
      setLoadingSolicitudes(props.loadingSolicitudes);
   }, [props.solicitudes, props.loadingSolicitudes]);

   useEffect(() => {
      (async () => {
         setEnableOrderList(await costsControlApi.getOrdersList());
      })();
   }, []);

   const handleEtitItem = (newValue, attrToChange) => {
      setSelectedItem((prevState) => ({
         ...prevState,
         [attrToChange]: newValue,
      }));
   };

   const validateNewOrder = (item) =>
      item.solicitud &&
      item.description &&
      item.type_of_service &&
      item.status &&
      item.tasks;

   const handleSaveItem = (e, item) => {
      e.preventDefault();
      if (!validateNewOrder(item)) {
         cogoToast.error('Faltan campos por llenar', { position: 'top-right' });
         return;
      }

      const saveStatus = costsControlApi.createOrder(item);
      if (!saveStatus?.error) {
         setServiceModal(false);
      }
   };

   const handleCancelOrClose = () => {
      setServiceModal(false);
   };

   return (
      <div className="table-responsive full">
         {serviceModal && (
            <ModalContainer
               size="normal"
               title={selectedItem.title}
               btnSuccessText="Guardar"
               onCancel={handleCancelOrClose}
               onClose={handleCancelOrClose}
               onSuccess={(e) => handleSaveItem(e, selectedItem)}>
               <DetailsSelector
                  select={ServiceOrder}
                  item={selectedItem}
                  onEditItem={handleEtitItem}
               />
            </ModalContainer>
         )}
         <table>
            <thead>
               <tr>
                  <th className="text-center">No Solicitud</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Fecha</th>
                  <th className="text-center">Proyecto</th>
                  <th className="text-center">Unidad</th>
                  <th className="text-center th-md"></th>
                  <th className="text-center th-md"></th>
               </tr>
            </thead>
            <tbody>
               {loadingSolicitudes ? (
                  <tr>
                     <td className="text-center" colSpan="7">
                        <i className="fas fa-spinner fa-spin"></i>
                     </td>
                  </tr>
               ) : solicitudes ? (
                  solicitudes?.length === 0 ? (
                     <tr>
                        <td className="text-center" colSpan="7">
                           Sin datos que mostrar
                        </td>
                     </tr>
                  ) : (
                     solicitudes
                        .slice((props.page - 1) * 15, props.page * 15)
                        .map((solicitud, key) => (
                           <tr key={key}>
                              <td className="text-center">{solicitud.NoSolicitud}</td>
                              <td className="text-center">{solicitud.Estado}</td>
                              <td className="text-center">
                                 {moment(solicitud.Fecha)
                                    .zone('+05:00')
                                    .format('DD-MM-YYYY')}
                              </td>
                              <td className="text-center">{solicitud.NombreProyecto}</td>
                              <td className="text-center">{solicitud.Numero}</td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.setSolicitud(solicitud);
                                    }}>
                                    <i className="font-small fas fa-eye" />
                                 </button>
                              </td>
                              <td className="text-center">
                                 {enableOrderList?.find(
                                    (item) => item.id_request === solicitud?.IdSolicitud
                                 ) ||
                                 (Number(solicitud?.IdEstado) !== 3 &&
                                    Number(solicitud?.IdEstado) !== 6 &&
                                    Number(solicitud.IdEstado) !== 10) ? (
                                    <button
                                       className="btn btn-empty btn-small color-white"
                                       onClick={() => {
                                          setServiceModal(true);
                                          setSelectedItem({
                                             title: actions[0],
                                             solicitud: solicitud,
                                          });
                                       }}>
                                       <i className="fas fa-plus-square" />
                                       &nbsp; Servicio
                                    </button>
                                 ) : (
                                    <button
                                       className="btn btn-empty btn-small color-white"
                                       disabled>
                                       <i className="fas fa-ban"></i>
                                       &nbsp; Servicio
                                    </button>
                                 )}
                              </td>
                           </tr>
                        ))
                  )
               ) : (
                  <tr>
                     <td className="text-center" colSpan="7">
                        Sin datos que mostrar
                     </td>
                  </tr>
               )}
            </tbody>
         </table>
      </div>
   );
};
export default TableAbiertas;

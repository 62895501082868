import React, {useEffect} from 'react';
import ItemInput from '../ItemInput';
import ItemCheckbox from '../ItemCheckbox';
import ItemOptionsTable from '../ItemOptionsTable';

const ProvidersDetails = (props) => {
    const {item, onEditItem, modalProps} = props;

    const scrollableOptionsProviders = {
        display: 'block',
        maxHeight: '100px',
        overflowY: 'auto',
    };

    useEffect(() => {
        if (item.Tasks && item.Tasks.length > 0) {
            if (item.Tasks.some((t) => !t.category))
                onEditItem(
                    item.Tasks.map((t) => ({
                        ...t,
                        category: {id: t?.category?.id || 0, name: t?.category?.name || ''}
                    })),
                    'Tasks'
                );
        }
    }, [item.Tasks, onEditItem]);

    return (
        <div>
            <ItemInput
                value={item.name}
                label="Nombre"
                onValueUpdate={onEditItem}
                attributeToChange="name"
            />

            <div className="white-space-16"/>

            <ItemCheckbox
                label="Activo"
                checked={item.statusId === 1}
                onValueUpdate={onEditItem}
                attributeToChange="statusId"
            />

            <div className="white-space-16"/>

            <ItemOptionsTable
                type="txt"
                items={item?.Tasks || []}
                visibleTHeads={['Tarea', 'Categoria']}
                visibleKeys={['name', 'category']}
                types={{name: 'text', category: 'select'}}
                options={modalProps.categories || []}
                onValueUpdate={onEditItem}
                attributeToChange="Tasks"
                scrollableOptions={scrollableOptionsProviders}
            />
        </div>
    );
};

export default ProvidersDetails;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import { Consumer } from '../../../context';
import Request from '../../../core/httpClient';
import Paginator from '../../Paginator';
import DinamicModal from '../../modal/DinamicModal';
import DeleteModal from '../../modal/DeleteModal';
import ModalCatalogo from '../modal/modalCatalogo';

const req = new Request();

const TableProblemas = (props) => {
   const [problemas, setProblemas] = useState([]);
   const [savingModal, setSavingModal] = useState(false);
   const [currentProblema, setCurrentProblema] = useState({});
   const [page, setPage] = useState(1);

   useEffect(() => {
      setProblemas(props.problemas);
   }, [props.problemas]);

   async function selectPage(page) {
      setPage(page);
   }

   async function openModal(problema, id) {
      let modal = document.getElementById(id);
      setCurrentProblema(problema);
      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async function updateProblema(event) {
      event.preventDefault();
      setSavingModal(true);
      const requestApi = await req.post('config/update/problema', currentProblema);
      if (requestApi.response) {
         cogoToast.success('Problema guardada con éxito.', {
            position: 'top-right',
         });
         closeModal('editProblema');
         props.loadProblemas();
         setCurrentProblema({});
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      setSavingModal(false);
   }

   async function deleteProblema(event) {
      event.preventDefault();
      setSavingModal(true);
      let data = {
         IdProblema: currentProblema.IdProblema,
      };
      const requestApi = await req.post('config/delete/problema', data);
      if (requestApi.response) {
         cogoToast.success('Problema eliminado con éxito.', {
            position: 'top-right',
         });
         closeModal('deleteModal');
         props.loadProblemas();
         setCurrentProblema({});
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      setSavingModal(false);
   }

   async function closeModal(id) {
      let modal = document.getElementById(id);
      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   async function updateValue(value) {
      setCurrentProblema({
         ...currentProblema,
         ...value,
      });
   }

   return (
      <React.Fragment>
         <div className="table-responsive full">
            <table>
               <thead>
                  <tr>
                     <th className="text-left">Detalles</th>
                     <th className="text-center th-lg"></th>
                     <th className="text-center th-lg"></th>
                  </tr>
               </thead>
               <tbody>
                  {props.loadingProblemas ? (
                     <tr>
                        <td className="text-center" colSpan="3">
                           <i className="fas fa-spinner fa-spin"></i>
                        </td>
                     </tr>
                  ) : (
                     problemas.slice((page - 1) * 15, page * 15).map((problema, key) => (
                        <tr key={key}>
                           <td>{problema.NombreProblema}</td>
                           <td className="text-center">
                              <button
                                 className="btn-small btn-empty"
                                 onClick={() => {
                                    openModal(problema, 'editProblema');
                                 }}>
                                 <i className="font-small fas fa-edit" />
                              </button>
                           </td>
                           <td className="text-center">
                              <button
                                 className="btn-small btn-empty"
                                 onClick={() => {
                                    openModal(problema, 'deleteModal');
                                 }}>
                                 <i className="font-small fas fa-trash-alt" />
                              </button>
                           </td>
                        </tr>
                     ))
                  )}
               </tbody>
            </table>
         </div>

         <div className="row">
            <div className="white-space-16"></div>
         </div>
         <div className="row justify-center align-center">
            <Paginator
               pages={Math.ceil(problemas.length / 15)}
               setPage={selectPage.bind(this)}
            />
         </div>
         <DinamicModal
            idModal={'editProblema'}
            sizeModalContainer={'mini'}
            title={'Editar Detalle'}
            btnTextSuccess={'Guardar'}
            success={updateProblema}
            showBtnSuccess={true}
            btnTextCancel={'Cancelar'}
            showBtnCancel={true}
            loadingSave={savingModal}>
            <ModalCatalogo
               value={{ ...currentProblema }}
               updateValue={updateValue.bind(this)}
            />
         </DinamicModal>
         <DeleteModal
            id="deleteModal"
            title="Eliminar"
            message="¿Está seguro de eliminar el problema?"
            acceptMethod={deleteProblema}
            savingModal={savingModal}
         />
      </React.Fragment>
   );
};
export default Consumer(TableProblemas);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import ImageLoader from 'react-loading-image';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

import ModalZoom from '../../modal/modalZoom';
import NoAvalible from '../../../images/no-image-available.png';
import NoVideoAvalible from '../../../images/no-video-available.jpg';
import Loading from '../../../images/load.gif';
import ImageUploader from '../../imagesUpload/images-upload';
import TextAreaRow from '../inputs/text-area-row';
import Tooltip from '../../modal/Tooltip';

const ModalSolicitud = (props) => {
   const [solicitud, setSolicitud] = useState({});
   const [problemas, setProblemas] = useState([]);
   const [areas, setAreas] = useState([]);
   const [equipos, setEquipos] = useState([]);
   const [estados, setEstados] = useState([]);
   const [proyectos, setProyectos] = useState([]);
   const [unidades, setUnidades] = useState([]);
   const [propietarios, setPropietarios] = useState([]);
   const [newMode, setNewMode] = useState(false);
   const [imgZoom, setImgZoom] = useState('');
   const [video, setVideo] = useState('');
   const [pictures, setPictures] = useState([]);
   const [defaultPictures, setDefaultPictures] = useState([]);
   const [realizado, setRealizado] = useState(false);
   const [ImageUploaderKey, setImageUploaderKey] = useState(0);
   const [disableWarranty, setDisableWarranty] = useState(() => {
      const { IdEstado, AplicaGarantia } = props.solicitud;
      return +(IdEstado || 0) !== 4 || !AplicaGarantia;
   });

   useEffect(() => {
      setSolicitud(() => props.solicitud);
      setProblemas(() => props.problemas);
      setAreas(() => props.areas);
      setEquipos(() => props.equipos);
      setEstados(() => props.estados);
      setProyectos(() => props.proyectos);
      setUnidades(() => props.unidades);
      setPropietarios(() => props.propietarios);
      setNewMode(() => props.newMode);
      setImageUploaderKey(ImageUploaderKey + 1);
      setPictures(() => props.pictures);
   }, [props]);

   useEffect(() => {
      // Desestructura las imágenes y el video de la solicitud.
      const { ImgEvidencia1, ImgEvidencia2, ImgEvidencia3 } = solicitud;

      // Crea un array con las imágenes y el video.
      const imagesAndVideo = [ImgEvidencia1, ImgEvidencia2, ImgEvidencia3];

      // Filtra los elementos no definidos o nulos del array.
      const validEvidenciasPics = imagesAndVideo.filter(Boolean);

      // Si hay imágenes adicionales y hay espacio en el array de evidencias, añádelas.
      if (pictures && validEvidenciasPics.length < 3) {
         const availableSlots = 3 - validEvidenciasPics.length;
         const additionalPics = pictures.slice(0, availableSlots);
         validEvidenciasPics.push(...additionalPics);
      }

      const uniqueImgs = new Set(validEvidenciasPics);

      setDefaultPictures(() => [...uniqueImgs]);
   }, [solicitud, pictures]);

   const syncDisableWarrantyStatus = (request) => {
      const { IdEstado, AplicaGarantia } = request;
      const disableWarrantyHandle = +(IdEstado || 0) !== 4 || !AplicaGarantia;
      setDisableWarranty(disableWarrantyHandle);
   };

   useEffect(() => syncDisableWarrantyStatus(props.solicitud), [props.solicitud]);

   function updateSolicitud(slc) {
      if (!slc.pictures) {
         slc.pictures = pictures;
      }

      slc.realizado = realizado;

      syncDisableWarrantyStatus(slc);

      props.updateJson({
         solicitud: slc,
      });
   }

   const hiddenFileInput = useRef(null);
   const hiddenFileInput2 = useRef(null);
   const hiddenFileInput3 = useRef(null);

   function openZoom(img, vid) {
      if (img) {
         setImgZoom(img);
      }

      if (vid) {
         setVideo(vid);
      }

      let modal = document.getElementById('image-zoom-modal');

      if (modal) {
         modal.classList.remove('hide-zoom-modal');
         modal.classList.add('show-zoom-modal');
      }
   }

   const toBase64 = (file) =>
      new Promise((resolve, reject) => {
         const reader = new FileReader();
         reader.readAsDataURL(file);
         reader.onload = () => resolve(reader.result);
         reader.onerror = (error) => reject(error);
      });

   async function changeImg(idImg, event) {
      let file = event.target;
      if (file) {
         if (file.files[0]) {
            let sol = solicitud;
            if (idImg === 1) {
               sol.Img1 = await toBase64(file.files[0]);
               sol.File1 = file.files[0];
            } else if (idImg === 2) {
               sol.Img2 = await toBase64(file.files[0]);
               sol.File2 = file.files[0];
            } else if (idImg === 3) {
               sol.Img3 = await toBase64(file.files[0]);
               sol.File3 = file.files[0];
            }
            await updateSolicitud(sol);
         }
      }
   }

   const onDrop = (picture, urls) => {
      const { ImgEvidencia1, ImgEvidencia2, ImgEvidencia3 } = props.solicitud;

      props.updateJson({ pictures: urls });
      const slc = props.solicitud;
      if (!urls.includes(ImgEvidencia1)) {
         picture = picture.filter((item) => !item.name.includes('0.jpg'));
         slc.ImgEvidencia1 = '';
      }
      if (!urls.includes(ImgEvidencia2)) {
         picture = picture.filter((item) => !item.name.includes('1.jpg'));
         slc.ImgEvidencia2 = '';
      }
      if (!urls.includes(ImgEvidencia3)) {
         picture = picture.filter((item) => !item.name.includes('2.jpg'));
         slc.ImgEvidencia3 = '';
      }
      slc.pictures = [];
      props.updateJson({ solicitud: slc });
   };

   return (
      <React.Fragment>
         <div className="worko-tabs">
            <input
               className="state"
               type="radio"
               title="tab-one"
               name="tabs-state"
               id="tab-one"
               defaultChecked
            />
            <input
               className="state"
               type="radio"
               title="tab-two"
               name="tabs-state"
               id="tab-two"
            />
            <input
               className="state"
               type="radio"
               title="tab-three"
               name="tabs-state"
               id="tab-three"
            />

            <div className="tabs flex-tabs">
               <label htmlFor="tab-one" id="tab-one-label" className="tab">
                  General
               </label>
               <label htmlFor="tab-two" id="tab-two-label" className="tab">
                  Garantía
               </label>
               <label htmlFor="tab-three" id="tab-three-label" className="tab">
                  Realizado
               </label>
               <div id="tab-one-panel" className="panel active">
                  <div className="full row align-center">
                     <div className="column full">
                        <div className="white-space-8"></div>
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>No Solicitud:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <input
                                 type="text"
                                 className="input input-modals"
                                 value={
                                    solicitud.NoSolicitud ? solicitud.NoSolicitud : ''
                                 }
                                 autoComplete="off"
                                 disabled
                                 minLength={2}
                              />
                           </div>
                           <div className="column label-middle">
                              <p>
                                 <b>Fecha:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <input
                                 type="date"
                                 className="input input-modals"
                                 required
                                 autoComplete="off"
                                 disabled={!newMode}
                                 value={
                                    solicitud.Fecha
                                       ? moment(solicitud.Fecha).format('YYYY-MM-DD')
                                       : ''
                                 }
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.Fecha = event.target.value;
                                    updateSolicitud(sol);
                                 }}
                              />
                           </div>
                        </div>
                        <div className="white-space-8" />
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>Proyecto:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <select
                                 name=""
                                 className="input input-modals"
                                 value={solicitud.IdProyecto ? solicitud.IdProyecto : ''}
                                 required
                                 disabled={!newMode}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.IdProyecto = Number(event.target.value);
                                    sol.IdPersona = '';
                                    sol.IdUnidad = '';
                                    updateSolicitud(sol);
                                 }}>
                                 <option value="">Sin Proyecto</option>
                                 {proyectos.map((pry, key) => (
                                    <option key={key} value={pry.IdProyecto}>
                                       {pry.Nombre}
                                    </option>
                                 ))}
                              </select>
                           </div>
                           <div className="column label-middle">
                              <p>
                                 <b>Unidad:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <select
                                 name=""
                                 className="input input-modals"
                                 value={solicitud.IdUnidad ? solicitud.IdUnidad : ''}
                                 required
                                 disabled={!newMode}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.IdUnidad = Number(event.target.value);
                                    sol.IdPersona = '';
                                    updateSolicitud(sol);
                                 }}>
                                 <option value="">Elige Unidad</option>

                                 {unidades
                                    .filter((un) =>
                                       solicitud.IdProyecto
                                          ? Number(solicitud.IdProyecto) == un.IdProyecto
                                          : false
                                    )
                                    .sort((a, b) => a.IdUnidad - b.IdUnidad)
                                    .map((unidad, key) => (
                                       <option key={key} value={unidad.IdUnidad}>
                                          {unidad.Numero}
                                       </option>
                                    ))}
                              </select>
                           </div>
                        </div>
                        <div className="white-space-8" />
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>Reportó:</b>
                              </p>
                           </div>
                           <div className="column full">
                              <select
                                 name=""
                                 className="input input-modals"
                                 value={solicitud.IdPersona ? solicitud.IdPersona : ''}
                                 required
                                 disabled={!newMode}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.IdPersona = event.target.value;
                                    updateSolicitud(sol);
                                 }}>
                                 <option value="">Nombre</option>
                                 {propietarios
                                    .filter((pr) =>
                                       solicitud.IdUnidad
                                          ? solicitud.IdUnidad == pr.IdUnidad
                                          : false
                                    )
                                    .map((pr, key) => (
                                       <option key={key} value={pr.IdPersona}>
                                          {pr.Nombre}
                                       </option>
                                    ))}
                              </select>
                           </div>
                        </div>
                        <div className="white-space-8" />
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>Área:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <select
                                 name=""
                                 className="input input-modals"
                                 value={solicitud.IdArea ? solicitud.IdArea : ''}
                                 required
                                 disabled={!newMode}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.IdArea = event.target.value;
                                    updateSolicitud(sol);
                                 }}>
                                 <option value="">Sin Área</option>
                                 {areas.map((area, key) => (
                                    <option key={key} value={area.IdArea}>
                                       {area.NombreArea}
                                    </option>
                                 ))}
                              </select>
                           </div>
                           <div className="column label-middle">
                              <p>
                                 <b>Equipo:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <select
                                 name=""
                                 className="input input-modals"
                                 required
                                 value={solicitud.IdEquipo ? solicitud.IdEquipo : ''}
                                 disabled={!newMode}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.IdEquipo = event.target.value;
                                    updateSolicitud(sol);
                                 }}>
                                 <option value="">Sin Equipo</option>
                                 {equipos.map((equipo, key) => (
                                    <option key={key} value={equipo.IdEquipo}>
                                       {equipo.NombreEquipo}
                                    </option>
                                 ))}
                              </select>
                           </div>
                        </div>
                        <div className="white-space-8" />
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>Detalle:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <select
                                 name=""
                                 className="input input-modals"
                                 value={solicitud.IdProblema ? solicitud.IdProblema : ''}
                                 required
                                 disabled={!newMode}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.IdProblema = event.target.value;
                                    updateSolicitud(sol);
                                 }}>
                                 <option value="">Sin Detalle</option>
                                 {problemas.map((problema, key) => (
                                    <option key={key} value={problema.IdProblema}>
                                       {problema.NombreProblema}
                                    </option>
                                 ))}
                              </select>
                           </div>
                           <div className="column label-middle">
                              <p>
                                 <b>Fecha visita:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <input
                                 className="input"
                                 type="datetime-local"
                                 disabled
                                 value={
                                    solicitud.FechaVisita
                                       ? moment(solicitud.FechaVisita).format(
                                            'YYYY-MM-DDTHH:mm:ss'
                                         )
                                       : ''
                                 }
                              />
                           </div>
                        </div>
                        <div className="white-space-8" />
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>Comentarios Inquilino:</b>
                              </p>
                           </div>
                           <div className="column full">
                              <textarea
                                 className="input"
                                 value={
                                    solicitud.Comentarios ? solicitud.Comentarios : ''
                                 }
                                 disabled={!newMode}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.Comentarios = event.target.value;
                                    updateSolicitud(sol);
                                 }}></textarea>
                           </div>
                        </div>
                        <div className="row full align-center">
                           <div className="column full column-image justify-center align-center img-update">
                              <div className="container-img justify-center align-center">
                                 <ImageLoader
                                    src={solicitud.Img1}
                                    image={() => <img src={solicitud.Img1} alt="" />}
                                    loading={() => (
                                       <img src={Loading} alt="Cargando..." />
                                    )}
                                    error={() => (
                                       <img src={NoAvalible} alt="Cargando..." />
                                    )}
                                 />
                                 {solicitud.Img1 && (
                                    <div className="middle-overlay">
                                       <label className="btn-upload-image column align-center justify-center">
                                          <Link
                                             to="#"
                                             onClick={() => {
                                                openZoom(solicitud.Img1);
                                             }}>
                                             <i className="fas fa-eye"> &nbsp; Ver</i>
                                          </Link>
                                          {!solicitud.IdSolicitud ? (
                                             <React.Fragment>
                                                <div className="white-space-8"></div>

                                                <Link
                                                   to="#"
                                                   onClick={() => {
                                                      hiddenFileInput.current.click();
                                                   }}>
                                                   <i className="fas fa-file-upload">
                                                      {' '}
                                                      &nbsp; Subir{' '}
                                                   </i>
                                                </Link>
                                                <input
                                                   type="file"
                                                   ref={hiddenFileInput}
                                                   id="img1"
                                                   accept="image/*"
                                                   onChange={changeImg.bind(this, 1)}
                                                />
                                             </React.Fragment>
                                          ) : null}
                                       </label>
                                    </div>
                                 )}
                              </div>
                           </div>
                           <div className="column full column-image justify-center align-center img-update">
                              <div className="container-img justify-center align-center">
                                 <ImageLoader
                                    src={solicitud.Img2}
                                    image={() => (
                                       <img src={solicitud.Img2} alt="Producto" />
                                    )}
                                    loading={() => (
                                       <img src={Loading} alt="Cargando..." />
                                    )}
                                    error={() => (
                                       <img src={NoAvalible} alt="Cargando..." />
                                    )}
                                 />
                                 {solicitud.Img2 && (
                                    <div className="middle-overlay">
                                       <label className="btn-upload-image column align-center justify-center">
                                          <Link
                                             to="#"
                                             onClick={() => {
                                                openZoom(solicitud.Img2);
                                             }}>
                                             <i className="fas fa-eye"> &nbsp; Ver</i>
                                          </Link>
                                          {!solicitud.IdSolicitud ? (
                                             <React.Fragment>
                                                <div className="white-space-8"></div>

                                                <Link
                                                   to="#"
                                                   onClick={() => {
                                                      hiddenFileInput2.current.click();
                                                   }}>
                                                   <i className="fas fa-file-upload">
                                                      {' '}
                                                      &nbsp; Subir{' '}
                                                   </i>
                                                </Link>
                                                <input
                                                   type="file"
                                                   ref={hiddenFileInput2}
                                                   id="img2"
                                                   accept="image/*"
                                                   onChange={changeImg.bind(this, 2)}
                                                />
                                             </React.Fragment>
                                          ) : null}
                                       </label>
                                    </div>
                                 )}
                              </div>
                           </div>
                           <div className="column full column-image justify-center align-center img-update">
                              <div className="container-img justify-center align-center">
                                 <ImageLoader
                                    src={solicitud.Img3}
                                    image={() => (
                                       <img src={solicitud.Img3} alt="Producto" />
                                    )}
                                    loading={() => (
                                       <img src={Loading} alt="Cargando..." />
                                    )}
                                    error={() => (
                                       <img src={NoAvalible} alt="Cargando..." />
                                    )}
                                 />
                                 {solicitud.Img3 && (
                                    <div className="middle-overlay">
                                       <label className="btn-upload-image column align-center justify-center">
                                          <Link
                                             to="#"
                                             onClick={() => {
                                                openZoom(solicitud.Img3);
                                             }}>
                                             <i className="fas fa-eye"> &nbsp; Ver</i>
                                          </Link>
                                          {!solicitud.IdSolicitud ? (
                                             <React.Fragment>
                                                <div className="white-space-8"></div>

                                                <Link
                                                   to="#"
                                                   onClick={() => {
                                                      hiddenFileInput3.current.click();
                                                   }}>
                                                   <i className="fas fa-file-upload">
                                                      {' '}
                                                      &nbsp; Subir{' '}
                                                   </i>
                                                </Link>
                                                <input
                                                   type="file"
                                                   ref={hiddenFileInput3}
                                                   id="img3"
                                                   accept="image/*"
                                                   onChange={changeImg.bind(this, 3)}
                                                />
                                             </React.Fragment>
                                          ) : null}
                                       </label>
                                    </div>
                                 )}
                              </div>
                           </div>
                           <div className="column full column-image justify-center align-center img-update">
                              <div className="container-img justify-center align-center">
                                 {solicitud.Vid1 ? (
                                    <ReactPlayer
                                       width={'100%'}
                                       height={100}
                                       style={{}}
                                       url={solicitud.Vid1}
                                    />
                                 ) : (
                                    <ImageLoader
                                       loading={() => (
                                          <img src={Loading} alt="Cargando..." />
                                       )}
                                       error={() => (
                                          <img src={NoVideoAvalible} alt="Cargando..." />
                                       )}
                                    />
                                 )}
                                 {solicitud.Vid1 && (
                                    <div className="middle-overlay">
                                       <label className="btn-upload-image column align-center justify-center">
                                          <Link
                                             to="#"
                                             onClick={() => {
                                                openZoom(null, solicitud.Vid1);
                                             }}>
                                             <i className="fas fa-play">
                                                {' '}
                                                &nbsp; Reproducir
                                             </i>
                                          </Link>
                                       </label>
                                    </div>
                                 )}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="tab-two-panel" className="panel">
                  <div className="full row align-center">
                     <div className="column full">
                        <div className="white-space-8" />
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>Aplica Garantía:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <label className="container-check">
                                 <input
                                    type="checkbox"
                                    checked={solicitud.AplicaGarantia}
                                    onClick={() => {
                                       let sol = solicitud;
                                       sol.AplicaGarantia = !sol.AplicaGarantia;
                                       updateSolicitud(sol);
                                    }}
                                    readOnly
                                 />
                                 <span className="checkmark"></span>
                              </label>
                           </div>
                           <div className="column label-middle">
                              <p>
                                 <b>Estado Solicitud:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <select
                                 name="estado"
                                 className="input input-modals"
                                 value={solicitud.IdEstado ? solicitud.IdEstado : ''}
                                 onChange={(event) => {
                                    const openNewSolicitud = {
                                       ...solicitud,
                                       IdEstado: Number(event.target.value),
                                    };
                                    setSolicitud(openNewSolicitud);
                                    updateSolicitud(openNewSolicitud);
                                 }}>
                                 <option value="">Sin Estado</option>
                                 {estados.map((edo, key) => (
                                    <option key={key} value={edo.IdEstado}>
                                       {edo.Estado}
                                    </option>
                                 ))}
                              </select>
                           </div>
                        </div>
                        <div className="white-space-8" />
                        <TextAreaRow
                           label="Comentarios garantia: "
                           name="ComentariosAplica"
                           defaultValue={solicitud?.ComentariosAplica}
                        />
                        {!newMode && (
                           <>
                              <TextAreaRow
                                 label="Comentarios reparación: "
                                 name="ComentarioReparacion"
                                 defaultValue={solicitud?.ComentariosReparacion}
                              />
                              <TextAreaRow
                                 label="Comentarios visita: "
                                 name="ComentariosVisita"
                                 defaultValue={solicitud?.ComentariosVisita}
                                 disabled
                              />
                           </>
                        )}
                        <div className="white-space-8" />
                        <div className="flex justify-end w-full">
                           {disableWarranty && (
                              <Tooltip
                                 id="warranty"
                                 message='Solo se puede modificar las propuestas para fecha de visita cuando el estado es "Por Agendar" y a su vez la garantia sea valida'
                              />
                           )}
                        </div>
                        <div className="row full align-center justify-center">
                           <div className="column-middle label-normal justify-center">
                              <p>
                                 <b>Fecha 1:</b>
                              </p>
                           </div>
                           <div className="column-middle label-normal  justify-center">
                              <p>
                                 <b>Fecha 2:</b>
                              </p>
                           </div>
                           <div className="column-middle label-normal  justify-center">
                              <p>
                                 <b>Fecha 3:</b>
                              </p>
                           </div>
                        </div>
                        <div className="row full align-center justify-center">
                           <div className="column-middle justify-center">
                              <input
                                 className="input input-huge"
                                 type="datetime-local"
                                 value={
                                    solicitud.Fecha1
                                       ? moment(solicitud.Fecha1).format(
                                            'YYYY-MM-DDTHH:mm:ss'
                                         )
                                       : ''
                                 }
                                 disabled={disableWarranty}
                                 required={solicitud.AplicaGarantia}
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.Fecha1 = event.target.value;
                                    updateSolicitud(sol);
                                 }}
                              />
                           </div>
                           <div className="column-middle justify-center">
                              <input
                                 className="input input-huge"
                                 type="datetime-local"
                                 disabled={disableWarranty}
                                 value={
                                    solicitud.Fecha2
                                       ? moment(solicitud.Fecha2).format(
                                            'YYYY-MM-DDTHH:mm:ss'
                                         )
                                       : ''
                                 }
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.Fecha2 = event.target.value;
                                    updateSolicitud(sol);
                                 }}
                              />
                           </div>
                           <div className="column-middle justify-center">
                              <input
                                 className="input input-huge"
                                 type="datetime-local"
                                 disabled={disableWarranty}
                                 value={
                                    solicitud.Fecha3
                                       ? moment(solicitud.Fecha3).format(
                                            'YYYY-MM-DDTHH:mm:ss'
                                         )
                                       : ''
                                 }
                                 onChange={(event) => {
                                    let sol = solicitud;
                                    sol.Fecha3 = event.target.value;
                                    updateSolicitud(sol);
                                 }}
                              />
                           </div>
                        </div>
                        <div className="white-space-8" />
                        <div className="column label-normal">
                           <p className="label-normal">
                              <b style={{ marginRight: '20px' }}>Fecha de visita:</b>
                              {solicitud.FechaVisita
                                 ? moment(solicitud?.FechaVisita).format(
                                      'DD/MM/YYYY hh:mm a'
                                   )
                                 : 'Sin asignar'}
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="tab-three-panel" className="panel">
                  <div className="row full">
                     <div className="column label-normal">
                        <p>
                           <b>Marcar realizado:</b>
                        </p>
                     </div>
                     <div className="column column-small">
                        <label className="container-check">
                           <input
                              type="checkbox"
                              checked={realizado ? true : false}
                              readOnly
                              onClick={() => {
                                 setRealizado(!realizado);
                                 const slc = solicitud;
                                 slc.realizado = !realizado;
                                 updateSolicitud(slc);
                              }}
                           />
                           <span className="checkmark" />
                        </label>
                     </div>
                  </div>
                  <div className="upload-images">
                     <ImageUploader
                        key={ImageUploaderKey}
                        defaultPictures={defaultPictures}
                        pictures={props.pictures}
                        onChange={onDrop.bind(this)}
                     />
                  </div>
               </div>
            </div>
         </div>
         <ModalZoom
            imgZoom={imgZoom}
            video={video}
            closeModal={(value) => [setImgZoom(() => value), setVideo(() => value)]}
         />
      </React.Fragment>
   );
};
export default ModalSolicitud;

function tryParseJson(value) {
    try {
        return JSON.parse(value);
    } catch (e) {
        return value; // Devuelve el valor original si no se puede parsear.
    }
}

function recursiveParse(item) {
    if (Array.isArray(item)) {
        return item.map(recursiveParse); // Procesa cada elemento del array.
    } else if (typeof item === 'object' && item !== null) {
        return Object.keys(item).reduce((acc, key) => {
            acc[key] = recursiveParse(item[key]); // Procesa cada valor del objeto.
            return acc;
        }, {});
    } else if (typeof item === 'string') {
        const parsedItem = tryParseJson(item);
        if (parsedItem !== item) {
            return recursiveParse(parsedItem); // Procesa el elemento parseado si era una cadena JSON.
        }
    }
    return item; // Devuelve el elemento si no es ni un array ni un objeto.
}

export const parseJson = (data) => data.map(recursiveParse);

// Helper function to extract parts
export const extractPathComponents = (attrToChange) => {
    const parts = attrToChange.split('.');
    const objectKey = parts[0].replace(/\[\d+\]/g, '')

    // Match only if array access syntax is used
    const arrayMatch = parts[0].match(/\[\d+]/);

    return [
        objectKey || attrToChange,
        arrayMatch ? arrayMatch[0].replace(/\D/g, '') : null, // Index, or null if no array access
        parts[1] || null, // Property, or null if no further parts
    ];
};

/**
 *  urlApi.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejor de las ruta directas al API
 */

// la empresa DWIT MÉXICO para este proyecto eleva tiene 3 ambientes de desarrollo y se negaron a usar variables de entorno
function getURL() {
   const env = process?.env?.REACT_APP_ENV;
   if (env === 'development') {
      // return 'https://testeleva.azurewebsites.net/';
      return 'http://localhost:8080/';
   } else {
      return 'https://apieleva.azurewebsites.net/';
   }
}

export const URL_API = getURL();

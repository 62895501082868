import moment from 'moment-timezone';

export const manipulateDate = (date, days, format = true) => {
   if (days !== 0) {
      date.setDate(date.getDate() + days);
   }

   if (format) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
   }

   return date;
};

// const defaultValueFormat = (date) => {
//    if (!date) {
//       return '';
//    }
//    return moment(date).format('YYYY-MM-DD');
// };

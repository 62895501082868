import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import WOW from 'wowjs';

import { Consumer } from '../context';
import Request from '../core/httpClient';
import DinamicModal from '../components/modal/DinamicModal';
import Controls from '../components/Controls';
import Paginator from '../components/Paginator';
import Sidebar from '../components/Sidebar';

import TablePropietarios from '../components/usuarios/tables/tablePropietarios';
import ModalPropietario from '../components/usuarios/modal/modalPropietario';
import DeleteModal from '../components/modal/DeleteModal';

const req = new Request();

class Propietarios extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         loadingUsuarios: false,
         loadingPerfiles: false,
         showUsuarios: true,
         showPerfiles: false,
         savingModal: false,
         menus: [],
         usuarios: [],
         proyectos: [],
         unidades: [],
         usuario: {},
         perfil: {},
         idModal: 'modalUsuario',
         openNewModal: this.openNewUsuario.bind(this),
         saveUsuario: this.createUsuario.bind(this),
         filter: '',
      };
   }

   componentDidMount() {
      this.loadUsuarios();
      this.loadProyectos();
      this.loadUnidades();
      new WOW.WOW({
         live: false,
      }).init();
   }

   async selectPage(page) {
      this.setState({ page: page });
   }

   async openModal() {
      let modal = document.getElementById(this.state.idModal);

      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async updateState(json) {
      this.setState(json);
   }

   async closeModal() {
      let modal = document.getElementById(this.state.idModal);
      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   async loadUsuarios() {
      this.setState({
         loadingUsuarios: true,
      });

      const apiRequest = await req.get('propietarios/get', {});

      if (!apiRequest.empty && !apiRequest.error) {
         this.setState({
            usuarios: apiRequest.data,
         });
      } else {
         this.setState({
            usuarios: [],
         });
      }
      this.setState({
         loadingUsuarios: false,
      });
   }

   async loadProyectos() {
      this.setState({
         loadingProyectos: true,
      });
      const response = await req.get('proyectos/get', {});
      if (!response.empty && !response.error) {
         this.setState({
            proyectos: response.data,
         });
      } else {
         this.setState({
            proyectos: [],
         });
      }
      this.setState({
         loadingProyectos: false,
      });
   }

   async loadUnidades() {
      this.setState({
         loadingUnidades: true,
      });
      const response = await req.get('proyectos/get/all/unidades', {});
      if (!response.empty && !response.error) {
         this.setState({
            unidades: response.data,
         });
      } else {
         this.setState({
            unidades: [],
         });
      }
      this.setState({
         loadingUnidades: false,
      });
   }

   openNewUsuario() {
      this.setState({
         usuario: {},
         saveUsuario: this.createUsuario.bind(this),
      });
      this.openModal();
   }

   async setUsuario(usuario) {
      await this.setState({
         usuario: {
            IdPersona: usuario.IdPersona,
            Apellidos: usuario.Apellidos,
            Nombre: usuario.Nombre,
            Email: usuario.Email,
            Telefono: usuario.Telefono,
            NumeroContrato: usuario.NumeroContrato,
            IdProyecto: usuario.IdProyecto,
            IdUnidad: usuario.IdUnidad,
            FechaCompra: usuario.FechaCompra,
            FechaEntrega: usuario.FechaEntrega,
            MesesGarantia: usuario.MesesGarantia,
            Comentarios: usuario.Comentarios,
            IdContrato: usuario.IdContrato,
         },
         idModal: 'modalUsuario',
         saveUsuario: this.updateUsuario.bind(this),
      });
      this.openModal();
   }

   async setUsuarioDelete(usuario) {
      await this.setState({
         usuario: usuario,
         deleteHandler: this.deleteUsuario.bind(this),
         messageDelete: '¿Está seguro de eliminar el usuario?',
         idModal: 'deleteModal',
      });
      this.openModal();
   }

   async createUsuario(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let usuario = this.state.usuario;
      let data = {
         Nombre: usuario.Nombre,
         Apellidos: usuario.Apellidos,
         Email: usuario.Email,
         Telefono: usuario.Telefono,
         IdUnidad: usuario.IdUnidad ? Number.parseInt(usuario.IdUnidad) : 0,
         NumeroContrato: usuario.NumeroContrato,
         FechaCompra: usuario.FechaCompra,
         FechaEntrega: usuario.FechaEntrega ? usuario.FechaEntrega : null,
         MesesGarantia: usuario.MesesGarantia,
         Comentarios: usuario.Comentarios,
         IdContrato: usuario.IdContrato,
      };
      const response = await req.post('propietarios/create', data);
      if (response.created) {
         cogoToast.success('El usuario ha sido guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ usuario: {} });
         this.closeModal();
         this.loadUsuarios();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${response.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async updateUsuario(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let usuario = this.state.usuario;
      let data = {
         IdPersona: usuario.IdPersona,
         Nombre: usuario.Nombre,
         Apellidos: usuario.Apellidos,
         Email: usuario.Email,
         Telefono: usuario.Telefono,
         IdUnidad: usuario.IdUnidad ? Number.parseInt(usuario.IdUnidad) : 0,
         NumeroContrato: usuario.NumeroContrato,
         FechaCompra: usuario.FechaCompra,
         FechaEntrega: usuario.FechaEntrega ? usuario.FechaEntrega : null,
         MesesGarantia: usuario.MesesGarantia,
         Comentarios: usuario.Comentarios,
         IdContrato: usuario.IdContrato,
         Status: 1,
      };

      const requestApi = await req.post('propietarios/update', data);

      if (requestApi.response) {
         cogoToast.success('El usuario ha sido guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ usuario: {} });
         this.closeModal();
         this.loadUsuarios();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async deleteUsuario(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let usuario = this.state.usuario;
      let data = {
         IdPersona: usuario.IdPersona,
         Nombre: usuario.Nombre,
         Apellidos: usuario.Apellidos,
         Email: usuario.Email,
         Telefono: usuario.Telefono,
         IdUnidad: usuario.IdUnidad ? Number.parseInt(usuario.IdUnidad) : 0,
         NumeroContrato: usuario.NumeroContrato,
         FechaCompra: usuario.FechaCompra,
         FechaEntrega: usuario.FechaEntrega ? usuario.FechaEntrega : null,
         MesesGarantia: usuario.MesesGarantia,
         Comentarios: usuario.Comentarios,
         IdContrato: usuario.IdContrato,
         Status: 0,
      };

      const requestApi = await req.post('propietarios/update', data);

      if (requestApi.response) {
         cogoToast.success('El usuario ha sido guardado con éxito.', {
            position: 'top-right',
         });
         this.setState({ usuario: {} });
         this.closeModal();
         this.loadUsuarios();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   setFilter(filter) {
      this.setState({
         filter: filter.toLowerCase(),
      });
   }

   filter(usuarios) {
      usuarios = usuarios.filter((user) => {
         if (user.Nombre.toLowerCase().indexOf(this.state.filter) !== -1) {
            return true;
         } else if (user.Apellidos.toLowerCase().indexOf(this.state.filter) !== -1) {
            return true;
         } else if (user.NombreProyecto.toLowerCase().indexOf(this.state.filter) !== -1) {
            return true;
         } else if (user.Numero.toLowerCase().indexOf(this.state.filter) !== -1) {
            return true;
         } else if (user.NumeroContrato.toLowerCase().indexOf(this.state.filter) !== -1) {
            return true;
         } else {
            return false;
         }
      });

      return usuarios;
   }

   render() {
      let usuarios = [];

      if (this.state.usuarios) {
         usuarios = this.state.usuarios;
         usuarios = this.filter(usuarios);
      }

      return (
         <div className="column justify-center align-center usuarios">
            <Helmet>
               <title>ELEVA Capital Group | Propetarios</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>
                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Configuración</h3>
                        <h3 className="color-secondary">&nbsp;/&nbsp;</h3>
                        <h4 className="color-darkgray">Propetarios</h4>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls
                              newHandler={this.state.openNewModal}
                              showFilter={true}
                              setFilter={this.setFilter.bind(this)}
                           />
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center">
                           <div className="container">
                              <div
                                 className="column card-table full align-center table-side wow fadeIn"
                                 data-wow-delay="0.3s">
                                 <TablePropietarios
                                    usuarios={usuarios}
                                    loadingUsuarios={this.state.loadingUsuarios}
                                    page={this.state.page}
                                    setUsuario={this.setUsuario.bind(this)}
                                    setUsuarioDelete={this.setUsuarioDelete.bind(this)}
                                 />

                                 <div className="row">
                                    <div className="white-space-8"></div>
                                 </div>
                                 <div className="row justify-center align-center">
                                    <Paginator
                                       pages={Math.ceil(usuarios.length / 15)}
                                       setPage={this.selectPage.bind(this)}
                                    />
                                 </div>
                                 <div className="row">
                                    <div className="white-space-8"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                     </div>
                  </div>
               </div>

               <DinamicModal
                  idModal={'modalUsuario'}
                  sizeModalContainer={'small'}
                  title={'Propietarios'}
                  btnTextSuccess={'Guardar'}
                  success={this.state.saveUsuario}
                  showBtnSuccess={true}
                  btnTextCancel={'Cancelar'}
                  showBtnCancel={true}
                  loadingSave={this.state.savingModal}>
                  <ModalPropietario
                     usuario={this.state.usuario}
                     updateState={this.updateState.bind(this)}
                     perfiles={this.state.perfiles}
                     proyectos={this.state.proyectos}
                     unidades={this.state.unidades}
                  />
               </DinamicModal>
               <DeleteModal
                  id="deleteModal"
                  title="Eliminar"
                  message={this.state.messageDelete}
                  acceptMethod={this.state.deleteHandler}
                  savingModal={this.state.savingModal}
               />
            </div>
         </div>
      );
   }
}
export default withRouter(Consumer(Propietarios));

import Request from '../../core/httpClient';
const req = new Request();

export async function getMenu() {
   let menu = JSON.parse(window.localStorage.getItem('menusr'));
   let session = JSON.parse(window.localStorage.getItem('bdusr'));
   if (menu) {
      this.setState({ menu: menu });
      return menu;
   } else if (session.token) {
      const res = await req.get('menu/by/usuario', { token: session.token });
      if (res.token && res.menu) {
         localStorage.setItem(
            'bdusr',
            JSON.stringify({
               ...session,
               token: res.token,
            })
         );
         localStorage.setItem('menusr', JSON.stringify(res.menu));
         this.setState({
            menu: res.menu,
         });
         return res.menu;
      }
      return [];
   } else {
      return [];
   }
}

import React, { useState, useEffect } from 'react';

const ModalProyecto = props => {
   const [unidad, setUnidad] = useState({});
   const [tipos, setTipos] = useState([]);

   useEffect(() => {
      setUnidad(props.unidad);
      setTipos(props.tipos);
   }, [props.unidad, props.tipos]);

   function updateUnidad(un) {
      props.updateJson({ unidad: un });
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-16"></div>
            <div className="row full align-center">
               <div className="column label-medium">
                  <p>
                     <b>Número:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={unidad.Numero ? unidad.Numero : ''}
                     onChange={event => {
                        let und = unidad;
                        und.Numero = event.target.value;
                        updateUnidad(und);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-medium">
                  <p>
                     <b>Tipo Unidad:</b>
                  </p>
               </div>
               <div className="column full">
                  <select
                     className="input input-modals"
                     value={unidad.IdTipoUnidad ? unidad.IdTipoUnidad : ''}
                     onChange={event => {
                        let und = unidad;
                        und.IdTipoUnidad = event.target.value;
                        updateUnidad(und);
                     }}
                     required
                  >
                     <option value="" disabled>
                        Tipo Unidad
                     </option>
                     {tipos.map((tip, key) => (
                        <option key={key} value={tip.IdTipoUnidad}>
                           {tip.Nombre}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-medium">
                  <p>
                     <b>Observaciones:</b>
                  </p>
               </div>
               <div className="column full">
                  <textarea
                     className="input"
                     value={unidad.Observaciones ? unidad.Observaciones : ''}
                     onChange={event => {
                        let und = unidad;
                        und.Observaciones = event.target.value;
                        updateUnidad(und);
                     }}
                  ></textarea>
               </div>
            </div>
            <div className="white-space-8" />
         </div>
      </div>
   );
};
export default ModalProyecto;

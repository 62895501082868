import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

const ModalContainer = (props) => {
   const {
      onCancel,
      onClose,
      onNavigate = null,
      onSuccess,
      isFirstPage = true,
      isLastPage = false,
      isLoading = false,
      title,
      children,
      btnSuccessText,
      size,
      className,
   } = props;
   const [sizeModal, setSizeModal] = useState(
      'column dinamic-modal-container dinamic-modal-normal'
   );

   useEffect(() => {
      switch (size) {
         case 'big':
            setSizeModal('column dinamic-modal-container dinamic-modal-big');
            break;
         case 'normal':
            setSizeModal('column dinamic-modal-container dinamic-modal-normal');
            break;
         case 'small':
            setSizeModal('column dinamic-modal-container dinamic-modal-small');
            break;
         case 'mini':
            setSizeModal('column dinamic-modal-container dinamic-modal-mini');
            break;
         case 'micro':
            setSizeModal('column dinamic-modal-container dinamic-modal-micro');
            break;
         case 'super':
            setSizeModal('column dinamic-modal-container dinamic-modal-super-big');
            break;
         default:
            setSizeModal('column dinamic-modal-container dinamic-modal-normal');
            break;
      }
   }, [size]);

   useEffect(() => {
      return () => {};
   }, []);

   return (
      <div className="overlay">
         <Draggable handle=".dinamic-header">
            <div className={`${sizeModal} ${className}`}>
               <div className="dinamic-header row justify-center">
                  <div className="column modal-container justify-center">
                     <h4 className="title">{title}</h4>
                  </div>
                  <button
                     className="btn-modal-close justify-center align-center"
                     onClick={onClose}>
                     <i className="fas fa-times"></i>
                  </button>
               </div>

               <div
                  className="dinamic-body row justify-center"
                  style={{ height: '100%' }}>
                  <div className="column modal-container">
                     <form
                        className="flex column justify-between"
                        style={{ height: '100%' }}
                        onSubmit={(e) => onSuccess(e)}>
                        <div className="flex column justify-center">{children}</div>
                        <div className="white-space-16"></div>
                        <div className="dinamic-footer row justify-end">
                           <div className="row justify-end full">
                              <button
                                 type="button"
                                 className="btn btn-small btn-action-cancel"
                                 onClick={onCancel}>
                                 {/* <i className="fas fa-arrow-left"></i> */}
                                 <i className="fas fa-times"></i>
                                 <i className="weight-bold">Cancelar</i>
                              </button>
                              {onNavigate && (
                                 <>
                                    {!isFirstPage && (
                                       <button
                                          type="button"
                                          className="btn btn-small btn-action-success"
                                          onClick={() => onNavigate('back')}>
                                          <i className="weight-bold">Anterior</i>
                                       </button>
                                    )}

                                    {!isLastPage && (
                                       <button
                                          type="button"
                                          className="btn btn-small btn-action-success"
                                          onClick={() => onNavigate('next')}>
                                          <i className="weight-bold">Siguiente</i>
                                       </button>
                                    )}
                                 </>
                              )}
                              {(isLastPage || !onNavigate) && (
                                 <button
                                    type="submit"
                                    className="btn btn-small btn-action-success"
                                    disabled={isLoading}>
                                    {isLoading ? (
                                       <i className="fas fa-spinner fa-spin"></i>
                                    ) : (
                                       <i className="weight-bold">{btnSuccessText}</i>
                                    )}
                                 </button>
                              )}
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div className="white-space-16"></div>
            </div>
         </Draggable>
      </div>
   );
};

export default ModalContainer;

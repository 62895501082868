/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import ItemInput from '../ItemInput';
import { costsControlApi } from '../../../../api';
import ItemOptionsTable from '../ItemOptionsTable';
import ItemCheckbox from '../ItemCheckbox';
import ItemDate from '../ItemDate';
import ItemOptions from '../ItemOptions';

const StaffDetails = (props) => {
   const { item, onEditItem } = props;
   const [categories, setCategories] = useState([]);
   const [regions, setRegions] = useState([]);
   const [isLoadingRegions, setIsLoadingRegions] = useState(true);
   const [isLoadingCategories, setIsLoadingCategories] = useState(true);

   const isItemActive = item.id_status === 1;

   const fetchCategories = async () => {
      const items = await costsControlApi.getStaffCategories();
      setCategories(items);
   };

   const fetchRegions = async () => {
      const items = await costsControlApi.getRegions();
      setRegions(items);
   };

   const defaultValues = {
      name: '',
      cost: 150,
      categories: [],
      region: {},
      id_status: 1,
      start_date: moment(new Date().toISOString().substring(0, 10), 'YYYY-MM-DD').format(
         'DD/MM/YYYY'
      ),
      end_date: null,
   };

   useEffect(() => {
      fetchCategories();
      fetchRegions();
      Object.keys(defaultValues).forEach((key) => {
         if (item[key] === undefined) {
            onEditItem(defaultValues[key], key);
         }
      });
   }, []);

   useEffect(() => {
      if (!regions || regions.length === 0) return;
      setIsLoadingRegions(false);
   }, [regions]);

   useEffect(() => {
      if (!categories || categories.length === 0) return;
      setIsLoadingCategories(false);
   }, [categories]);

   return (
      <React.Fragment>
         <ItemInput
            label="Nombre"
            value={item.name}
            onValueUpdate={onEditItem}
            attributeToChange="name"
         />
         <div className="white-space-16" />
         <ItemInput
            type="number"
            label="Costo/hora"
            value={item.cost}
            onValueUpdate={onEditItem}
            attributeToChange="cost"
         />
         <div className="white-space-16" />
         <ItemOptionsTable
            label="Categoría"
            items={item.categories}
            options={categories}
            select="Seleccione una categoría"
            onValueUpdate={onEditItem}
            attributeToChange="categories"
            isLoadingOptions={isLoadingCategories}
         />
         <div className="white-space-16" />

         <ItemOptions
            label="Región"
            options={regions}
            select={item.region}
            onChange={onEditItem}
            attributeToChange="region"
            isLoading={isLoadingRegions}
         />
         <div className="white-space-16" />
         <ItemCheckbox
            label="Activo"
            checked={isItemActive}
            onValueUpdate={onEditItem}
            attributeToChange="id_status"
         />
         <div className="white-space-16" />
         <div className="row align-center full">
            <ItemDate
               label="Fecha Inicio"
               date={item.start_date}
               onValueUpdate={onEditItem}
               attributeToChange="start_date"
            />
            <ItemDate
               label="Fecha Fin"
               date={item.end_date}
               onValueUpdate={onEditItem}
               attributeToChange="end_date"
            />
         </div>
      </React.Fragment>
   );
};

export default StaffDetails;

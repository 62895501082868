/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import ItemInput from '../ItemInput';
import ItemCheckbox from '../ItemCheckbox';
import ItemOptionsTable from '../ItemOptionsTable';
import { costsControlApi } from '../../../../api';
import ItemOptions from '../ItemOptions';

const TaskDetails = (props) => {
   const { item, onEditItem } = props;

   const [materials, setMaterials] = useState([]);
   const [personalCategories, setPersonalCategories] = useState([]);
   const [categories, setCategories] = useState([]);

   const defaultValues = {
      name: '',
      description: '',
      id_status: 1,
      materials: [],
      personal_categories: [],
   };

   const isItemActive = item.id_status === 1;

   const scrollableOptions = {
      display: 'block',
      maxHeight: '200px',
      overflowY: 'auto',
   };

   useEffect(() => {
      (async () => {
         Object.keys(defaultValues).forEach((key) => {
            if (!item[key]) {
               onEditItem(defaultValues[key], key);
            }
         });
         const detailsOptions = await costsControlApi.getTasksDetailsList();
         setMaterials(detailsOptions.materials);
         setPersonalCategories(detailsOptions.personal_categories);
         setCategories(() => detailsOptions.categories);
      })();
   }, []);

   return (
      <React.Fragment>
         <ItemInput
            label="Nombre"
            value={item.name}
            onValueUpdate={onEditItem}
            attributeToChange="name"
         />
         <div className="white-space-16" />

         <ItemInput
            label="Descripción"
            value={item.description}
            onValueUpdate={onEditItem}
            attributeToChange="description"
         />
         <div className="white-space-16" />

         <ItemOptions
            label="Categoría"
            options={categories}
            select={item.category}
            onChange={onEditItem}
            attributeToChange="category"
            isLoading={!categories}
         />
         <div className="white-space-16" />

         <ItemCheckbox
            label="Activo"
            checked={isItemActive}
            onValueUpdate={onEditItem}
            attributeToChange="id_status"
         />
         <div className="white-space-16" />

         <div className="flex-container justify-between gap-15">
            <ItemOptionsTable
               items={item.personal_categories}
               options={personalCategories}
               select="Seleccione categoría personal"
               onValueUpdate={onEditItem}
               attributeToChange="personal_categories"
               scrollableOptions={scrollableOptions}
            />
            <div className="white-space-16" />

            <ItemOptionsTable
               items={item.materials}
               options={materials}
               select="Seleccione material"
               onValueUpdate={onEditItem}
               attributeToChange="materials"
               scrollableOptions={scrollableOptions}
            />
         </div>
      </React.Fragment>
   );
};

export default TaskDetails;

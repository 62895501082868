import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const ModalUsuarios = props => {
   const [usuario, setUsuario] = useState({});
   const [perfiles, setPerfiles] = useState([]);
   const [unidades, setUnidades] = useState([]);
   const [proyectos, setProyectos] = useState([]);

   useEffect(() => {
      setPerfiles(props.perfiles);
   }, [props.perfiles]);

   useEffect(() => {
      setUsuario(props.usuario);
   }, [props.usuario]);

   useEffect(() => {
      setUnidades(props.unidades);
   }, [props.unidades]);

   useEffect(() => {
      setProyectos(props.proyectos);
   }, [props.proyectos]);

   function updateUsuario(usr) {
      props.updateState({
         usuario: usr,
      });
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-16" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Usuario (correo):</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={usuario.Email ? usuario.Email : ''}
                     onChange={event => {
                        let usr = usuario;
                        usr.Email = event.target.value;
                        updateUsuario(usr);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Nombre:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={usuario.Nombre ? usuario.Nombre : ''}
                     onChange={event => {
                        let usr = usuario;
                        usr.Nombre = event.target.value;
                        updateUsuario(usr);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Apellidos:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={usuario.Apellidos ? usuario.Apellidos : ''}
                     onChange={event => {
                        let usr = usuario;
                        usr.Apellidos = event.target.value;
                        updateUsuario(usr);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8"></div>
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Teléfono:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={usuario.Telefono ? usuario.Telefono : ''}
                     onChange={event => {
                        let usr = usuario;
                        usr.Telefono = event.target.value;
                        updateUsuario(usr);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8"></div>
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Fecha Alta:</b>
                  </p>
               </div>
               <div className="column column-small">
                  <input
                     className="input"
                     type="date"
                     value={
                        usuario.FechaAlta
                           ? moment(usuario.FechaAlta).format('YYYY-MM-DD')
                           : ''
                     }
                     onChange={event => {
                        let usr = usuario;
                        usr.FechaAlta = event.target.value;
                        updateUsuario(usr);
                     }}
                     autoComplete="off"
                     required
                  />
               </div>
               <div className="column label-middle">
                  <p>
                     <b>Perfil:</b>
                  </p>
               </div>
               <div className="column column-small">
                  <select
                     className="input input-modals"
                     value={usuario.IdPerfil ? usuario.IdPerfil : ''}
                     onChange={event => {
                        let usr = usuario;
                        usr.IdPerfil = event.target.value;
                        updateUsuario(usr);
                     }}
                  >
                     <option value="" disabled>
                        Elija un perfil
                     </option>
                     {perfiles
                        ? perfiles.map((perfil, key) => (
                             <option key={key} value={perfil.IdPerfil}>
                                {perfil.NombrePerfil}
                             </option>
                          ))
                        : null}
                  </select>
               </div>
            </div>
            <div className="white-space-8"></div>
         </div>
      </div>
   );
};
export default ModalUsuarios;

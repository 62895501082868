import React, { useState, useEffect } from 'react';

const ModalFolder = (props) => {
   const [folder, setFolder] = useState({});
   const [proyectos, setProyectos] = useState([]);

   useEffect(() => {
      setFolder(props.folder);
   }, [props.folder]);

   useEffect(() => {
      setProyectos(props.proyectos);
   }, [props.proyectos]);

   function updateFolder(fld) {
      props.updateJson({ folder: fld });
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-16" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Nombre:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     className="input"
                     value={folder.NombreFolder ? folder.NombreFolder : ''}
                     onChange={(event) => {
                        let fld = folder;
                        fld.NombreFolder = event.target.value;
                        updateFolder(fld);
                     }}></input>
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Proyecto:</b>
                  </p>
               </div>
               <div className="column full">
                  <select
                     name=""
                     className="input input-modals"
                     value={folder.IdProyecto ? folder.IdProyecto : ''}
                     onChange={(event) => {
                        let fld = folder;
                        fld.IdProyecto = Number(event.target.value);
                        updateFolder(fld);
                     }}>
                     <option value="">Todos</option>
                     {proyectos.map((pry, key) => (
                        <option key={key} value={pry.IdProyecto}>
                           {pry.Nombre}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <div className="white-space-8" />
         </div>
      </div>
   );
};

export default ModalFolder;

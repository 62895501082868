import React, { useEffect, useState } from 'react';

const TableTipoUnidad = props => {
   const [tipos, setTipos] = useState([]);

   useEffect(() => {
      if (props.tipos) {
         setTipos(props.tipos);
      }
   }, [props.tipos]);

   return (
      <div
         className="table-responsive full wow fadeInRight"
         id="tableTipoUnidad"
         data-wow-duration="0.2s"
      >
         <table>
            <thead>
               <tr>
                  <th className="text-left">Nombre Tipo</th>
                  <th className="text-left">Metros</th>
                  <th className="text-center th-lg">Planos</th>
                  <th className="text-center th-sm"></th>
                  <th className="text-center th-sm"></th>
               </tr>
            </thead>
            <tbody>
               {props.loadingTipos ? (
                  <tr>
                     <td className="text-center" colSpan="5">
                        <i className="fas fa-spinner fa-spin"></i>
                     </td>
                  </tr>
               ) : tipos.length === 0 ? (
                  <tr>
                     <td className="text-center" colSpan="5">
                        Sin datos que mostrar
                     </td>
                  </tr>
               ) : (
                  tipos
                     .slice((props.page - 1) * 15, props.page * 15)
                     .map((tip, key) => (
                        <tr key={key}>
                           <td className="text-left">{tip.Nombre}</td>
                           <td className="text-left">{tip.Metros}</td>
                           <td className="text-center th-lg">
                              <button
                                 className=" btn-small btn-empty"
                                 type="button"
                                 onClick={() => {}}
                              >
                                 <i className="fas fa-download"></i>
                              </button>
                           </td>
                           <td className="text-center th-sm">
                              <button
                                 className=" btn-small btn-empty"
                                 type="button"
                                 onClick={() => {
                                    props.setToUpdateTipo(tip);
                                 }}
                              >
                                 <i className="font-small fas fa-edit" />
                              </button>
                           </td>
                           <td className="text-center th-sm">
                              <button
                                 className=" btn-small btn-empty"
                                 type="button"
                                 onClick={() => {
                                    props.setTipoDelete(tip);
                                 }}
                              >
                                 <i className="font-small fas fa-trash-alt" />
                              </button>
                           </td>
                        </tr>
                     ))
               )}
            </tbody>
         </table>
      </div>
   );
};
export default TableTipoUnidad;

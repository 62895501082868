// node_module
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import WOW from 'wowjs';
import moment from 'moment-timezone';

// generales
import { Consumer } from '../context';
import Request from '../core/httpClient';
import Controls from '../components/Controls';
import Sidebar from '../components/Sidebar';
import GraphDashboard from '../components/controlCostos/components/GraphDashboard';
import { costsGraphApi } from './../api';
import { formatMoney } from '../utils/formatMoney';

const req = new Request();

const ReportSheet = (props) => {
   return (
      <React.Fragment key={props.key}>
         <div
            className="reporter_sheet"
            style={{
               width: '100%',
            }}>
            <div className="reporter_content">{props.children}</div>
         </div>
         {/* <div className="jump-page"></div> */}
      </React.Fragment>
   );
};

class Consultas extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 1,
         reportes: [],
         data: [],
         reporte: '',
         loadingReportes: false,
         notificacion: {},
         desde: new Date().setMonth(new Date().getMonth() - 1),
         hasta: new Date(),
         showGraphDashboard: false,
         cardsData: [],
      };
   }

   async componentDidMount() {
      new WOW.WOW({
         live: false,
      }).init();
      this.loadReportes();
   }

   // load

   async loadReportes() {
      this.setState({
         loadingReportes: true,
      });

      const requestApi = await req.get('consultas/get', {});

      if (!requestApi.error) {
         this.setState({
            reportes: requestApi.data,
         });
      } else {
         this.setState({
            reportes: [],
         });
      }
      this.setState({
         loadingReportes: false,
      });
   }

   // generales

   async selectPage(page) {
      this.setState({ page: page });
   }

   async generateQuery(e) {
      e.preventDefault();
      let reportes = this.state.reportes.filter(
         (rep) => rep.IdReporte === Number(this.state.reporte)
      );

      if (reportes.length <= 0) {
         return;
      }
      const actions = {
         KPIs: () => {
            this.setState({
               showGraphDashboard: true,
            });
            const fetchGraphCardsData = async () => {
               const graphCardsData = await costsGraphApi.getKpi(
                  this.formatDate(this.state.desde),
                  this.formatDate(this.state.hasta)
               );
               this.setState({ cardsData: graphCardsData });
            };

            fetchGraphCardsData();
         },
         Solicitudes: () => {
            this.setState({
               showGraphDashboard: false,
            });
         },
         'Estado reportes': () => {
            this.setState({
               showGraphDashboard: false,
            });
         },
      };
      actions[reportes[0]?.Nombre]();

      let data = {
         Desde: moment(this.state.desde),
         Hasta: moment(this.state.hasta),
         Procedure: reportes[0].Consulta,
      };

      let responseApi = await req.post('consultas/execute', data);

      if (!responseApi.error) {
         this.setState({
            data: responseApi.data,
         });
      } else {
         this.setState({
            data: [],
         });
      }
      this.setState({ updateGraphDashboard: false });
   }

   formatDate(date) {
      const formatPattern = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
      const momentDate = moment(date);
      const formattedDate = momentDate.utc().format(formatPattern);
      return formattedDate;
   }

   async ExportExcel() {
      this.setState({
         excelDisabled: true,
      });
      let dataSet = this.state.data;
      // Definición de columnas
      if (!dataSet[0].excelColumnsShow) {
         cogoToast.error('No se encontraron definición de columnas en el reporte.', {
            position: 'top-right',
         });
         this.setState({
            excelDisabled: false,
         });
         return;
      }
      let excelColumnsShow = dataSet[0].excelColumnsShow;
      excelColumnsShow = excelColumnsShow.split(',');
      let excelColumnsName = dataSet[0].excelColumnsName;
      excelColumnsName = excelColumnsName.split(',');
      let excelColumnsType = dataSet[0].excelColumnsType;
      excelColumnsType = excelColumnsType.split(',');
      // Construir las filas
      let rows = [];
      dataSet.forEach((element) => {
         const object = {};
         excelColumnsShow.forEach((columnShow, index) => {
            const column = excelColumnsName[index];
            const row = element[columnShow];
            object[column] = row;
         });
         rows.push(object);
      });
      //Datos a enviar al API
      const { context } = this.props;
      let titulo = 'Reporte';
      const select = document.getElementById('report-select');
      if (select) {
         titulo = select.options[select.selectedIndex].text;
      }
      dataSet = {
         columns: excelColumnsName,
         rows,
         excelColumnsType,
         titulo: titulo.toUpperCase(),
         fraccionamiento: context.fraccionamiento || 'Reporte',
         usuario: context.usuario || '',
         fecha: moment().format('DD/MM/YYYY HH:mm'),
         tz: moment.tz.guess(),
      };
      const data = {
         info: dataSet,
      };
      const response = await req.post('consultas/exportar/reporte', data);
      const { buffer } = response;
      if (buffer) {
         cogoToast.success('Generando archivo.', {
            position: 'bottom-right',
         });
         const bytes = new Uint8Array(buffer.data);
         const blob = new Blob([bytes]);
         const link = document.createElement('a');
         link.href = window.URL.createObjectURL(blob);
         const fileName = `${titulo}_${moment().format('YYMMDDHHmm')}.xlsx`;
         link.download = fileName;
         link.click();
      } else {
         cogoToast.error(response.message || 'No se pudo crear el documento de excel.', {
            position: 'bottom-right',
         });
      }
      this.setState({
         excelDisabled: false,
      });
   }

   PrintElem() {
      let columns = '';
      let rows = '';
      let dataSet = this.state.data;
      // Definición de columnas
      let excelColumnsShow = dataSet[0].excelColumnsShow;
      if (excelColumnsShow) {
         //Si el store tiene formato de excel
         excelColumnsShow = excelColumnsShow.split(',');
         let excelColumnsName = dataSet[0].excelColumnsName;
         excelColumnsName = excelColumnsName.split(',');
         let excelColumnsType = dataSet[0].excelColumnsType;
         excelColumnsType = excelColumnsType.split(',');

         const cols = excelColumnsName;
         cols.forEach((col) => {
            columns = columns + `<th>${col}</th>`;
         });
         dataSet.forEach((row) => {
            let newRow = '';
            excelColumnsShow.forEach((col, colIndex) => {
               if (excelColumnsType[colIndex] === 'image') {
                  if (
                     row[col].indexOf('data:image/') !== -1 ||
                     row[col].indexOf('http://') !== -1 ||
                     row[col].indexOf('https://') !== -1
                  ) {
                     newRow = newRow + `<td><img src="${row[col]}" width="150"/></td>`;
                  } else {
                     newRow =
                        newRow +
                        `<td><img src="data:image/png;base64,${row[col]}" alt="reportImage" width="150"></img></td>`;
                  }
               } else if (
                  excelColumnsType[colIndex] === 'date' ||
                  excelColumnsType[colIndex] === 'datetime'
               ) {
                  if (moment(row[col]).isValid()) {
                     newRow =
                        newRow +
                        `<td>${moment(row[col]).format('DD/MM/YYYY HH:mm')}</td>`;
                  } else {
                     newRow = newRow + `<td>-</td>`;
                  }
               } else {
                  newRow = newRow + `<td>${row[col] || ''}</td>`;
               }
            });
            rows = rows + `<tr>${newRow}</tr>`;
         });
      } else {
         const cols = Object.keys(dataSet[0]);
         cols.forEach((col) => {
            columns = columns + `<th>${col}</th>`;
         });

         dataSet.forEach((row) => {
            const newRow = cols.map((col) => `<td>${row[col] || ''}</td>`);
            rows = rows + `<tr>${newRow}</tr>`;
         });
      }

      const html = `<html>
        <head>
            <title>Reporte</title>
            <style>
                body {
                    font-family: "Mukta", sans-serif;
                    font-size: 11px;
                }
                th {
                    white-space: nowrap;
                }
                td {
                    color: #4e4a4a;
                }
            </style>
        </head>
        <body>
            <table>
                <thead>
                    <tr>
                        ${columns}
                    </tr>
                </thead>
                <tbody>
                    ${rows}
                </tbody>
            </table>
        </body></html>`;
      const mywindow = window.open('', 'PRINT', 'height=400,width=600');
      mywindow.document.write(html);

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
   }

   render() {
      //Definición de hojas
      let report_sheets = [];
      //Data State
      let data = this.state.data;
      //DataSet
      let dataSet = [],
         columns = [];
      if (this.state.loading) {
         report_sheets.push(
            <div className="row justify-center align-center">
               <div>
                  <div className="white-space-32"></div>
                  <i className="fas fa-spinner fa-spin"></i>
               </div>
            </div>
         );
      } else if (Array.isArray(data)) {
         if (data.length <= 0) {
            report_sheets.push(
               <div className="row justify-center align-center">
                  <div className="white-space-64"></div>
                  <div>
                     <h1>Sin Datos</h1>
                  </div>
               </div>
            );
         } else {
            report_sheets = [];
            dataSet = data;
            let sheets = Math.ceil(dataSet.length / 30);
            let rowsSheet = 30;
            //ExcelFormat Options
            if (dataSet[0].excelColumnsShow) {
               if (dataSet[0].rowSheet) {
                  rowsSheet = dataSet[0].rowSheet;
                  sheets = Math.ceil(dataSet.length / rowsSheet);
               }
               // Definición de columnas
               let excelColumnsShow = dataSet[0].excelColumnsShow;
               excelColumnsShow = excelColumnsShow.split(',');
               let excelColumnsName = dataSet[0].excelColumnsName;
               excelColumnsName = excelColumnsName.split(',');
               let excelColumnsType = dataSet[0].excelColumnsType;
               excelColumnsType = excelColumnsType.split(',');

               columns = dataSet[0].excelColumnsName.split(',');
               // Reconstruir las filas
               let rows = [];
               dataSet.forEach((element) => {
                  const object = {};
                  excelColumnsShow.forEach((columnShow, colIndex) => {
                     const column = excelColumnsName[colIndex];
                     let row = element[columnShow] || '';
                     const type = excelColumnsType[colIndex];
                     if (type === 'image') {
                        if (
                           row.indexOf('data:image/') !== -1 ||
                           row.indexOf('http://') !== -1 ||
                           row.indexOf('https://') !== -1
                        ) {
                           row = <img src={row} alt="reportImage" width="150"></img>;
                        } else {
                           row = (
                              <img
                                 src={`data:image/png;base64,${row}`}
                                 alt="reportImage"
                                 width="150"></img>
                           );
                        }
                        object[column] = row;
                     } else if (type === 'date' || type === 'datetime') {
                        if (moment(row).isValid()) {
                           object[column] = moment(row).format('DD/MM/YYYY HH:mm');
                        } else {
                           object[column] = '-';
                        }
                     } else {
                        object[column] = row;
                     }
                  });
                  rows.push(object);
               });
               dataSet = rows;
            } else {
               columns = Object.keys(dataSet[0]);
            }

            //Construcción del reporte
            for (let sheet = 1; sheet <= sheets; sheet++) {
               const Sheet = (
                  <ReportSheet key={sheet}>
                     <table
                        style={{
                           width: '100%',
                        }}>
                        <thead>
                           <tr>
                              {columns.map((column, key) => (
                                 <th key={key}>{column}</th>
                              ))}
                           </tr>
                        </thead>
                        <tbody>
                           {dataSet
                              .slice((sheet - 1) * rowsSheet, sheet * rowsSheet)
                              .map((row, index) => (
                                 <tr key={index}>
                                    {columns.map((column) => {
                                       const isCurrency =
                                          row.Concepto?.includes('$') &&
                                          (typeof row[column] === 'number' ||
                                             !row[column]);
                                       const isConcept = typeof row[column] === 'string';

                                       return (
                                          <td
                                             style={{
                                                fontSize: '13px',
                                             }}>
                                             <span
                                                style={{
                                                   width: isCurrency ? '15%' : '100%',
                                                   display: 'flex',
                                                   justifyContent: isCurrency
                                                      ? 'end'
                                                      : 'start',
                                                }}>
                                                {isCurrency
                                                   ? formatMoney(row[column])
                                                   : isConcept
                                                   ? row[column].replace(
                                                        /(\r\n|\n|\$|\r)/gm,
                                                        ' '
                                                     )
                                                   : row[column]}
                                             </span>
                                          </td>
                                       );
                                    })}
                                 </tr>
                              ))}
                        </tbody>
                     </table>
                  </ReportSheet>
               );
               report_sheets.push(Sheet);
            }
         }
      } else {
         report_sheets.push(
            <div className="row justify-center align-center">
               <div className="white-space-64"></div>
               <div>
                  <h1>Sin Datos</h1>
               </div>
            </div>
         );
      }

      return (
         <div className="column justify-center align-center proyectos">
            <Helmet>
               <title>ELEVA Capital Group | Consultas</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>
                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Consultas</h3>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls showFilter={false}>
                              <form
                                 className="justify-end align-center full"
                                 onSubmit={(event) => {
                                    this.generateQuery(event);
                                 }}>
                                 <div className="row justify-end align-center full">
                                    <div className="column text-normal">
                                       <p className="color-secondary">
                                          <b>Desde:</b>
                                       </p>
                                    </div>
                                    <div className="column ">
                                       <input
                                          className="input"
                                          value={moment(this.state.desde).format(
                                             'YYYY-MM-DD'
                                          )}
                                          type="date"
                                          onChange={(event) => {
                                             this.setState({
                                                desde: event.target.value,
                                             });
                                          }}
                                       />
                                    </div>
                                    <div className="column text-normal padding-left-semi">
                                       <p className="color-secondary">
                                          <b>Hasta:</b>
                                       </p>
                                    </div>
                                    <div className="column ">
                                       <input
                                          className="input"
                                          type="date"
                                          value={moment(this.state.hasta).format(
                                             'YYYY-MM-DD'
                                          )}
                                          onChange={(event) => {
                                             this.setState({
                                                hasta: event.target.value,
                                             });
                                          }}
                                       />
                                    </div>
                                    <div className="column text-normal padding-left-semi">
                                       <p className="color-secondary">
                                          <b>Reporte:</b>
                                       </p>
                                    </div>
                                    <div className="column">
                                       <select
                                          name=""
                                          className="input full"
                                          value={this.state.reporte}
                                          onChange={(event) => {
                                             this.setState({
                                                reporte: event.target.value,
                                             });
                                          }}
                                          required>
                                          <option value="" disabled>
                                             Consultas
                                          </option>
                                          {this.state.reportes.map((rpt, key) => (
                                             <option key={key} value={rpt.IdReporte}>
                                                {rpt.Nombre}
                                             </option>
                                          ))}
                                       </select>
                                    </div>
                                    <div className="column padding-left-semi">
                                       <button
                                          type="submit"
                                          className="btn btn-secondary btn-small color-white ">
                                          <i className="fas fa-sign-in-alt"></i>
                                          &nbsp; Generar
                                       </button>
                                    </div>
                                 </div>
                              </form>
                           </Controls>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center wow fadeIn">
                           <div className="container">
                              <div id="reporter" className="reporter">
                                 <div id="reporter_tools">
                                    <button
                                       id="rptBtnExcel"
                                       onClick={this.ExportExcel.bind(this)}
                                       disabled={this.state.excelDisabled}>
                                       <i className="far fa-file-excel" />
                                    </button>
                                    <button
                                       id="rptBtnPrint"
                                       onClick={this.PrintElem.bind(
                                          this,
                                          'reporter_content'
                                       )}>
                                       <i className="fas fa-print" />
                                    </button>
                                 </div>
                                 <div id="reporter_main">
                                    <div id="reporter_content">{report_sheets}</div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center">
                           <div className="container justify-between wrap gap-20">
                              {this.state.showGraphDashboard && (
                                 <GraphDashboard
                                    startDate={this.formatDate(this.state.desde)}
                                    endDate={this.formatDate(this.state.hasta)}
                                    cardsData={this.state.cardsData}
                                 />
                              )}
                           </div>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default withRouter(Consumer(Consultas));

import React from 'react';
import Helmet from 'react-helmet';
import { withRouter, Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import WOW from 'wowjs';

import { Consumer } from '../context';
import Request from '../core/httpClient';
import DinamicModal from '../components/modal/DinamicModal';
import Controls from '../components/Controls';
import Sidebar from '../components/Sidebar';

import TableAreas from '../components/catalogos/tables/tableAreas';
import TableEquipos from '../components/catalogos/tables/tableEquipos';
import TableProblemas from '../components/catalogos/tables/tableProblemas';
import ModalCatalogo from '../components/catalogos/modal/modalCatalogo';

const req = new Request();

class Catalogo extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         areas: [],
         equipos: [],
         problemas: [],
         showAreas: true,
         loadingAreas: false,
         showEquipos: false,
         loadingEquipos: false,
         showProblemas: false,
         loadingProblemas: false,
         savingModal: false,
         newValue: '',
         titleNewModal: 'Nueva Área',
         saveMethod: this.saveAreaMethod.bind(this),
      };
   }

   componentDidMount() {
      this.loadAreas();
      this.loadProblemas();
      this.loadEquipos();
      new WOW.WOW({
         live: false,
      }).init();
   }

   async loadAreas() {
      this.setState({
         loadingAreas: true,
      });
      const response = await req.get('config/get/areas', {});

      if (!response.error || !response.empty) {
         this.setState({
            areas: response.areas || [],
         });
      } else {
         this.setState({ areas: [] });
      }
      this.setState({
         loadingAreas: false,
      });
   }

   async loadEquipos() {
      this.setState({
         loadingEquipos: true,
      });
      const response = await req.get('config/get/equipos', {});

      if (!response.error || !response.empty) {
         this.setState({
            equipos: response.equipos || [],
         });
      } else {
         this.setState({ equipos: [] });
      }
      this.setState({
         loadingEquipos: false,
      });
   }

   async loadProblemas() {
      this.setState({
         loadingProblemas: true,
      });
      const response = await req.get('config/get/problemas', {});

      if (!response.error || !response.empty) {
         this.setState({
            problemas: response.problemas || [],
         });
      } else {
         this.setState({
            problemas: [],
         });
      }
      this.setState({
         loadingProblemas: false,
      });
   }

   async selectPage(page) {
      this.setState({ page: page });
   }

   async openModal() {
      let modal = document.getElementById('newModal');
      this.setState({ newValue: '' });
      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async activeAreas() {
      this.setState({
         titleNewModal: 'Nueva Área',
         saveMethod: this.saveAreaMethod.bind(this),
         showAreas: true,
         showEquipos: false,
         showProblemas: false,
      });
   }

   async activeEquipos() {
      this.setState({
         titleNewModal: 'Nuevo Equipo',
         saveMethod: this.saveEquipoMethod.bind(this),
         showAreas: false,
         showEquipos: true,
         showProblemas: false,
      });
   }

   async activeProblemas() {
      this.setState({
         titleNewModal: 'Nuevo Detalle',
         saveMethod: this.saveProblemaMethod.bind(this),
         showAreas: false,
         showEquipos: false,
         showProblemas: true,
      });
   }

   async saveAreaMethod(event) {
      event.preventDefault();
      if (this.state.newValue === '') {
         return;
      }
      this.setState({
         savingModal: true,
      });

      let data = {
         NombreArea: this.state.newValue,
      };
      let requestApi = await req.post('config/create/area', data);

      if (requestApi.response) {
         cogoToast.success('Área guardada con éxito.', {
            position: 'top-right',
         });
         this.closeModal();
         this.loadAreas();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async saveEquipoMethod(event) {
      event.preventDefault();
      if (this.state.newValue === '') {
         return;
      }
      this.setState({
         savingModal: true,
      });
      let data = {
         NombreEquipo: this.state.newValue,
      };
      let requestApi = await req.post('config/create/equipo', data);

      if (requestApi.response) {
         cogoToast.success('Equipo guardado con éxito.', {
            position: 'top-right',
         });
         this.closeModal();
         this.loadEquipos();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async saveProblemaMethod(event) {
      event.preventDefault();
      if (this.state.newValue === '') {
         return;
      }
      this.setState({
         savingModal: true,
      });
      let data = {
         NombreProblema: this.state.newValue,
      };
      let requestApi = await req.post('config/create/problema', data);

      if (requestApi.response) {
         cogoToast.success('Problema guardado con éxito.', {
            position: 'top-right',
         });
         this.closeModal();
         this.loadProblemas();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      this.setState({
         savingModal: false,
      });
   }

   async closeModal() {
      let modal = document.getElementById('newModal');
      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   async updateValue(value) {
      this.setState({
         newValue: value,
      });
   }

   render() {
      return (
         <div className="column justify-center align-center catalogo">
            <Helmet>
               <title>ELEVA Capital Group | Catálogos</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>

                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Configuración</h3>
                        <h3 className="color-secondary">&nbsp;/&nbsp;</h3>
                        <h4 className="color-darkgray">Catálogos</h4>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls
                              newHandler={this.openModal.bind(this)}
                              showFilter={true}
                           />
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center">
                           <div className="container">
                              <div className="column quart list-side wow fadeIn">
                                 <Link
                                    to="#"
                                    onClick={() => {
                                       this.activeAreas();
                                    }}
                                 >
                                    <div
                                       className={`row list-item ${
                                          this.state.showAreas ? 'active' : ''
                                       } `}
                                    >
                                       Áreas
                                    </div>
                                 </Link>
                                 <Link
                                    to="#"
                                    onClick={() => {
                                       this.activeEquipos();
                                    }}
                                 >
                                    <div
                                       className={`row list-item ${
                                          this.state.showEquipos ? 'active' : ''
                                       } `}
                                    >
                                       Equipos
                                    </div>
                                 </Link>
                                 <Link
                                    to="#"
                                    onClick={() => {
                                       this.activeProblemas();
                                    }}
                                 >
                                    <div
                                       className={`row list-item ${
                                          this.state.showProblemas
                                             ? 'active'
                                             : ''
                                       } `}
                                    >
                                       Detalles
                                    </div>
                                 </Link>
                              </div>
                              <div
                                 className="column card-table full align-center table-side wow fadeIn"
                                 data-wow-delay="0.3s"
                              >
                                 {this.state.showAreas ? (
                                    <TableAreas
                                       loadingAreas={this.state.loadingAreas}
                                       loadAreas={this.loadAreas.bind(this)}
                                       areas={this.state.areas}
                                    />
                                 ) : this.state.showEquipos ? (
                                    <TableEquipos
                                       loadingEquipos={
                                          this.state.loadingEquipos
                                       }
                                       loadEquipos={this.loadEquipos.bind(this)}
                                       equipos={this.state.equipos}
                                       problemas={this.state.problemas}
                                    />
                                 ) : this.state.showProblemas ? (
                                    <TableProblemas
                                       loadingProblemas={
                                          this.state.loadingProblemas
                                       }
                                       loadProblemas={this.loadProblemas.bind(
                                          this
                                       )}
                                       problemas={this.state.problemas}
                                    />
                                 ) : (
                                    ''
                                 )}
                                 <div className="row">
                                    <div className="white-space-8"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <DinamicModal
                  idModal={'newModal'}
                  sizeModalContainer={'micro'}
                  title={this.state.titleNewModal}
                  btnTextSuccess={'Guardar'}
                  success={this.state.saveMethod}
                  showBtnSuccess={true}
                  btnTextCancel={'Cancelar'}
                  showBtnCancel={true}
                  loadingSave={this.state.savingModal}
               >
                  <ModalCatalogo
                     value={this.state.newValue}
                     updateValue={this.updateValue.bind(this)}
                  />
               </DinamicModal>
            </div>
         </div>
      );
   }
}
export default withRouter(Consumer(Catalogo));

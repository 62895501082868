import React from 'react';
import Helmet from 'react-helmet';
import {withRouter, Link} from 'react-router-dom';
import WOW from 'wowjs';
import cogoToast from 'cogo-toast';

import {Consumer} from '../context';
import {catalogTabs, catalogDetails} from '../utils/controlCostsCatalog';

import ModalContainer from '../components/controlCostos/ModalContainer';
import Controls from '../components/Controls';
import Sidebar from '../components/Sidebar';
import CatalogTable from '../components/controlCostos/CatalogTable';
import DetailsSelector from '../components/controlCostos/components/DetailsSelector';
import {extractPathComponents} from '../utils/serializers';

class Catalogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            catalogData: {},
            showModal: false,
            newItemValue: {},
            items: [],
            searchInput: '',
            modalProps: {},
        };
        this.catalogTabs = catalogTabs;
        this.catalogDetails = catalogDetails;
    }

    setItems = (items) => {
        this.setState({items});
    };

    setModalProps = (modalProps) => {
        this.setState({modalProps});
    };

    async componentDidMount() {
        this.handleSelectCatalog('materials');
        new WOW.WOW({
            live: false,
        }).init();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.catalogData !== this.state.catalogData) {
            this.setState({
                items: await this.state.catalogData.getItems(),
            });
        }
    }

    async handleSelectCatalog(catalog) {
        const catalogData = this.catalogDetails[catalog];
        this.setState({
            catalogData: catalogData,
        });
    }

    async handleEditItem(newValue, attrToChange) {
        this.setState((prevState) => {
            const [objectKey, indexString, propertyKey] =
                extractPathComponents(attrToChange);

            // Case 1: Updating array element property
            if (indexString) {
                const index = parseInt(indexString); // Extract index

                return {
                    ...prevState,
                    newItemValue: {
                        ...prevState.newItemValue,
                        [objectKey]: prevState.newItemValue[objectKey].map((item, i) =>
                            i === index ? {...item, [propertyKey]: newValue} : item
                        ),
                    },
                };
            }

            // Case 2: Updating single object property
            return {
                ...prevState,
                newItemValue: {
                    ...prevState.newItemValue,
                    [attrToChange]: newValue,
                },
            };
        });
    }

    async handleShowModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    async handleCreateItem(e) {
        e.preventDefault();
        const {catalogData, newItemValue} = this.state;
        if (!catalogData.validateData(newItemValue)) {
            cogoToast.error('Por favor, ingrese los datos faltantes', {
                position: 'top-right',
            });
            return;
        }
        await catalogData.createItem(newItemValue);

        this.handleShowModal();
        this.setState({
            items: await catalogData.getItems(),
        });
        this.setState({
            newItemValue: {},
        });
    }

    // cambiar el estado para que se refresquen los datos del dato agregado con post

    render() {
        return (
            <div className="column justify-center align-center catalogo">
                <Helmet>
                    <title>ELEVA Capital Group | Catálogos</title>
                </Helmet>
                <div className="row full">
                    <Sidebar/>
                    <div className="column align-center full">
                        <div className="row">
                            <div className="white-space-48"></div>
                        </div>

                        <div className="row full justify-center wow fadeIn">
                            <div className="container justify-start align-center">
                                <h3 className="color-secondary">Control costos</h3>
                                <h3 className="color-secondary">&nbsp;/&nbsp;</h3>
                                <h4 className="color-darkgray">Catálogos</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="white-space-8"></div>
                        </div>
                        <div className="row full justify-center">
                            <div className="column full">
                                <div className="row">
                                    <div className="white-space-8"></div>
                                </div>
                                <div className="row justify-center align-center">
                                    <Controls
                                        newHandler={this.handleShowModal.bind(this)}
                                        showFilter={true}
                                    />
                                </div>
                                <div className="row">
                                    <div className="white-space-16"></div>
                                </div>
                                <div className="row full justify-center">
                                    <div className="container">
                                        <div className="column quart list-side wow fadeIn">
                                            {/* Pestañas con link */}
                                            {this.catalogTabs.map((catalogTab, index) => (
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        this.handleSelectCatalog(
                                                            catalogTab.catalogName
                                                        );
                                                    }}
                                                    key={catalogTab + index}>
                                                    <div className={`row list-item`}>
                                                        {catalogTab.label}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                        <div
                                            className="column card-table full align-center table-side wow fadeIn"
                                            data-wow-delay="0.3s">
                                            {/* Tablas */}
                                            <div className="mt-search input-clean">
                                                <input
                                                    type="text"
                                                    placeholder="Buscar"
                                                    className="input input-filter"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            searchInput: e.target.value,
                                                        });
                                                    }}
                                                />
                                                <i className="fas fa-search font-small"/>
                                            </div>
                                            <CatalogTable
                                                catalog={this.state.catalogData}
                                                items={this.state.items}
                                                setItems={this.setItems}
                                                modalProps={this.state.modalProps}
                                                setModalProps={this.setModalProps}
                                                search={this.state.searchInput}
                                            />
                                            <div className="row">
                                                <div className="white-space-8"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.showModal && (
                        <ModalContainer
                            size={'normal'}
                            title={`Añadir ${this.state.catalogData.itemName}`}
                            btnSuccessText="Guardar"
                            onCancel={this.handleShowModal.bind(this)}
                            onClose={this.handleShowModal.bind(this)}
                            onSuccess={this.handleCreateItem.bind(this)}>
                            <DetailsSelector
                                select={this.state.catalogData.detailsComponent}
                                item={this.state.newItemValue}
                                specificProperties={this.state.catalogData.specificProperties}
                                modalProps={this.state.modalProps}
                                onEditItem={this.handleEditItem.bind(this)}
                            />
                        </ModalContainer>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(Consumer(Catalogo));

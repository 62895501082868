import React from 'react';

export default function Tooltip({ id, icon, message }) {
   return (
      /* From Uiverse.io by vnuny */
      <div id={id} className="item-hints">
         <div className="hint" data-position="4">
            <span className="hint-radius"></span>
            <span className="hint-dot">
               {icon || (
                  <i className="fa fa-question-circle" style={{ color: '#18191a' }}></i>
               )}
            </span>
            <div className="hint-content do--split-children">
               <p>{message}</p>
            </div>
         </div>
      </div>
   );
}

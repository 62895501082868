/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ItemOptionsTable from '../../ItemOptionsTable';
import EditableOptionsTable from '../../EditableOptionsTable';

import { costsControlApi } from '../../../../../api/index';

export const ServiceOrderTasksDetails = (props) => {
   const { item, onEditItem, optionsLists, tasks, isLoadingTasks, isExtraPage } = props;

   const [staffFiltered, setStaffFiltered] = useState([]);
   const [tasksProviders, setTasksProviders] = useState([]);

   const staffTableColumns = [
      {
         label: 'Nombre',
         labelProperty: 'name',
      },
      {
         label: 'Horas previstas',
         labelProperty: 'scheduledHours',
         isEditable: true,
      },
      {
         label: 'Horas reales',
         labelProperty: 'realHours',
         isEditable: true,
      },
   ];

   const scrollableOptionsTask = {
      display: 'block',
      maxHeight: '300px',
      overflowY: 'auto',
   };

   const scrollableOptionsMaterials = {
      display: 'block',
      maxHeight: '100px',
      overflowY: 'auto',
   };

   const materialsTableColumns = [
      {
         label: 'Nombre',
         labelProperty: 'name',
      },
      {
         label: 'Cantidad prevista',
         labelProperty: 'expectedQuantity',
         isEditable: true,
      },
      {
         label: 'Cantidad real',
         labelProperty: 'realQuantity',
         isEditable: true,
      },
   ];

   const handleOnChangeItemOptions = (option, attributeToChange) => {
      onEditItem(option, attributeToChange);
   };

   const fetchProviders = async () => {
      const providers = await costsControlApi.getTasksByProvider(item?.provider?.id);
      setTasksProviders(() => providers);
   };

   const filterStaff = () => {
      const uniqueStaff = tasks.reduce((acc, task) => {
         if (!task?.allowed_staff) return acc;
         task.allowed_staff.forEach((staff) => {
            const { id, name } = staff;

            // no repeat staff
            if (!acc.some((existingStaff) => existingStaff.id === id)) {
               acc.push({ id, name });
            }
         });
         return acc;
      }, []);

      return uniqueStaff;
   };

   useEffect(() => {
      setStaffFiltered(() => filterStaff());
   }, [item]);

   useEffect(() => {
      if (item.provider?.id) fetchProviders();
   }, []);

   return (
      <React.Fragment>
         <div className={`${isExtraPage ? 'hidden' : ''}`}>
            <ItemOptionsTable
               label="Tarea"
               items={item.tasks}
               options={item?.provider?.id !== 15 ? tasksProviders : tasks}
               select={'Seleccione la tarea'}
               onValueUpdate={handleOnChangeItemOptions}
               attributeToChange="tasks"
               isLoading={isLoadingTasks}
               scrollableOptions={scrollableOptionsTask}
            />
            <div className="white-space-16" />
         </div>
         <div className={`${isExtraPage ? '' : 'hidden'}`}>
            <EditableOptionsTable
               items={item.staff}
               options={staffFiltered}
               columns={staffTableColumns}
               select="Seleccione el personal"
               onValueUpdate={onEditItem}
               attributeToChange="staff"
               scrollableOptions={scrollableOptionsMaterials}
            />
            <EditableOptionsTable
               items={item.materials}
               options={optionsLists.materials}
               columns={materialsTableColumns}
               select="Seleccione los materiales"
               onValueUpdate={onEditItem}
               attributeToChange="materials"
               scrollableOptions={scrollableOptionsMaterials}
            />
         </div>
      </React.Fragment>
   );
};

export default ServiceOrderTasksDetails;

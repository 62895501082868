import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import Paginator from '../../Paginator';

const HistorialEnvio = props => {
   const [logNotificaciones, setLogNotificaciones] = useState([]);
   const [loadingLog, setLoadingLog] = useState(false);
   const [page, setPage] = useState(1); // pagina

   useEffect(() => {
      setLogNotificaciones(props.logNotificaciones);
   }, [props.logNotificaciones]);

   useEffect(() => {
      setLoadingLog(props.loadingLog);
   }, [props.loadingLog]);

   async function selectPage(page) {
      setPage(page);
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-8"></div>
            <div className="row full align-center">
               <div className="column card-table full align-center card-table-modal">
                  <div className="table-responsive full">
                     <table>
                        <thead>
                           <tr>
                              <th className="text-left">Proyecto</th>
                              <th className="text-center">Fecha</th>
                              <th className="text-center">Unidad</th>
                              <th className="text-center">Persona</th>
                           </tr>
                        </thead>
                        <tbody>
                           {loadingLog ? (
                              <tr>
                                 <td className="text-center" colSpan="4">
                                    <i className="fas fa-spinner fa-spin"></i>
                                 </td>
                              </tr>
                           ) : (logNotificaciones
                                ? logNotificaciones.length
                                : 0) === 0 ? (
                              <tr>
                                 <td className="text-center" colSpan="4">
                                    Sin datos que mostrar
                                 </td>
                              </tr>
                           ) : (
                              logNotificaciones
                                 .slice((page - 1) * 8, page * 8)
                                 .map((log, key) => (
                                    <tr key={key}>
                                       <td className="text-left">
                                          {log.Proyecto}
                                       </td>
                                       <td className="text-center">
                                          {log.Fecha
                                             ? moment(log.Fecha).format(
                                                  'DD-MM-YYYY HH:mm'
                                               )
                                             : ''}
                                       </td>
                                       <td className="text-center">
                                          {log.Unidad}
                                       </td>
                                       <td className="text-center">
                                          {log.Persona}
                                       </td>
                                    </tr>
                                 ))
                           )}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="row full justify-center">
               <Paginator
                  pages={Math.ceil(logNotificaciones.length / 8)}
                  setPage={selectPage.bind(this)}
               />
            </div>
         </div>
      </div>
   );
};
export default HistorialEnvio;

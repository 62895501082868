import React, { useState, useEffect } from 'react';

const ModaPerfiles = props => {
   const [perfil, setPerfil] = useState({});
   const [menus, setMenus] = useState([]);

   useEffect(() => {
      setPerfil(props.perfil);
      setMenus(props.menus);
   }, [props.perfil, props.menus]);

   async function updatePerfil(prf) {
      await props.updateState({
         perfil: prf,
      });
      await setPerfil(prf);
   }

   async function permisoLectura(IdMenu, IdParent) {
      if (perfil.Permisos) {
         if (perfil.Permisos.lectura.includes(IdMenu)) {
            let permisos = perfil.Permisos.lectura.filter(
               prm => prm !== IdMenu
            );
            perfil.Permisos.lectura = permisos;

            let permisosEscritura = perfil.Permisos.escritura.filter(
               prm => prm !== IdMenu
            );
            perfil.Permisos.escritura = permisosEscritura;

            if (IdParent) {
               let menu = menus.find(menu => menu.IdMenu === IdParent);

               let subMenus = menu.Submenu.map(a => a.IdMenu);

               const menuIntersections = permisos.filter(value =>
                  subMenus.includes(value)
               );

               if (menuIntersections.length === 0) {
                  let permisos = perfil.Permisos.lectura.filter(
                     prm => prm !== IdParent
                  );
                  perfil.Permisos.lectura = permisos;

                  let permisosEscritura = perfil.Permisos.escritura.filter(
                     prm => prm !== IdParent
                  );
                  perfil.Permisos.escritura = permisosEscritura;
               }
            }

            await updatePerfil(perfil);
         } else {
            perfil.Permisos.lectura.push(IdMenu);

            if (IdParent) {
               let permisos = perfil.Permisos.lectura.filter(
                  prm => prm !== IdMenu
               );

               let menu = menus.find(menu => menu.IdMenu === IdParent);

               let subMenus = menu.Submenu.map(a => a.IdMenu);

               const menuIntersections = permisos.filter(value =>
                  subMenus.includes(value)
               );

               if (menuIntersections.length === 0) {
                  perfil.Permisos.lectura.push(IdParent);
               }
            }

            await updatePerfil(perfil);
         }
      } else {
         let lectura = [];

         lectura.push(IdMenu);

         if (IdParent) {
            lectura.push(IdParent);
         }

         let newPermiso = {
            ...perfil,
            Permisos: {
               lectura: lectura,
               escritura: [],
            },
         };

         await updatePerfil(newPermiso);
      }
   }

   async function permisoEscritura(IdMenu, IdParent) {
      if (perfil.Permisos) {
         if (perfil.Permisos.escritura.includes(IdMenu)) {
            let permisos = perfil.Permisos.escritura.filter(
               prm => prm !== IdMenu
            );
            perfil.Permisos.escritura = permisos;

            if (IdParent) {
               let menu = menus.find(menu => menu.IdMenu === IdParent);

               let subMenus = menu.Submenu.map(a => a.IdMenu);

               const menuIntersections = permisos.filter(value =>
                  subMenus.includes(value)
               );

               if (menuIntersections.length === 0) {
                  let permisos = perfil.Permisos.escritura.filter(
                     prm => prm !== IdParent
                  );
                  perfil.Permisos.escritura = permisos;
               }
            }

            await updatePerfil(perfil);
         } else {
            if (!perfil.Permisos.lectura.includes(IdMenu)) {
               perfil.Permisos.lectura.push(IdMenu);
            }

            perfil.Permisos.escritura.push(IdMenu);

            if (IdParent) {
               let permisos = perfil.Permisos.escritura.filter(
                  prm => prm !== IdMenu
               );

               let menu = menus.find(menu => menu.IdMenu === IdParent);

               let subMenus = menu.Submenu.map(a => a.IdMenu);

               const menuIntersections = permisos.filter(value =>
                  subMenus.includes(value)
               );

               if (menuIntersections.length === 0) {
                  perfil.Permisos.escritura.push(IdParent);
                  perfil.Permisos.lectura.push(IdParent);
               }
            }

            await updatePerfil(perfil);
         }
      } else {
         let escritura = [];
         let lectura = [];

         escritura.push(IdMenu);
         lectura.push(IdMenu);

         if (IdParent) {
            escritura.push(IdParent);
            lectura.push(IdParent);
         }

         let newPermiso = {
            ...perfil,
            Permisos: {
               lectura: lectura,
               escritura: escritura,
            },
         };

         await updatePerfil(newPermiso);
      }
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-16" />
            <div className="row full align-center">
               <div className="column label-medium">
                  <p>
                     <b>Nombre de Perfil:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={perfil.NombrePerfil ? perfil.NombrePerfil : ''}
                     onChange={event => {
                        let prf = perfil;
                        prf.NombrePerfil = event.target.value;
                        updatePerfil(prf);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-16" />
            <div className="row full align-center">
               <div className="column card-table full align-center card-table-modal">
                  <div className="table-responsive full">
                     <table>
                        <thead>
                           <tr>
                              <th className="text-left">Permisos</th>
                              <th className="text-center th-lg">Lectura</th>
                              <th className="text-center th-lg">Escritura</th>
                           </tr>
                        </thead>
                        <tbody>
                           {(menus ? menus.length : 0) === 0 ? (
                              <tr>
                                 <td className="text-center" colSpan="3">
                                    Sin datos que mostrar
                                 </td>
                              </tr>
                           ) : (
                              menus.map((menu, key) => {
                                 return (
                                    <React.Fragment key={key}>
                                       <tr>
                                          <td className="text-left weight-bold">
                                             {menu.Menu}
                                          </td>
                                          <td className="text-center">
                                             <label className="container-check">
                                                <input
                                                   type="checkbox"
                                                   checked={
                                                      perfil.Permisos
                                                         ? perfil.Permisos.lectura.includes(
                                                              menu.IdMenu
                                                           )
                                                         : false
                                                   }
                                                   disabled={
                                                      menu.Submenu.length === 0
                                                         ? false
                                                         : true
                                                   }
                                                   onClick={() =>
                                                      permisoLectura(
                                                         menu.IdMenu,
                                                         false
                                                      )
                                                   }
                                                />
                                                <span className="checkmark"></span>
                                             </label>
                                          </td>
                                          <td className="text-center">
                                             <label className="container-check">
                                                <input
                                                   type="checkbox"
                                                   checked={
                                                      perfil.Permisos
                                                         ? perfil.Permisos.escritura.includes(
                                                              menu.IdMenu
                                                           )
                                                         : false
                                                   }
                                                   disabled={
                                                      menu.Submenu.length === 0
                                                         ? false
                                                         : true
                                                   }
                                                   onClick={() =>
                                                      permisoEscritura(
                                                         menu.IdMenu,
                                                         false
                                                      )
                                                   }
                                                />
                                                <span className="checkmark"></span>
                                             </label>
                                          </td>
                                       </tr>
                                       {menu.Submenu.map((sub, key) => (
                                          <tr key={key}>
                                             <td className="text-left">
                                                {sub.Menu}
                                             </td>
                                             <td className="text-center">
                                                <label className="container-check">
                                                   <input
                                                      type="checkbox"
                                                      checked={
                                                         perfil.Permisos
                                                            ? perfil.Permisos.lectura.includes(
                                                                 sub.IdMenu
                                                              )
                                                            : false
                                                      }
                                                      onClick={() =>
                                                         permisoLectura(
                                                            sub.IdMenu,
                                                            sub.Parent
                                                         )
                                                      }
                                                   />
                                                   <span className="checkmark"></span>
                                                </label>
                                             </td>
                                             <td className="text-center">
                                                <label className="container-check">
                                                   <input
                                                      type="checkbox"
                                                      checked={
                                                         perfil.Permisos
                                                            ? perfil.Permisos.escritura.includes(
                                                                 sub.IdMenu
                                                              )
                                                            : false
                                                      }
                                                      onClick={() =>
                                                         permisoEscritura(
                                                            sub.IdMenu,
                                                            sub.Parent
                                                         )
                                                      }
                                                   />
                                                   <span className="checkmark"></span>
                                                </label>
                                             </td>
                                          </tr>
                                       ))}
                                    </React.Fragment>
                                 );
                              })
                           )}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <div className="white-space-8" />
         </div>
      </div>
   );
};

export default ModaPerfiles;

import React from 'react';
import { formatMoney } from '../../../utils/formatMoney';

const GraphCard = (props) => {
   const { data, onDrawGraphClick, isLoading } = props;
   const isMoney = data.Nombre.includes('$');
   return (
      <div className="graph-card">
         <div className="card-header justify-between align-center">
            <h4>{data.Nombre.replace('$', '') || ''}</h4>
         </div>
         <div className="card-content">
            <div className="icon-text align-center column">
               <div className="icon-container">
                  {isLoading ? (
                     <div className="justify-center">
                        <i className="fas fa-spinner fa-spin"></i>
                     </div>
                  ) : (
                     <i
                        style={{ cursor: data.Cantidad ? 'pointer' : '' }}
                        className="fas fa-flag"
                        onClick={() => onDrawGraphClick(data)}
                     />
                  )}
               </div>
               <div className="text-container">
                  <p>{isMoney ? formatMoney(data.Cantidad) : data.Cantidad || 0}</p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default GraphCard;

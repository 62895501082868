import React from 'react';

const ItemCheckbox = (props) => {
   const { label, checked, onValueUpdate, attributeToChange } = props;

   const handleCheckboxChange = (e) => {
      const statusId = e.target.checked ? 1 : 3;
      onValueUpdate(statusId, attributeToChange);
   };

   return (
      <div className="row full align-center">
         <div className="column label-normal">
            <p>
               <b>{`${label}: `}</b>
            </p>
         </div>

         <div className="column column-small">
            <label className="container-check">
               <input
                  type="checkbox"
                  checked={checked}
                  onClick={handleCheckboxChange}
                  readOnly
               />

               <span className="checkmark"></span>
            </label>
         </div>
      </div>
   );
};

export default ItemCheckbox;

import React from 'react';

import ItemInput from '../ItemInput';
import ItemCheckbox from '../ItemCheckbox';

const MaterialCategoriesDetails = props => {
   const { item, onEditItem } = props;
   const isItemActive = item.id_status === 1;

   return (
      <React.Fragment>
         <ItemInput
            value={item.name}
            label="Nombre"
            onValueUpdate={onEditItem}
            attributeToChange="name"
         />
         <div className="white-space-16" />
         <ItemCheckbox
            label="Activo"
            checked={isItemActive}
            onValueUpdate={onEditItem}
            attributeToChange="id_status"
         />
      </React.Fragment>
   );
};

export default MaterialCategoriesDetails;

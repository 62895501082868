import React, {useState} from 'react';
import ItemOptions from './ItemOptions';

const ItemOptionsTable = (props) => {
    const {
        items = [],
        options = [],
        visibleTHeads = ['Detalles'],
        visibleKeys = ['name'],
        types = {name: 'text'},
        type = 'select',
        select,
        onValueUpdate,
        attributeToChange,
        isLoadingOptions,
        isRemovingItem = false,
        scrollableOptions = {},
    } = props;

    const [selectedOption, setSelectedOption] = useState(select);
    const [textItem, setTextItem] = useState('');

    const handleSelectValue = (newValue) => {
        const selected = options.find((item) => item.id === parseInt(newValue));
        if (!selected) return;
        setSelectedOption(selected);
    };

    const handleAddItem = (selectedItemId) => {
        if (type === 'txt') {
            const data = items
            console.log(items)
            data.push({id: 0, name: textItem})
            console.log(data)
            onValueUpdate(
                data,
                attributeToChange
            );
            setTextItem('');
            return;
        }
        const selectedItem = options.find((item) => item.id === parseInt(selectedItemId));
        if (!selectedItem) return;
        onValueUpdate([...(items || []), selectedItem], attributeToChange);
        setSelectedOption(select);
    };

    const handleDeleteItem = (selectedItemId) => {
        const filteredItems = items.filter((item) => item.id !== selectedItemId);
        onValueUpdate(filteredItems, attributeToChange);
    };

    const handleOnchangeTxtItem = (e, item) => {
        const newItems = items.map((itemNew) => {
            if (item.id === itemNew.id) {
                return {
                    ...itemNew,
                    name: e.target.value,
                };
            }
            return itemNew;
        });
        onValueUpdate(newItems, attributeToChange);
    };

    return (
        <div>
            <div className="row full align-center">
                <div className="row full align-center">
                    <div className="column full">
                        {isLoadingOptions ? (
                            <div className="justify-center">
                                <i className="fas fa-spinner fa-spin"></i>
                            </div>
                        ) : type === 'select' ? (
                            <select
                                className="input input-modals"
                                value="0"
                                onChange={(event) => handleSelectValue(event.target.value)}>
                                <option value={0} disabled>
                                    {selectedOption?.name ? selectedOption.name : selectedOption}
                                </option>

                                {options?.map((item, key) => {
                                    if (items?.some((selected) => selected.id === item.id)) {
                                        return null;
                                    } else {
                                        return (
                                            <option key={key} value={item.id}>
                                                {item.name}
                                            </option>
                                        );
                                    }
                                })}
                            </select>
                        ) : (
                            <input
                                type="text"
                                className="input input-modals"
                                value={textItem}
                                onChange={(event) => setTextItem(event.target.value)}
                            />
                        )}
                    </div>
                    &nbsp; &nbsp;
                    <button
                        type="button"
                        className="color-black"
                        onClick={() => handleAddItem(selectedOption?.id)}
                        disabled={selectedOption === select && type === 'select'}>
                        <i className="fas fa-plus-circle"></i>
                    </button>
                </div>
            </div>
            <div className="white-space-16"></div>
            <div className="row full align-center">
                <div className="column card-table full align-center">
                    <div className="table-responsive full">
                        <table>
                            <thead>
                            <tr>
                                {visibleTHeads.map((head, index) => {
                                    return <th key={index}>{head}</th>;
                                })}

                                <th className="text-center th-lg">Eliminar</th>
                            </tr>
                            </thead>
                            <tbody style={scrollableOptions}>
                            {items?.length === 0 || !items ? (
                                <tr>
                                    <td className="text-center" colSpan="3">
                                        Sin datos que mostrar
                                    </td>
                                </tr>
                            ) : (
                                items?.map((item, index) => {
                                    return (
                                        <tr key={item.id + index}>
                                            {Object.entries(item).map(([key, value]) => {
                                                if (visibleKeys.includes(key)) {
                                                    return (
                                                        <td key={key}>
                                                            {type === 'select' ? (
                                                                value
                                                            ) : types[key] === 'text' ? (
                                                                <input
                                                                    type="txt"
                                                                    value={value}
                                                                    style={{width: '100%'}}
                                                                    onChange={(e) => {
                                                                        handleOnchangeTxtItem(e, item);
                                                                    }}
                                                                />
                                                            ) : types[key] === 'select' ? (
                                                                <ItemOptions
                                                                    options={options}
                                                                    select={value}
                                                                    onChange={onValueUpdate}
                                                                    attributeToChange={`${attributeToChange}[${index}].category`} //pendiente mejorar
                                                                    isLoading={isLoadingOptions}
                                                                />
                                                            ) : null}
                                                        </td>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <td className="text-center">
                                                {isRemovingItem === item.IdEquipoTipoProblema ? (
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                ) : (
                                                    <i
                                                        className="fas fa-times-circle color-red cursor-pointer space-4"
                                                        onClick={() =>
                                                            handleDeleteItem(item.id)
                                                        }></i>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemOptionsTable;

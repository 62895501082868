import React, { useEffect, useState } from 'react';

const TableNotificaciones = props => {
   const [notificaciones, setNotificaciones] = useState([]);
   const [loadingNotificaciones, setLoadingNotificaciones] = useState(false);

   useEffect(() => {
      setNotificaciones(props.notificaciones);
      setLoadingNotificaciones(props.loadingNotificaciones);
   }, [props.notificaciones, props.loadingNotificaciones]);

   return (
      <div className="table-responsive full">
         <table>
            <thead>
               <tr>
                  <th className="text-center">Mensaje</th>
                  <th className="text-center th-md">Enviar</th>
                  <th className="text-center th-md">Historial</th>
               </tr>
            </thead>
            <tbody>
               {loadingNotificaciones ? (
                  <tr>
                     <td className="text-center" colSpan="4">
                        <i className="fas fa-spinner fa-spin"></i>
                     </td>
                  </tr>
               ) : notificaciones ? (
                  notificaciones.length === 0 ? (
                     <tr>
                        <td className="text-center" colSpan="4">
                           Sin datos que mostrar
                        </td>
                     </tr>
                  ) : (
                     notificaciones
                        .slice((props.page - 1) * 15, props.page * 15)
                        .map((notificacion, key) => (
                           <tr key={key}>
                              <td className="">{notificacion.Mensaje}</td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.sendNotificacion(notificacion);
                                    }}
                                 >
                                    <i className="font-small fas fa-paper-plane" />
                                 </button>
                              </td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.setNotificacionHistory(
                                          notificacion
                                       );
                                    }}
                                 >
                                    <i className="fas fa-clipboard-list"></i>
                                 </button>
                              </td>
                           </tr>
                        ))
                  )
               ) : null}
            </tbody>
         </table>
      </div>
   );
};
export default TableNotificaciones;

import React, { useEffect } from 'react';

const DetailsSelector = (props) => {
   const { select, item, specificProperties, onEditItem, ...rest } = props;

   const Details = select;
   useEffect(() => {
      return () => {};
   }, []);

   return (
      <Details
         item={item}
         onEditItem={onEditItem}
         extraProps={specificProperties}
         {...rest}
      />
   );
};

export default DetailsSelector;

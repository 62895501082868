import Request from '../core/httpClient';
const req = new Request();

export const getKpi = async (from, to, projectId = 0) => {
   const response = await req.get(
      `costos/kpi/${projectId}?from=${from}&to=${to}`
   );
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const getKpiDetails = async (kpi, from, to, projectId = 0) => {
   const response = await req.get(
      `costos/kpi/${projectId}/${kpi}?from=${from}&to=${to}`
   );
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

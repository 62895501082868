/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

const ModalDocumento = props => {
   const [documento, setDocumento] = useState({});

   useEffect(() => {
      setDocumento(props.documento);
      if (Object.keys(props.documento).length === 0) {
         resetFileUpload();
      }
   }, [props.documento]);

   useEffect(() => {
      uploadFile();
   }, []);

   function updateDocumento(doc) {
      props.updateJson({
         documento: doc,
      });
   }

   async function uploadFile() {
      const fileSelect = document.getElementById('file-upload');
      const fileDrag = document.getElementById('file-drag');

      function Init() {
         fileSelect.addEventListener('change', fileSelectHandler, false);

         // Is XHR2 available?
         var xhr = new XMLHttpRequest();
         if (xhr.upload) {
            // File Drop
            fileDrag.addEventListener('dragover', fileDragHover, false);
            fileDrag.addEventListener('dragleave', fileDragHover, false);
            fileDrag.addEventListener('drop', fileSelectHandler, false);
         }
      }

      function fileDragHover(e) {
         var fileDrag = document.getElementById('file-drag');

         e.stopPropagation();
         e.preventDefault();

         fileDrag.className =
            e.type === 'dragover' ? 'hover' : 'modal-body file-upload';
      }

      function fileSelectHandler(e) {
         // Fetch FileList object
         var files = e.target.files || e.dataTransfer.files;
         updateDocumento({
            ...documento,
            Archivo: files[0],
            NombreDocumento: files[0].name.substring(
               0,
               files[0].name.lastIndexOf('.')
            ),
         });
         // Cancel event and hover styling
         fileDragHover(e);

         // Process all File objects
         for (var i = 0, f; (f = files[i]); i++) {
            parseFile(f);
            var pBar = document.getElementById('file-progress');
            if (pBar) {
               const setProgress = setInterval(() => {
                  if (pBar.value < pBar.max) {
                     pBar.value = pBar.value + 1;
                  } else {
                     clearInterval(setProgress);
                  }
               }, 100);
            }
         }

         resetFileUpload();
      }

      // Output
      function output(msg) {
         // Response
         var m = document.getElementById('messages');
         m.innerHTML = msg;
      }

      function parseFile(file) {
         output('<strong>' + encodeURI(file.name) + '</strong>');

         var imageName = file.name;

         var isGood = /\.(?=pdf)/gi.test(imageName);
         if (isGood) {
            document.getElementById('start').classList.add('hidden');
            document.getElementById('response').classList.remove('hidden');
            document.getElementById('notimage').classList.add('hidden');
         } else {
            document.getElementById('notimage').classList.remove('hidden');
            document.getElementById('start').classList.remove('hidden');
            document.getElementById('response').classList.add('hidden');
            document.getElementById('file-upload-form').reset();
         }
      }

      function resetFileUpload() {
         document.getElementById('file-upload-form').reset();
         document.getElementById('start').classList.remove('hidden');
         document.getElementById('response').classList.add('hidden');
      }

      // Check for the various File API support.
      if (window.File && window.FileList && window.FileReader) {
         Init();
      } else {
         document.getElementById('file-drag').style.display = 'none';
      }
   }

   function resetFileUpload() {
      document.getElementById('file-upload-form').reset();
      document.getElementById('start').classList.remove('hidden');
      document.getElementById('response').classList.add('hidden');
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="column full justify-center align-center">
               <div className="column upload full">
                  <div className="white-space-16" />
                  <div className="row full align-center">
                     <div className="column label-normal">
                        <p>
                           <b>Nombre:</b>
                        </p>
                     </div>
                     <div className="column full">
                        <input
                           className="input"
                           disabled
                           value={
                              documento.NombreDocumento
                                 ? documento.NombreDocumento
                                 : ''
                           }
                        ></input>
                     </div>
                  </div>
                  <div className="white-space-8"></div>
                  <form id="file-upload-form" className="uploader">
                     <input
                        id="file-upload"
                        type="file"
                        name="fileUpload"
                        accept="application/pdf"
                     />
                     <label htmlFor="file-upload" id="file-drag">
                        <div id="start">
                           <i className="fa fa-download" aria-hidden="true"></i>
                           <div>Selecciona o arrastra un archivo (PDF)</div>
                           <div id="notimage" className="hidden">
                              Selecciona una imagen válida
                           </div>
                        </div>
                        <div id="response" className="hidden">
                           <div id="messages"></div>
                           <progress
                              className="progress"
                              id="file-progress"
                              value="0"
                           >
                              <span>0</span>%
                           </progress>
                        </div>
                     </label>
                  </form>
               </div>
            </div>
         </div>
      </div>
   );
};
export default ModalDocumento;

import React, { Component } from 'react';
import Views from './core/views';
import moment from 'moment-timezone';

moment.tz.setDefault('UTC');
class App extends Component {
   render() {
      return (
         <div className="App">
            <Views> </Views>
         </div>
      );
   }
}

export default App;

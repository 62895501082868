import React, { useState, useEffect } from 'react';
import ImageLoader from 'react-loading-image';
import ReactPlayer from 'react-player';

import Loading from '../../images/load.gif';
import NoAvalible from '../../images/no-image-available.png';

const ModalZoom = (props) => {
   const [imgZoom, setImgZoom] = useState('');
   const [video, setVideo] = useState('');

   useEffect(() => {
      if (!props.imgZoom) {
         return;
      }
      const image = new Image();
      image.src = props.imgZoom;
      image.onload = () => {
         const resizedImage = new Image();
         resizedImage.src = image.src;
         setImgZoom(resizedImage);
      };
   }, [props.imgZoom]);

   useEffect(() => {
      setVideo(props.video);
   }, [props.video]);

   function close(event) {
      event.preventDefault();

      props.closeModal('');

      const modal = document.getElementById('image-zoom-modal');
      modal.classList.remove('show-zoom-modal');
      modal.classList.add('hide-zoom-modal');
   }

   return (
      <div
         className="image-zoom-modal align-center justify-center hide-zoom-modal"
         id="image-zoom-modal">
         <div className="modal-content-zoom column align-center">
            <div
               className="column full"
               style={{ position: 'absolute', top: 0, right: '23px' }}>
               <div className="row full justify-end">
                  <button id="modal-close-zoom" onClick={close}>
                     &times;
                  </button>
               </div>
            </div>
            <div className="container justify-center align-center">
               {video ? (
                  <ReactPlayer url={video} controls />
               ) : (
                  <ImageLoader
                     id="img-zoom"
                     className="img-zoom"
                     src={imgZoom.src}
                     image={() => (
                        <img
                           src={imgZoom.src}
                           width={imgZoom.width}
                           height={imgZoom.height}
                           alt="Imagen"
                        />
                     )}
                     loading={() => <img src={Loading} alt="Cargando..." />}
                     error={() => <img src={NoAvalible} alt="Cargando..." />}
                  />
               )}
            </div>
         </div>
      </div>
   );
};

export default ModalZoom;

import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { Consumer } from '../context';
import Menu from '../components/Menu';
import Sidebar from '../components/Sidebar';

import Logo from '../images/triangulo.svg';

class Home extends React.Component {
   constructor(props) {
      super(props);
      this.setState = {};
   }

   render() {
      return (
         <div className="column justify-center align-center">
            <Helmet>
               <title>ELEVA Capital Group | Error</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center justify-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>
                  <div className="row full justify-center align-center">
                     <div className="column full justify-center align-center">
                        <div className="row full justify-center align-center">
                           <img className="error-img" src={Logo} alt="Error" />
                        </div>
                        <div className="row">
                           <div className="white-space-64"></div>
                        </div>
                        <div className="row full justify-center align-center">
                           <h1 className="color-secondary font-triple">
                              Página no encontrada
                           </h1>
                        </div>

                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                     </div>
                  </div>
               </div>

               <Menu />
            </div>
         </div>
      );
   }
}
export default withRouter(Consumer(Home));

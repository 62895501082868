// A diferencia de ItemOptionsTable, este componente recibe un array de las celdas editables.

// Las funciones y la estructura del componente se modificaron ligeramente pare permitir esta funcionalidad.

// El componente no tiene props para saber si las opciones han cargado o no, pues estas forman parte de
// una propiedad que se obtiene de un objeto, la carga de dicho objeto se maneja desde su componente de details
// (esto puede cambiar mas tarde).

import React, { useState } from 'react';

const EditableOptionsTable = props => {
   const {
      items = [],
      options = [],
      columns = [],
      select,
      onValueUpdate,
      attributeToChange,
      isRemovingItem = false,
      scrollableOptions,
   } = props;

   const [selectedOption, setSelectedOption] = useState(select);

   const findSelectedItem = value => {
      const selected = options.find(item => item.id === parseInt(value));
      return selected;
   };

   const handleSelectOption = option => {
      const selected = findSelectedItem(option);
      setSelectedOption(selected);
   };

   const handleEditCell = (cell, currentItem, newValue, attributeToChange) => {
      const updatedItem = items.map(item => {
         if (item === currentItem) {
            return { ...item, [cell]: newValue };
         } else {
            return item;
         }
      });
      onValueUpdate(updatedItem, attributeToChange);
   };

   const handleAddItem = selectedItemId => {
      const selectedItem = findSelectedItem(selectedItemId);
      if (!selectedItem) return;

      const defaultCellValues = columns.map(column => {
         if (!column.isEditable) {
            return null;
         }
         return {
            [column.labelProperty]: 0,
         };
      });
      const combinedDefaultValues = defaultCellValues.reduce((acc, value) => {
         if (value) {
            return { ...acc, ...value };
         }
         return acc;
      }, {});

      const newSelectedItem = { ...selectedItem, ...combinedDefaultValues };
      onValueUpdate(
         items ? [...items, newSelectedItem] : [newSelectedItem],
         attributeToChange
      );
      setSelectedOption(select);
   };

   const handleDeleteItem = (selectedItemId) => {
      const filteredItems = items.filter((item) => item.id !== selectedItemId);
      onValueUpdate(filteredItems, attributeToChange);
   };

   return (
      <React.Fragment>
         <div className="flex-container gap-15">
            <div className="row full align-center">
               <div className="row full align-center">
                  <div className="column full">
                     <select
                        className="input input-modals"
                        value="0"
                        onChange={event =>
                           handleSelectOption(event.target.value)
                        }
                     >
                        <option value={0} disabled>
                           {selectedOption?.name
                              ? selectedOption.name
                              : selectedOption}
                        </option>
                        {options.map((option, key) => {
                           if (
                              items?.some(selected => selected.id === option.id)
                           ) {
                              return null;
                           } else {
                              return (
                                 <option key={key} value={option.id}>
                                    {option.name}
                                 </option>
                              );
                           }
                        })}
                     </select>
                  </div>
                  &nbsp; &nbsp;
                  <button
                     type="button"
                     className="color-black"
                     onClick={() => handleAddItem(selectedOption.id)}
                     disabled={selectedOption === select}
                  >
                     <i className="fas fa-plus-circle" />
                  </button>
               </div>
            </div>
         </div>

         <div className="white-space-16"></div>
         <div className="row full align-center">
            <div className="column card-table full align-center">
               <div className="table-responsive full">
                  <table className="editable-table">
                     <thead>
                        <tr>
                           {columns.map((column, index) => {
                              return <th key={index}>{column.label}</th>;
                           })}
                           <th className="text-center th-lg">Eliminar</th>
                        </tr>
                     </thead>
                     <tbody style={scrollableOptions}>
                        {items?.length === 0 || !items ? (
                           <tr>
                              <td className="text-center" colSpan="3">
                                 Sin datos que mostrar
                              </td>
                           </tr>
                        ) : (
                           items.map((item, index) => {
                              return (
                                 <tr key={`${item.id}-${index}`}>
                                    {columns.map(column => (
                                       <td
                                          key={column.label}
                                          className={
                                             column.isEditable
                                                ? 'custom-td'
                                                : ''
                                          }
                                       >
                                          {column.isEditable ? (
                                             <input
                                                className="custom-input"
                                                type="number"
                                                value={
                                                   item[column.labelProperty] ||
                                                   0
                                                }
                                                onChange={e => {
                                                   if (
                                                      e.target.value.length ===
                                                      2
                                                   ) {
                                                      e.target.value =
                                                         e.target.value.replace(
                                                            /^0+/,
                                                            ''
                                                         );
                                                   }
                                                   handleEditCell(
                                                      column.labelProperty,
                                                      item,
                                                      parseFloat(
                                                         e.target.value
                                                      ),
                                                      attributeToChange
                                                   );
                                                }}
                                             />
                                          ) : (
                                             item[column.labelProperty] || ''
                                          )}
                                       </td>
                                    ))}
                                    <td className="text-center">
                                       {isRemovingItem ===
                                       item.IdEquipoTipoProblema ? (
                                          <i className="fas fa-spinner fa-spin"></i>
                                       ) : (
                                          <i
                                             className="fas fa-times-circle color-red cursor-pointer space-4"
                                             onClick={() =>
                                                handleDeleteItem(item.id)
                                             }
                                          ></i>
                                       )}
                                    </td>
                                 </tr>
                              );
                           })
                        )}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
};

export default EditableOptionsTable;

import React, {useEffect, useState} from 'react';

const ItemOptions = (props) => {
    const {
        label,
        options = [],
        select,
        onSelect = null,
        onChange,
        attributeToChange,
        isLoading,
        disabled = false,
    } = props;

    const [selectedOption, setSelectedOption] = useState(select);

    const selectDefaultValue = (selectedOption) => {
        options.forEach((option) => {
            if (option.name === selectedOption || option.id === selectedOption) {
                setSelectedOption(option.name);
                onChange(option.id, attributeToChange);
            }
        });
    };

    const handleOnChange = (newValue) => {
        const selected = options.find((item) => item.id === parseInt(newValue));
        if (onSelect) onSelect(selected);
        setSelectedOption(selected);
        const {id, name} = selected
        onChange({id, name}, attributeToChange);
    };

    useEffect(() => {
        selectDefaultValue(select);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="full row align-center">
                <div className="column full">
                    <div className="white-space-16"></div>
                    <div className="full row align-center">
                        {label ? (
                            <div className="column label-normal">
                                <p>
                                    <b>{`${label}:`}</b>
                                </p>
                            </div>
                        ) : null}
                        <div className="column full">
                            {isLoading ? (
                                <div className="justify-center">
                                    <i className="fas fa-spinner fa-spin"></i>
                                </div>
                            ) : (
                                <select
                                    className="input input-modals"
                                    value={selectedOption?.id || 0}
                                    disabled={disabled}
                                    onChange={(event) => handleOnChange(event.target.value)}>
                                    <option value={0} disabled>
                                        {selectedOption?.name || 'Seleccionar'}
                                    </option>
                                    {/* En caso de que la opcion haya sido eliminada, options ya no lo incluye pero debe seguir mostrándose*/}
                                    {options &&
                                        !options.some(
                                            (option) => option.id === selectedOption?.id
                                        ) &&
                                        selectedOption?.id && (
                                            <option value={selectedOption?.id}>
                                                {`${selectedOption?.name} (${label} eliminada o deshabilitada)`}
                                            </option>
                                        )}
                                    {options?.map((item, key) => (
                                        <option key={`${item.id}-${key}`} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ItemOptions;

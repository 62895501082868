import React, { useEffect, useState } from 'react';

const TableProyectos = props => {
   const [proyectos, setProyectos] = useState([]);

   useEffect(() => {
      if (props.proyectos) {
         setProyectos(props.proyectos);
      }
   }, [props]);

   return (
      <div id="tableProyectos" className="table-responsive full wow  fadeIn">
         <table>
            <thead>
               <tr>
                  <th className="text-left">Nombre</th>
                  <th className="text-left">Ciudad</th>
                  <th className="text-center th-lg">Tipo Unidad</th>
                  <th className="text-center th-lg">Unidades</th>
                  <th className="text-center th-sm"> </th>
                  <th className="text-center th-sm"> </th>
               </tr>
            </thead>
            <tbody>
               {props.loadingProyectos ? (
                  <tr>
                     <td className="text-center" colSpan="6">
                        <i className="fas fa-spinner fa-spin"></i>
                     </td>
                  </tr>
               ) : proyectos.length === 0 ? (
                  <tr>
                     <td className="text-center" colSpan="6">
                        Sin datos que mostrar
                     </td>
                  </tr>
               ) : (
                  proyectos
                     .slice((props.page - 1) * 15, props.page * 15)
                     .map((pry, key) => (
                        <tr key={key}>
                           <td className="text-left">{pry.Nombre}</td>
                           <td className="text-left">{pry.Ciudad}</td>
                           <td className="text-center th-lg justify-center">
                              <button
                                 type="button"
                                 className=" btn-small btn-empty"
                                 onClick={() => {
                                    props.setProyectos(pry);
                                 }}
                              >
                                 <i className="fas fa-tasks"></i>
                              </button>
                           </td>
                           <td className="text-center">
                              <button
                                 type="button"
                                 className=" btn-small btn-empty"
                                 onClick={() => {
                                    props.setProyectosUnidades(pry);
                                 }}
                              >
                                 <i className="fas fa-home"></i>
                              </button>
                           </td>
                           <td className="text-center th-sm">
                              <button
                                 className=" btn-small btn-empty"
                                 type="button"
                                 onClick={() => {
                                    props.setToUpdateProyect(pry);
                                 }}
                              >
                                 <i className="fas fa-edit"></i>
                              </button>
                           </td>
                           <td className="text-center th-sm">
                              <button
                                 className=" btn-small btn-empty"
                                 type="button"
                                 onClick={() => {
                                    props.setProyectoDelete(pry);
                                 }}
                              >
                                 <i className="font-small fas fa-trash-alt" />
                              </button>
                           </td>
                        </tr>
                     ))
               )}
            </tbody>
         </table>
      </div>
   );
};
export default TableProyectos;

import React, { useEffect, useState } from 'react';
import ServiceOrderDetails from './ServiceOrderDetails';
import ServiceOrderTasksDetails from './ServiceOrderTasksDetails';
import { costsControlApi } from '../../../../../api/index';

const ServiceOrder = (props) => {
   const { item, onEditItem } = props;
   const [currentPage, setCurrentPage] = useState(0);

   const [optionsLists, setOptionsLists] = useState({});
   const [isLoadingOptionsList, setIsLoadingOptionsList] = useState(true);

   const pages = [ServiceOrderDetails, ServiceOrderTasksDetails];

   const [isExtraPage, setIsExtraPage] = useState(false);
   const lastPage = pages.length - 1;
   const isLastPage = currentPage === lastPage;
   const isFirstPage = currentPage === 0;

   const DetailsComponent = pages[currentPage];

   const handleNavigate = (direction) => {
      direction === 'next' ? navigateNextPage() : navigeteBackPage();
   };

   const navigeteBackPage = () => {
      if (isFirstPage) return;
      if (isExtraPage) {
         setIsExtraPage(false);
         return;
      }
      const backPage = currentPage - 1;
      setCurrentPage(backPage);
   };

   const navigateNextPage = () => {
      if (isLastPage) {
         setIsExtraPage(true);
         return;
      }
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
   };

   useEffect(() => {
      (async () => {
         const details = await costsControlApi.getOrdersDetailsList();
         setOptionsLists({
            typeOfServices: details.types_of_service || [],
            statusList: details.status_list || [],
            tasks: details.tasks || [],
            requests: details.requests || [],
            providers: details.providers || [],
            materials: details.materials || [],
         });
         setIsLoadingOptionsList(false);
      })();
   }, []);

   return (
      <>
         <DetailsComponent
            item={item}
            onEditItem={onEditItem}
            optionsLists={optionsLists}
            isLoadingOptionsList={isLoadingOptionsList}
            tasks={optionsLists?.tasks || []}
            isExtraPage={isExtraPage}
         />
         <div className="white-space-16" />
         <div style={{ margin: '0 auto' }}>
            {!isFirstPage && (
               <button
                  type="button"
                  className="btn btn-small btn-action-success"
                  onClick={() => handleNavigate('back')}>
                  <i className="weight-bold">Regresar</i>
               </button>
            )}
            {(!isLastPage || !isExtraPage) && (
               <button
                  type="button"
                  className="btn btn-small btn-action-success"
                  disabled={isLoadingOptionsList}
                  onClick={() => handleNavigate('next')}>
                  <i className="weight-bold">Siguiente</i>
               </button>
            )}
         </div>
      </>
   );
};

export default ServiceOrder;

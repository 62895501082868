/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import ItemInput from '../../ItemInput';
import ItemDate from '../../ItemDate';
import ItemOptions from '../../ItemOptions';
import moment from 'moment-timezone';
import { getOrdersByRequest } from '../../../../../api/control-catalogos.api';

const ServiceOrderDetails = (props) => {
   const { item, onEditItem, optionsLists, isLoadingOptionsList } = props;
   const [osQuantity, setOsQuantity] = React.useState(null);

   const defaultRequest = {
      id: item?.solicitud?.IdSolicitud || item?.id_request || item?.solicitud?.id || 0,
      name:
         item?.solicitud?.NoSolicitud ||
         item?.request ||
         item?.solicitud?.name ||
         'Selecciona una solicitud',
   };

   useEffect(() => {
      (async () => {
         if (item?.solicitud?.IdSolicitud || item?.request) {
            const quantity = await getOrdersByRequest(
               item?.solicitud?.IdSolicitud || item?.id_request
            );
            setOsQuantity(quantity);
         }
      })();
   }, [item.solicitud, item.request]);
   return (
      <React.Fragment>
         <div className="white-space-16" />
         {osQuantity?.quantity > 0 && (
            <div className="full row label-normal justify-center">
               <span>
                  <b>{osQuantity.message}</b>
               </span>
            </div>
         )}
         <ItemOptions
            disabled={item?.id_request || item?.solicitud?.IdSolicitud}
            label="Solicitud"
            options={optionsLists?.requests || [defaultRequest] || []}
            select={defaultRequest}
            onChange={onEditItem}
            attributeToChange="solicitud"
         />
         <div className="white-space-16" />
         <ItemInput
            label="Descripción"
            value={item.description}
            onValueUpdate={onEditItem}
            attributeToChange="description"
         />
         <div className="white-space-16" />

         <ItemOptions
            label="Tipo de servicio"
            options={optionsLists.typeOfServices || []}
            select={item.type_of_service}
            onChange={onEditItem}
            attributeToChange="type_of_service"
            isLoading={isLoadingOptionsList}
         />
         <div className="white-space-16" />

         <ItemOptions
            label="Proveedor"
            options={optionsLists.providers || []}
            select={item.provider}
            onChange={onEditItem}
            attributeToChange="provider"
            isLoading={isLoadingOptionsList}
         />
         <div className="white-space-16" />

         <ItemOptions
            label="Estado"
            options={optionsLists.statusList || []}
            select={item.status || optionsLists.statusList}
            onChange={onEditItem}
            attributeToChange="status"
            isLoading={isLoadingOptionsList}
         />

         <div className="white-space-16" />
         <ItemInput
            label="Comentarios"
            value={item.comments}
            onValueUpdate={onEditItem}
            attributeToChange="comments"
         />
         <div className="white-space-16" />
         <div className="row align-center full">
            <ItemDate
               label="Fecha Inicio"
               date={
                  item?.solicitud?.Fecha
                     ? moment.utc(item.solicitud.Fecha)
                     : item.start_date
               }
               onValueUpdate={onEditItem}
               attributeToChange="start_date"
               disabled
            />
            <ItemDate
               label="Fecha Fin"
               date={
                  item?.solicitud?.FechaFinaliza
                     ? moment.utc(item.solicitud.FechaFinaliza)
                     : item.end_date
               }
               onValueUpdate={onEditItem}
               attributeToChange="end_date"
               disabled
            />
         </div>
      </React.Fragment>
   );
};

export default ServiceOrderDetails;

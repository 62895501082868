/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cogoToast from 'cogo-toast';

import Paginator from '../Paginator';
import ModalContainer from './ModalContainer';
import {Consumer} from '../../context';

import advertencia from '../../images/advertencia.svg';
import DetailsSelector from './components/DetailsSelector';
import ItemTable from './components/ItemTable';
import {extractPathComponents} from '../../utils/serializers';

const CatalogTable = (props) => {
    const {
        catalog,
        items,
        setItems,
        modalProps,
        setModalProps,
        search,
        editModalClassName,
        editModalSize = 'normal',
    } = props;

    const [page, setPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState({});
    const [showModal, setShowModal] = useState({
        edit: false,
        delete: false,
        details: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [filteredItems, setFilteredItems] = useState(items);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const loadModalProps = async () => {
        if (catalog?.getModalProps) {
            const data = await catalog?.getModalProps();
            setModalProps(data);
        } else if (setModalProps) {
            setModalProps({});
        }
    };

    const handleSelectPage = (page) => {
        setPage(page);
    };

    const handleShowModal = (item, modalType) => {
        setShowModal((prevState) => ({
            ...prevState,
            [modalType]: !prevState[modalType],
        }));
        setSelectedItem(item);
    };

    const handleEditItem = (newValue, attrToChange) => {
        setSelectedItem((prevState) => {
            const [objectKey, indexString, propertyKey] =
                extractPathComponents(attrToChange);
            console.log(objectKey, indexString)
            // Case 1: Updating array element property
            if (indexString && objectKey) {
                const index = parseInt(indexString); // Extract index

                return {
                    ...prevState,
                    [objectKey]: prevState[objectKey].map((item, i) =>
                        i === index ? {...item, [propertyKey]: newValue} : item
                    ),
                };
            }

            // Case 2: Updating single object property
            return {
                ...prevState,
                [attrToChange]: newValue,
            };
        });
    };

    const handleSaveItem = async (e, item) => {
        e.preventDefault();
        if (!catalog.validateData(item)) {
            cogoToast.error('Por favor, ingrese los datos faltantes', {
                position: 'top-right',
            });
            return;
        }
        setIsSaving(true);
        await catalog.saveItem(item);
        handleShowModal(null, 'edit');
        setIsSaving(false);
        //API request to update item
        fetchCatalogItems();
    };

    const handleDeleteItem = async (e, item) => {
        e.preventDefault();
        try {
            setIsDeleting(true);
            await catalog.deleteItem(item.id);
            setItems(items.filter((it) => it.id !== item.id));
            handleShowModal(null, 'delete');
            setIsDeleting(false);
        } catch (err) {
            cogoToast.error('No se pudo eliminar el elemento', {
                position: 'top-right',
            });
        }
    };

    const fetchCatalogItems = async () => {
        setIsLoading(true);
        const items = await catalog.getItems();
        setItems(items);
        setIsLoading(false);
    };

    const isPrice = (column) => {
        return column === 'Total';
    };

    const handleSearchItems = (items, searchText) => {
        if (!items || !searchText) return items;

        const words = searchText
            .toLowerCase()
            .split(' ')
            .map((word) =>
                word
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/[.,#!$%\\^&\\*;:{}=_`~()"]/g, '')
            );

        return items.filter((item) => {
            const itemCopy = {...item};
            delete itemCopy.comments;
            delete itemCopy.materials;
            delete itemCopy.staff;
            delete itemCopy.status;
            delete itemCopy.type_of_task;
            delete itemCopy.type_of_service;
            delete itemCopy.provider;

            const sanitizedItem = JSON.stringify(itemCopy)
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .replace(/[.,#!$%\\^&\\*;:{}=_`~()"]/g, ' ');

            return words.some((word) => sanitizedItem.includes(word));
        });
    };

    useEffect(() => {
        if (!catalog.catalogName) {
            return;
        }
        Promise.all([fetchCatalogItems(), loadModalProps()]);
    }, [catalog]);

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);

    useEffect(() => {
        if (search) setFilteredItems(handleSearchItems(items, search?.toLowerCase()));
    }, [search]);

    useEffect(() => {
        return () => {
        };
    }, []);

    return (
        <React.Fragment>
            {
                <div className="table-responsive full">
                    <table>
                        <thead>
                        <tr>
                            {catalog.columns && catalog.columns.length > 0 ? (
                                catalog.columns.map((column, index) => (
                                    <th
                                        key={column + index}
                                        className={isPrice(column) ? 'text-right' : 'text-left'}>
                                        {column}
                                    </th>
                                ))
                            ) : (
                                <th className="text-left">{catalog.label}</th>
                            )}

                            <th className="text-center"></th>

                            <th className="text-center"></th>
                        </tr>
                        </thead>

                        <tbody>
                        {isLoading ? (
                            <tr>
                                <td className="text-center" colSpan="3">
                                    <i className="fas fa-spinner fa-spin"></i>
                                </td>
                            </tr>
                        ) : (
                            filteredItems
                                .slice((page - 1) * 15, page * 15)
                                .map((item, index) => (
                                    <ItemTable
                                        key={`${item.id}-${index}`}
                                        item={item}
                                        itemProperties={catalog.columnsProps}
                                        onEditClick={() => handleShowModal(item, 'edit')}
                                        onDeleteClick={() => handleShowModal(item, 'delete')}
                                    />
                                ))
                        )}
                        </tbody>
                    </table>
                </div>
            }
            <div className="row">
                <div className="white-space-16"></div>
            </div>

            <div className="row justify-center align-center">
                <Paginator
                    pages={Math.ceil(filteredItems.length / 15)}
                    setPage={handleSelectPage.bind(this)}
                />
            </div>

            {showModal.edit && (
                <ModalContainer
                    size={editModalSize}
                    title={`Editar ${catalog.itemName}`}
                    btnSuccessText="Guardar"
                    onCancel={() => handleShowModal(null, 'edit')}
                    onClose={() => handleShowModal(null, 'edit')}
                    onSuccess={(e) => handleSaveItem(e, selectedItem)}
                    isLoading={isSaving}
                    className={editModalClassName}>
                    <DetailsSelector
                        select={catalog.detailsComponent}
                        item={selectedItem}
                        specificProperties={catalog.specificProperties}
                        onEditItem={handleEditItem}
                        modalProps={modalProps}
                    />
                </ModalContainer>
            )}

            {showModal.delete && (
                <ModalContainer
                    size="mini"
                    title="Eliminar"
                    btnSuccessText="Eliminar"
                    onCancel={() => handleShowModal(null, 'delete')}
                    onClose={() => handleShowModal(null, 'delete')}
                    onSuccess={(e) => handleDeleteItem(e, selectedItem)}
                    isLoading={isDeleting}>
                    <div className="img-responsive img-trash full row align-center justify-center">
                        <img src={advertencia} alt="Eliminar"/>
                    </div>

                    <div className="white-space-16"/>

                    <div className="full row align-center justify-center">
                        <p className="text-center color-black font-huge weight-bold">
                            {`¿Está seguro que desea eliminar el ${catalog.itemName}`}
                        </p>
                    </div>

                    <div className="white-space-16"/>
                </ModalContainer>
            )}
        </React.Fragment>
    );
};

PropTypes.checkPropTypes = {
    data: PropTypes.array,
    itemName: PropTypes.string,
};

export default Consumer(CatalogTable);

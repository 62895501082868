import React from 'react';
import { Consumer } from '../context';
import { Link } from 'react-router-dom';

const Menu = props => {
   return (
      <div id="menu" className="menu row justify-center">
         <div className="container column half align-center">
            <div className="row half justify-end">
               <a
                  href="#logout"
                  className="color-black menu-nav"
                  onClick={() => {
                     const menu = document.getElementById('menu');
                     if (menu) {
                        menu.classList.toggle('open');
                     }
                  }}
               >
                  <i className="fas fa-times font-double " />
               </a>
            </div>
            <div className="row">
               <div className="white-space-24"></div>
            </div>
            <div className="row half ">
               <div className="column column-menu">
                  <div className="white-space-16"></div>
                  <div className="justify-start">
                     <Link className="font-regular color-black menu-nav" to="/">
                        <i className="fas fa-tachometer-alt font-text " />{' '}
                        Dashboard
                     </Link>
                  </div>
                  <div className="white-space-16"></div>
                  <div className="justify-start">
                     <Link className="font-regular color-black menu-nav">
                        <i className="fas fa-edit font-text " /> Definición
                     </Link>
                  </div>
                  <div className="white-space-16"></div>
                  <div className="justify-start">
                     <Link className="font-regular color-black menu-nav">
                        <i className="fas fa-cog" /> Configuración
                     </Link>
                  </div>
                  <div className="white-space-16"></div>
                  <div className="justify-start">
                     <a
                        href="#logout"
                        className="font-regular color-black menu-nav"
                     >
                        <i className="fas fa-sign-out-alt font-text " />{' '}
                        Contratos
                     </a>
                  </div>
                  <div className="white-space-16"></div>
                  <div className="justify-start">
                     <a
                        href="#logout"
                        className="font-regular color-black menu-nav"
                     >
                        <i className="fas fa-scroll font-text " /> Garantías
                     </a>
                  </div>
                  <div className="white-space-16"></div>
                  <div className="justify-start">
                     <a
                        href="#logout"
                        className="font-regular color-black menu-nav"
                     >
                        <i className="fas fa-file-medical font-text " />{' '}
                        Documentos
                     </a>
                  </div>
                  <div className="white-space-16"></div>
                  <div className="justify-start">
                     <a
                        href="#logout"
                        className="font-regular color-black menu-nav"
                     >
                        <i className="fas fa-chart-pie font-text " /> Reportes
                     </a>
                  </div>
                  <div className="white-space-16"></div>
               </div>
               <div className="column full">
                  <div className="justify-center">
                     <h3 className="color-gray">Definición</h3>
                  </div>
                  <div className="white-space-16"></div>

                  <div className="justify-center">
                     <Link
                        className="font-regular color-black menu-nav"
                        to="/page"
                     >
                        Desarrollo
                     </Link>
                  </div>
                  <div className="white-space-8"></div>
                  <div className="justify-center">
                     <Link
                        className="font-regular color-black menu-nav"
                        to="/page"
                     >
                        Tipo Unidad
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default Consumer(Menu);

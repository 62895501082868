// node_module
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import WOW from 'wowjs';
import moment from 'moment-timezone';

// generales
import { Consumer } from '../context';
import Request from '../core/httpClient';
import DinamicModal from '../components/modal/DinamicModal';
import Controls from '../components/Controls';
import Sidebar from '../components/Sidebar';
import Paginator from '../components/Paginator';
// componentes
import TableAbiertas from '../components/solicitud/tables/tableAbiertas';
import ModalSolicitud from '../components/solicitud/modal/modalSolicitud';

const req = new Request();

class SolicitudesAbiertas extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 1,
         solicitudes: [],
         loadingSolicitudes: false,
         modalName: 'modalSolicitud',
         desde: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
         hasta: new Date().toISOString(),
         savingModal: false,
         newMode: true,
         estado: 0,
         areas: [],
         equipos: [],
         problemas: [],
         estados: [],
         statesAvailable: [4, 5, 10],
         estadosFiltro: [],
         proyectos: [],
         unidades: [],
         propietarios: [],
         solicitud: {},
         pictures: [],
         filter: '',
      };
   }

   componentDidMount() {
      this.loadSolicitudes();
      this.loadAreas();
      this.loadProblemas();
      this.loadEquipos();
      this.loadEstados();
      this.loadProyectos();
      this.loadUnidades();
      this.loadPropietarios();
      new WOW.WOW({
         live: false,
      }).init();
   }

   componentDidUpdate(_, prevState) {
      if (prevState.desde !== this.state.desde || prevState.hasta !== this.state.hasta) {
         this.loadSolicitudes();
      }
   }

   // load

   async loadSolicitudes() {
      this.setState({
         loadingSolicitudes: true,
      });
      const data = {
         Desde: moment(this.state.desde).format('YYYY-MM-DD'),
         Hasta: moment(this.state.hasta).format('YYYY-MM-DD'),
      };
      const requestApi = await req.get('solicitud/get', data);
      if (!requestApi.empty && !requestApi.error) {
         this.setState({
            solicitudes: requestApi.data,
         });
      } else {
         this.setState({
            solicitudes: [],
         });
      }
      this.setState({
         loadingSolicitudes: false,
      });
   }

   async loadAreas() {
      this.setState({
         loadingAreas: true,
      });
      let response = await req.get('config/get/areas', {});

      if (!response.error || !response.empty) {
         this.setState({
            areas: response.areas || [],
         });
      } else {
         this.setState({ areas: [] });
      }
      this.setState({
         loadingAreas: false,
      });
   }

   async loadEquipos() {
      this.setState({
         loadingEquipos: true,
      });
      let response = await req.get('config/get/equipos', {});

      if (!response.error || !response.empty) {
         this.setState({
            equipos: response.equipos || [],
         });
      } else {
         this.setState({ equipos: [] });
      }
      this.setState({
         loadingEquipos: false,
      });
   }

   async loadProblemas() {
      this.setState({
         loadingProblemas: true,
      });
      let response = await req.get('config/get/problemas', {});
      if (!response.error || !response.empty) {
         this.setState({
            problemas: response.problemas || [],
         });
      } else {
         this.setState({
            problemas: [],
         });
      }
      this.setState({
         loadingProblemas: false,
      });
   }

   async loadEstados() {
      this.setState({
         loadingEstados: true,
      });
      let response = await req.get('config/get/estados', {});
      if (!response.error || !response.empty) {
         this.setState({
            estadosFiltro: response.estados.filter((edo) =>
               this.state.statesAvailable.includes(edo.IdEstado)
            ),
         });
         this.setState({
            estados: response.estados || [],
         });
      } else {
         this.setState({ estados: [] });
      }
      this.setState({
         loadingEstados: false,
      });
   }

   async loadProyectos() {
      this.setState({
         loadingProyectos: true,
      });
      const response = await req.get('proyectos/get', {});
      if (!response.empty && !response.error) {
         this.setState({
            proyectos: response.data ? response.data : [],
         });
      } else {
         this.setState({
            proyectos: [],
         });
      }
      this.setState({
         loadingProyectos: false,
      });
   }

   async loadUnidades() {
      this.setState({
         loadingUnidades: true,
      });
      const response = await req.get('proyectos/get/all/unidades', {});
      if (!response.empty && !response.error) {
         this.setState({
            unidades: response.data ? response.data : [],
         });
      } else {
         this.setState({
            unidades: [],
         });
      }
      this.setState({
         loadingUnidades: false,
      });
   }

   async loadPropietarios() {
      this.setState({
         loadingPropietarios: true,
      });
      const apiRequest = await req.get('propietarios/get/all', {});

      if (!apiRequest.error) {
         this.setState({
            propietarios: apiRequest.data ? apiRequest.data : [],
         });
      } else {
         this.setState({
            propietarios: [],
         });
      }
      this.setState({
         loadingPropietarios: false,
      });
   }

   // generales

   async selectPage(page) {
      this.setState({ page: page });
   }

   async openModal() {
      let modal = document.getElementById(this.state.modalName);

      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async closeModal() {
      let modal = document.getElementById(this.state.modalName);
      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   async updateJson(json) {
      this.setState(() => json);
   }

   async updateJsonDate(json) {
      this.setState(() => json);
   }

   // solicitudes
   async openNewSolicitud() {
      this.setState({
         solicitud: {},
         newMode: true,
         saveModal: this.createSolicitud.bind(this),
      });
      this.openModal();
      let tab1 = document.getElementById('tab-one');
      if (tab1) {
         tab1.checked = true;
      }
   }

   async setSolicitud(solicitud) {
      this.setState({
         solicitud: solicitud,
         newMode: false,
         saveModal: this.updateSolicitud.bind(this),
      });
      this.openModal();
      let tab1 = document.getElementById('tab-one');
      if (tab1) {
         tab1.checked = true;
      }
   }

   async createSolicitud(event) {
      event.preventDefault();

      const ComentariosAplica = document.getElementById('ComentariosAplica')?.value;

      this.setState({
         savingModal: true,
      });
      let solicitud = this.state.solicitud;

      if (!solicitud.IdEstado) {
         cogoToast.error('Por favor, seleccione un estado. En la pestaña "Garantía"', {
            position: 'top-right',
         });
         this.setState({
            savingModal: false,
         });
         return;
      }

      let file1 = solicitud.File1;
      let file2 = solicitud.File2;
      let file3 = solicitud.File3;

      let NumeroUnidad = this.state.unidades.filter(
         (un) => un.IdUnidad === solicitud.IdUnidad
      );
      let NombreArea = this.state.areas.filter((ar) => ar.IdArea == solicitud.IdArea);
      let NombreEstado = this.state.estados.filter(
         (edo) => edo.IdEstado == (solicitud?.IdEstado || 4)
      );

      let data = {
         IdUnidad: solicitud.IdUnidad,
         IdProyecto: solicitud.IdProyecto,
         Fecha: solicitud.Fecha,
         IdArea: solicitud.IdArea,
         IdEquipo: solicitud.IdEquipo,
         IdProblema: solicitud.IdProblema,
         IdPersona: solicitud.IdPersona,
         Comentarios: solicitud.Comentarios ? solicitud.Comentarios : '',
         Fecha1: solicitud.Fecha1 ? solicitud.Fecha1 : '',
         Fecha2: solicitud.Fecha2 ? solicitud.Fecha2 : '',
         Fecha3: solicitud.Fecha3 ? solicitud.Fecha3 : '',
         ComentariosAplica,
         ComentariosReparacion: null,
         ComentariosVisita: null,
         AplicaGarantia: solicitud.AplicaGarantia ? 1 : 0,
         IdEstado: solicitud?.IdEstado || 4,
         NumeroUnidad: NumeroUnidad[0].Numero,
         NombreArea: NombreArea[0].NombreArea,
         NombreEstado: NombreEstado[0].Estado,
      };

      const requestApi = await req.postFile(
         'solicitud/create',
         [file1, file2, file3],
         data
      );

      if (requestApi.created) {
         cogoToast.success('Solicitud actualizada con éxito.', {
            position: 'top-right',
         });
         this.loadSolicitudes();
         this.closeModal();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }

      this.setState({
         savingModal: false,
      });
   }

   async updateSolicitud(event) {
      event.preventDefault();
      const ComentariosAplica = document.getElementById('ComentariosAplica').value;
      const ComentariosReparacion = document.getElementById('ComentarioReparacion').value;
      const ComentariosVisita = document.getElementById('ComentariosVisita').value;

      this.setState({
         savingModal: true,
      });
      let solicitud = this.state.solicitud;

      let NumeroUnidad = this.state.unidades.filter(
         (un) => un.IdUnidad === solicitud.IdUnidad
      );
      let NombreArea = this.state.areas.filter((ar) => ar.IdArea === solicitud.IdArea);
      let NombreEstado = this.state.estados.filter(
         (edo) => edo.IdEstado === Number(solicitud.IdEstado)
      );

      let data = {
         IdSolicitud: solicitud.IdSolicitud,
         IdPersona: solicitud.IdPersona,
         Fecha1: solicitud.Fecha1,
         Fecha2: solicitud.Fecha2,
         Fecha3: solicitud.Fecha3,
         IdUnidad: solicitud.IdUnidad,
         IdProyecto: solicitud.IdProyecto,
         ComentariosAplica,
         ComentariosReparacion,
         ComentariosVisita,
         AplicaGarantia: solicitud.AplicaGarantia ? 1 : 0,
         IdEstado: solicitud.IdEstado,
         NumeroUnidad: NumeroUnidad[0].Numero ? NumeroUnidad[0].Numero : '',
         NombreArea: NombreArea[0].NombreArea ? NombreArea[0].NombreArea : '',
         NombreEstado: NombreEstado[0].Estado ? NombreEstado[0].Estado : '',
         realizado: solicitud.realizado || false,
      };

      const requestApi = await req.postFile('solicitud/update', solicitud.pictures, data);

      if (requestApi.response) {
         cogoToast.success('Solicitud actualizada con éxito.', {
            position: 'top-right',
         });
         this.loadSolicitudes();
         this.closeModal();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }

      this.setState({
         savingModal: false,
      });
   }

   setFilter(filter) {
      this.setState({
         filter: filter.toLowerCase(),
      });
   }

   filter(solicitudes) {
      const filter = this.state.filter.toLowerCase();
      const dateSearch = this.state.filter.includes('-')
         ? this.state.filter.split('-')
         : this.state.filter.split('/') || [this.state.filter];
      if (dateSearch.length === 0) {
         dateSearch.push(filter);
      }

      return solicitudes.filter(
         (sol) =>
            this.state.filter === '' ||
            sol.Estado.toLowerCase().includes(filter) ||
            sol.Numero.toLowerCase().includes(filter) ||
            sol.NoSolicitud.toLowerCase().includes(filter) ||
            sol.NombreProyecto.toLowerCase().includes(filter) ||
            sol.NombrePersona.toLowerCase().includes(filter) ||
            dateSearch.some((date) => sol.Fecha?.includes(date)) ||
            dateSearch.some((date) => sol.Fecha1?.includes(date)) ||
            dateSearch.some((date) => sol.Fecha2?.includes(date)) ||
            dateSearch.some((date) => sol.Fecha3?.includes(date)) ||
            dateSearch.some((date) => sol.FechaVisita?.includes(date)) ||
            dateSearch.some((date) => sol.FechaFinaliza?.includes(date))
      );
   }

   render() {
      let solicitudes = [];

      if (this.state.solicitudes) {
         solicitudes = this.filter(this.state.solicitudes);
      }

      return (
         <div className="column justify-center align-center proyectos">
            <Helmet>
               <title>ELEVA Capital Group | Reportes</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>
                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Reportes</h3>
                        <h3 className="color-secondary"> &nbsp;/&nbsp;</h3>
                        <h4 className="color-darkgray">Abiertos</h4>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls
                              newHandler={this.openNewSolicitud.bind(this)}
                              setFilter={this.setFilter.bind(this)}>
                              {/* <div className="row justify-end align-center full"> */}
                              <div className="column text-normal">
                                 <p className="color-secondary">
                                    <b>Desde:</b>
                                 </p>
                              </div>
                              <div className="column ">
                                 <input
                                    name="from"
                                    className="input"
                                    value={moment(this.state.desde).format('YYYY-MM-DD')}
                                    type="date"
                                    onChange={(event) => {
                                       this.updateJsonDate({
                                          desde: event.target.value,
                                       });
                                    }}
                                 />
                              </div>
                              <div className="column text-normal padding-left-semi">
                                 <p className="color-secondary">
                                    <b>Hasta:</b>
                                 </p>
                              </div>
                              <div className="column ">
                                 <input
                                    name="to"
                                    className="input"
                                    type="date"
                                    value={moment(this.state.hasta).format('YYYY-MM-DD')}
                                    onChange={(event) => {
                                       this.updateJsonDate({
                                          hasta: event.target.value,
                                       });
                                    }}
                                 />
                              </div>
                              <div className="column text-normal padding-left-semi">
                                 <p className="color-secondary">
                                    <b>Estado:</b>
                                 </p>
                              </div>
                              <div className="column">
                                 <select
                                    className="input"
                                    value={this.state.estado}
                                    onChange={(event) => {
                                       this.setState({
                                          estado: event.target.value,
                                       });
                                    }}>
                                    <option value="">Todos</option>
                                    {this.state.estadosFiltro.map((edo, key) => (
                                       <option key={key} value={edo.IdEstado}>
                                          {edo.Estado}
                                       </option>
                                    ))}
                                 </select>
                              </div>
                              {/* </div> */}
                           </Controls>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center wow fadeIn">
                           <div className="container">
                              <div className="column card-table full align-center">
                                 <TableAbiertas
                                    loadingSolicitudes={this.state.loadingSolicitudes}
                                    solicitudes={solicitudes.filter((sol) =>
                                       this.state.estado !== '' && this.state.estado !== 0
                                          ? sol.IdEstado === Number(this.state.estado)
                                          : true
                                    )}
                                    page={this.state.page}
                                    setSolicitud={this.setSolicitud.bind(this)}
                                 />
                                 <div className="row">
                                    <div className="white-space-16"></div>
                                 </div>
                                 <div className="row justify-center align-center">
                                    <Paginator
                                       pages={Math.ceil(
                                          solicitudes.filter((sol) =>
                                             this.state.estado !== '' &&
                                             this.state.estado !== 0
                                                ? sol.IdEstado ===
                                                  Number(this.state.estado)
                                                : true
                                          ).length / 15
                                       )}
                                       setPage={this.selectPage.bind(this)}
                                    />
                                 </div>
                                 <div className="row">
                                    <div className="white-space-16"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <DinamicModal
               idModal={'modalSolicitud'}
               sizeModalContainer={'normal'}
               title={'Reportes'}
               btnTextSuccess={'Guardar'}
               success={this.state.saveModal}
               showBtnSuccess={true}
               btnTextCancel={'Cancelar'}
               showBtnCancel={true}
               loadingSave={this.state.savingModal}>
               <ModalSolicitud
                  updateJson={this.updateJson.bind(this)}
                  solicitud={this.state.solicitud}
                  areas={this.state.areas}
                  equipos={this.state.equipos}
                  problemas={this.state.problemas}
                  estados={this.state.estados}
                  proyectos={this.state.proyectos}
                  unidades={this.state.unidades}
                  propietarios={this.state.propietarios}
                  newMode={this.state.newMode}
                  pictures={this.state.pictures}
               />
            </DinamicModal>
         </div>
      );
   }
}
export default withRouter(Consumer(SolicitudesAbiertas));

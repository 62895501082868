import React from 'react';

export default function TextAreaRow({ label, name, defaultValue, disabled = false }) {
   return (
      <div className="row full align-center">
         <div className="column label-normal">
            <p>
               <b>{label}</b>
            </p>
         </div>
         <div className="column full">
            <textarea
               id={name}
               name={name}
               disabled={disabled}
               className="input"
               defaultValue={defaultValue}></textarea>
         </div>
      </div>
   );
}

import React, { Component } from 'react';
import AppLinks from '../components/AppLinks';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Consumer } from '../context';

class UserUnauthorizedWeb extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   render() {
      return (
         <div
            className="flex justify-center login"
            style={{
               width: '100%',
               height: '100vh',
               alignItems: 'center',
               backgroundColor: 'rgba(255, 255, 255, 0.5)',
               backgroundBlendMode: 'overlay',
            }}>
            <div
               className="flex justify-between"
               style={{
                  flexDirection: 'column',
                  width: '100%',
                  maxWidth: '500px',
                  height: '200px',
               }}>
               <h1 className="text-center">Continua en la app</h1>
               <div style={{ width: '100%' }}>
                  <AppLinks />
               </div>
            </div>
            <div
               style={{
                  bottom: '30px',
                  right: '30px',
                  position: 'fixed',
                  fontSize: '20px',
               }}>
               <Link
                  className="color-black"
                  to="/login"
                  onClick={() => {
                     this.props.context.logOut();
                  }}>
                  <i className="fas fa-power-off "></i>
                  &nbsp;Logout
               </Link>
            </div>
         </div>
      );
   }
}

export default withRouter(Consumer(UserUnauthorizedWeb));

import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import WOW from 'wowjs';
import cogoToast from 'cogo-toast';
import { catalogDetails } from '../utils/controlCostsCatalog';

import { Consumer } from '../context';
import ModalContainer from '../components/controlCostos/ModalContainer';
import Controls from '../components/Controls';
import Sidebar from '../components/Sidebar';
import CatalogTable from '../components/controlCostos/CatalogTable';
import DetailsSelector from '../components/controlCostos/components/DetailsSelector';

class ControlCompras extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         catalogData: {},
         showModal: false,
         newItemValue: '',
         items: [],
         searchInput: '',
      };

      this.catalogDetails = catalogDetails;
   }

   setItems = (items) => {
      this.setState({ items });
   };

   async componentDidMount() {
      this.handleSelectCatalog('shopping');
      new WOW.WOW({
         live: false,
      }).init();
   }

   async handleSelectCatalog(catalog) {
      const catalogData = this.catalogDetails[catalog];
      this.setState({
         catalogData: catalogData,
      });
   }

   async handleEditItem(newValue, attrToChange) {
      this.setState((prevState) => ({
         newItemValue: {
            ...prevState.newItemValue,
            [attrToChange]: newValue,
         },
      }));
   }

   async handleShowModal() {
      this.setState({
         showModal: !this.state.showModal,
      });
   }

   async handleCreateItem(e) {
      e.preventDefault();
      const { catalogData, newItemValue } = this.state;
      if (!catalogData.validateData(newItemValue)) {
         cogoToast.error('Por favor, ingrese los datos faltantes', {
            position: 'top-right',
         });
         return;
      }
      await catalogData.createItem(newItemValue);

      this.handleShowModal();
      this.setState({
         items: await catalogData.getItems(),
      });
      this.setState({
         newItemValue: {},
      });
   }

   render() {
      return (
         <div className="column justify-center align-center catalogo">
            <Helmet>
               <title>ELEVA Capital Group | Compras</title>
            </Helmet>
            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>

                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Control costos</h3>
                        <h3 className="color-secondary">&nbsp;/&nbsp;</h3>
                        <h4 className="color-darkgray">Compras</h4>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls
                              newHandler={this.handleShowModal.bind(this)}
                              showFilter={true}
                           />
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center">
                           <div className="container">
                              <div
                                 className="column card-table full align-center table-side wow fadeIn"
                                 data-wow-delay="0.3s">
                                 {/* Tablas */}
                                 <div className="mt-search input-clean">
                                    <input
                                       type="text"
                                       placeholder="Buscar"
                                       className="input input-filter"
                                       onChange={(e) => {
                                          this.setState({
                                             searchInput: e.target.value,
                                          });
                                       }}
                                    />
                                    <i className="fas fa-search font-small" />
                                 </div>
                                 <CatalogTable
                                    catalog={this.state.catalogData}
                                    items={this.state.items}
                                    setItems={this.setItems}
                                    search={this.state.searchInput}
                                 />
                                 <div className="row">
                                    <div className="white-space-8"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {this.state.showModal && (
                  <ModalContainer
                     size="normal"
                     title={`Añadir ${this.state.catalogData.itemName}`}
                     btnSuccessText="Guardar"
                     onCancel={this.handleShowModal.bind(this)}
                     onClose={this.handleShowModal.bind(this)}
                     onSuccess={this.handleCreateItem.bind(this)}>
                     <DetailsSelector
                        select={this.state.catalogData.detailsComponent}
                        item={this.state.newItemValue}
                        onEditItem={this.handleEditItem.bind(this)}
                     />
                  </ModalContainer>
               )}
            </div>
         </div>
      );
   }
}

export default withRouter(Consumer(ControlCompras));

import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { Consumer } from '../context';
import Menu from '../components/Menu';
import DinamicModal from '../components/modal/DinamicModal';
import Controls from '../components/Controls';
import TableDesarrollo from '../components/TableDesarrollo';
import Paginator from '../components/Paginator';
import Sidebar from '../components/Sidebar';

class Home extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
      };
   }

   async selectPage(page) {
      this.setState({ page: page });
   }

   async openModal() {
      let modal = document.getElementById('newModal');

      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async saveMethod() {}

   render() {
      return (
         <div className="column justify-center align-center">
            <Helmet>
               <title>ELEVA Capital Group | Eventos</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Eventos</h3>
                     </div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls newHandler={this.openModal.bind(this)} />
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center">
                           <TableDesarrollo />
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Paginator
                              pages={Math.ceil(100 / 20)}
                              setPage={this.selectPage.bind(this)}
                           />
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                     </div>
                  </div>
               </div>

               <DinamicModal
                  idModal={'newModal'}
                  sizeModalContainer={'normal'}
                  title={'Nuevo modal'}
                  btnTextSuccess={'Guardar'}
                  success={this.saveMethod.bind(this)}
                  showBtnSuccess={true}
                  btnTextCancel={'Cancelar'}
                  showBtnCancel={true}
                  loadingSave={false}
               >
                  <div className="full row align-center">
                     <div className="column full">
                        <div className="row full align-center">
                           <div className="column label-medium">
                              <p>
                                 <b>Nombre:</b>
                              </p>
                           </div>
                           <div className="column full">
                              <input
                                 type="text"
                                 className="input input-modals"
                                 autoComplete="off"
                                 required
                                 minLength={2}
                              />
                           </div>
                        </div>
                        <div className="white-space-16" />
                        <div className="row full align-center">
                           <div className="column label-medium">
                              <p>
                                 <b>Label 2:</b>
                              </p>
                           </div>
                           <div className="column full">
                              <select
                                 name="concepto"
                                 id=""
                                 className="input input-modal"
                              ></select>
                           </div>
                        </div>
                        <div className="white-space-16" />
                        <div className="row full align-center">
                           <div className="column label-medium">
                              <p>
                                 <b>Label 3:</b>
                              </p>
                           </div>
                           <div className="column full">
                              <input
                                 type="text"
                                 name="factura"
                                 className="input input-modals"
                                 autoComplete="off"
                                 required
                                 minLength={2}
                              />
                           </div>
                        </div>
                        <div className="white-space-16" />
                        <div className="row full align-center">
                           <div className="column label-medium">
                              <p>
                                 <b>Fecha:</b>
                              </p>
                           </div>
                           <div className="column full">
                              <input
                                 type="date"
                                 name="fecha"
                                 className="input input-modals"
                                 placeholder="Fecha"
                                 required
                                 minLength="2"
                                 maxLength="4"
                              />
                           </div>
                        </div>
                        <div className="white-space-16" />
                        <div className="row full align-center">
                           <div className="column label-normal">
                              <p>
                                 <b>Label 4:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <input
                                 type="number"
                                 name="importe"
                                 className="input input-modals"
                                 autoComplete="off"
                                 required
                                 minLength={2}
                                 step="any"
                              />
                           </div>
                           <div className="column label-middle">
                              <p>
                                 <b>Label 4:</b>
                              </p>
                           </div>
                           <div className="column column-small">
                              <input
                                 type="number"
                                 name="importe"
                                 className="input input-modals"
                                 autoComplete="off"
                                 required
                                 minLength={2}
                                 step="any"
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </DinamicModal>
               <Menu />
            </div>
         </div>
      );
   }
}
export default withRouter(Consumer(Home));

import React from 'react';
import moment from 'moment-timezone';

const ItemDate = (props) => {
   const { label, date, onValueUpdate, attributeToChange, disabled } = props;

   return (
      <div className="row align-center full">
         <div className="column label-normal">
            <p>
               <b>{`${label}: `}</b>
            </p>
         </div>

         <div className="column ">
            <input
               className="input"
               value={moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')}
               type="date"
               disabled={disabled}
               onChange={(e) =>
                  onValueUpdate(
                     moment(e.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                     attributeToChange
                  )
               }
            />
         </div>
      </div>
   );
};

export default ItemDate;

/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import moment from 'moment-timezone';
import Request from '../core/httpClient';
import cogoToast from 'cogo-toast';
const req = new Request();

//materiales

const stateToas = (message, type) => {
   if (type === 'error')
      cogoToast.error(message, {
         position: 'top-right',
      });
   if (type === 'success')
      cogoToast.success(message, {
         position: 'top-right',
      });
};

export const getMaterialsAndTools = async () => {
   const response = await req.get('costos/materiales');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const createMaterials = async (data, type) =>
   await req.post('costos/materiales', {
      Codigo: data.code,
      Nombre: data.name,
      IdTipoMaterial: type,
      IdCategoriaMaterial: data.category_of_material.id,
      Comentarios: data.comments || '',
      IdEstado: data.id_status || 3,
      Cantidad: data.quantity || 0,
      Max: data.max,
      Min: data.min,
      Price: data.price,
      Action: 'INSERT',
   });

export const updateMaterials = async (data) =>
   await req.put(
      `costos/materiales?${new URLSearchParams({
         id: data.id,
         name: data.name,
         idType: data.type_of_material.id,
         idCategory: data.category_of_material.id,
         comments: data.comments,
         idStatus: data.id_status,
         code: data.code,
         quantity: data.quantity,
         max: data.max,
         min: data.min,
         price: data.price,
      })}`
   );

export const deleteMaterials = async (id) => await req.delete(`costos/materiales/${id}`);

export const getMaterialsCategories = async (type) => {
   const response = await req.get('costos/materiales/categorias');
   if (response.error || response.empty) {
      return [];
   }
   return response.filter((category) => category.type_of_material === type);
};

export const createMaterialsCategories = async (data) =>
   await req.post('costos/materiales/categorias', data);

export const updateMaterialsCategories = async (data) => {
   await req.put(
      `costos/materiales/categorias?${new URLSearchParams({
         id: data.id,
         name: data.name,
         status: data.status,
      })}`
   );
};

export const deleteMaterialsCategories = async (id) =>
   await req.delete(`costos/materiales/categorias/${id}`);

// personal

export const getStaff = async () => {
   const staff = await req.get('costos/personal');
   if (staff.error || staff.empty) {
      return [];
   }
   return staff;
};

export const createStaff = async (data) => {
   await req.post('costos/personal', {
      Nombre: data.name,
      FechaInicio: moment(data.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      FechaFin: data?.end_date
         ? moment(data?.end_date, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
         : null,
      IdEstado: data.id_status,
      Costo: data.cost.toFixed(2),
      IdRegion: data.region.id,
      Categories: JSON.stringify(data.categories),
      Action: 'INSERT',
   });
};

export const updateStaff = async (data) => {
   await req.put(
      `costos/personal?${new URLSearchParams({
         id: data.id,
         name: data.name,
         startDate: moment(data.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
         endDate: data?.end_date
            ? moment(data?.end_date, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
            : null,
         idStatus: data.id_status,
         cost: data.cost.toFixed(2),
         idRegion: data.region.id,
         categories: JSON.stringify(data.categories),
      })}`
   );
};

export const deleteStaff = async (id) => await req.delete(`costos/personal/${id}`);

export const getStaffCategories = async () => {
   const response = await req.get('costos/personal/categoria');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const createStaffCategories = async (data) =>
   await req.post('costos/personal/categoria', {
      Nombre: data.name,
      IdEstado: data.id_status || 1,
      Action: 'INSERT',
   });

export const updateStaffCategories = async (data) =>
   await req.put(
      `costos/personal/categoria?${new URLSearchParams({
         IdCategoria: data.id,
         Nombre: data.name,
         IdEstado: data.id_status || 1,
      })}`
   );

export const deleteStaffCategories = async (id) =>
   await req.delete(`costos/personal/categoria/${id}`);

export const getRegions = async () => {
   const response = await req.get('costos/personal/region');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const getTaskCategories = async () => {
   const res = await req.get('costos/tareas/categoria');
   if (res?.error) {
      stateToas(
         `Ocurrio un error al intentar obtener las categorias: ${res.message}`,
         'error'
      );
      return [];
   }
   return res;
};

export const createTaskCategories = async (data) => {
   const res = await req.post('costos/tareas/categoria', {
      Nombre: data.name,
      IdEstado: data.id_status || 3,
      Action: 'INSERT',
   });
   if (res?.error) {
      stateToas(
         `Ocurrio un error al intentar obtener las categorías: ${res.message}`,
         'error'
      );
      return;
   }
};

export const updateTaskCategories = async (data) => {
   const res = await req.patch('costos/tareas/categoria', {
      IdCategoria: data.id,
      Nombre: data.name,
      IdEstado: data.id_status || 1,
      Action: 'UPDATE',
   });
   if (res?.error) {
      stateToas(
         `Ocurrio un error al intentar actualizar la categoría: ${res.message}`,
         'error'
      );
      return;
   }
};

export const deleteTaskCategories = async (id) => {
   const res = await req.delete(`costos/tareas/categoria/${id}`);
   if (res?.error) {
      stateToas(
         `Ocurrio un error al intentar eliminar la categoria: ${res.message}`,
         'error'
      );
      return;
   }
};

export const getProviders = async () => {
   const response = await req.get('costos/proveedores');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const getProviderModalProps = async () => {
   const categories = await getTaskCategories();
   return {
      categories,
   };
};

export const createProviders = async (data) =>
   await req.post('costos/proveedores', {
      Action: 'INSERT',
      Proveedor: data.name,
      Observaciones: data.comments,
      IdEstado: data.statusId || 3,
      Tasks: JSON.stringify(data.Tasks),
   });

export const updateProviders = async (data) =>
   await req.put(
      `costos/proveedores?${new URLSearchParams({
         Action: 'UPDATE',
         IdProveedor: data.id,
         Proveedor: data.name,
         Observaciones: data.comments,
         IdEstado: data.statusId || 3,
         Tasks: JSON.stringify(data.Tasks),
      })}`
   );

export const deleteProviders = async (id) => await req.delete(`costos/proveedores/${id}`);

export const getPurchases = async () => {
   const response = await req.get('costos/compras');
   if (response.error || response.empty) {
      return [];
   }
   const formattedPurchases = response.map((purchase) => ({
      ...purchase,
      materials: JSON.parse(purchase.materials),
   }));
   return formattedPurchases;
};

export const createPurchases = async (data) => {
   await req.post('costos/compras', {
      Folio: data.invoice,
      Fecha: moment(data.date, 'DD/-MM/YYYY').format('YYYY-MM-DD'),
      Proveedor: data.provider,
      Comentarios: data.comments,
      Subtotal: data.subtotal,
      Iva: data.iva,
      Total: data.total,
      Materials: JSON.stringify(data.materials),
   });
};

export const updatePurchases = async (data) => {
   const {
      id,
      invoice,
      date,
      provider,
      comments,
      /*, subtotal, iva, total, */
      materials,
   } = data;
   const newSubtotal = materials.reduce(
      (acc, material) => acc + material.quantity * material.price,
      0
   );

   await req.put(
      `costos/compras?${new URLSearchParams({
         id,
         invoice,
         date: moment(date, 'DD/-MM/YYYY').format('YYYY-MM-DD'),
         provider,
         comments,
         subtotal: newSubtotal,
         iva: newSubtotal * 0.16,
         total: newSubtotal * 1.16,
         materials: JSON.stringify(materials),
      })}`
   );
};

export const deletePurchases = async (id) => {
   await req.delete(`costos/compras/${id}`);
};

// Tareas

export const getTasksList = async () => {
   const response = await req.get('costos/tareas');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const createTask = async (data) => {
   await req.post('costos/tareas', {
      Nombre: data.name,
      Descripcion: data.description,
      IdEstado: data.id_status,
      IdCategoria: data.category?.id || null,
      Materials: JSON.stringify(data.materials),
      PersonalCategories: JSON.stringify(data.personal_categories),
      Action: 'INSERT',
   });
};

export const updateTask = async (data) => {
   await req.put(
      `costos/tareas?${new URLSearchParams({
         id: data.id,
         name: data.name,
         description: data.description,
         categoryId: data.category.id,
         idStatus: data.id_status,
         materials: JSON.stringify(data.materials),
         personalCategories: JSON.stringify(data.personal_categories),
      })}`
   );
};

export const deleteTask = async (id) => {
   await req.delete(`costos/tareas/${id}`);
};

export const getTasksDetailsList = async () => {
   const response = await req.get('costos/tareas/detalles');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

// Ordenes de servicios

export const getOrdersList = async () => {
   const response = await req.get('costos/ordenes');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const createOrder = async (data) => {
   await req.post('costos/ordenes', {
      IdSolicitud: data?.solicitud?.IdSolicitud || data.solicitud.id,
      Descripcion: data.description,
      IdTipoServicio: data.type_of_service.id,
      IdProvider: data?.provider?.id || null,
      IdEstado: data.status.id,
      Comentarios: data.comments,
      Tarea: JSON.stringify(data.tasks),
      Personal: JSON.stringify(data?.staff || []),
      Materiales: JSON.stringify(data?.materials || []),
   });
};

export const updateOrder = async (data) => {
   await req.patch('costos/ordenes', {
      IdOs: Number(data.id),
      Descripcion: data.description,
      IdTipoServicio: data.type_of_service.id,
      IdProvider: data?.provider?.id || null,
      IdEstado: data.status.id,
      Comentarios: data.comments,
      Tarea: JSON.stringify(
         data.tasks.map((task) => {
            return {
               id: Number(task.id),
            };
         })
      ),
      Personal: JSON.stringify(data?.staff || []),
      Materiales: JSON.stringify(data?.materials || []),
   });
};

export const deleteOrder = async (id) => {
   await req.delete(`costos/ordenes/${id}`);
};

export const getOrdersDetailsList = async () => {
   const response = await req.get('costos/ordenes/detalles');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const getOrdersByRequest = async (id) => {
   const response = await req.get(`solicitud/${id}/service-orders/count`);
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const getOrdersFilters = async () => {
   const response = await req.get('costos/ordenes/filtros');
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

export const getTasksByProvider = async (id) => {
   const response = await req.get(`costos/tareas/proveedor/${id}`);
   if (response.error || response.empty) {
      return [];
   }
   return response;
};

import React from 'react';
import Routes from './routes';

const View = () => (
   <React.Fragment>
      <Routes></Routes>
   </React.Fragment>
);

export default View;

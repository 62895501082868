import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Paginator from '../../Paginator';

const ModalTipoUnidad = props => {
   const [tipoUnidad, setTipoUnidad] = useState({}); // json con todo
   const [areas, setAreas] = useState([]); // catalogo areas
   const [equipos, setEquipos] = useState([]); // catalogos equipos
   const [newArea, setNewArea] = useState(''); // select para nuevas areas
   const [newEquipo, setNewEquipo] = useState(''); // select para nuevos equipos
   const [selectedArea, setSelectedArea] = useState(0); // area para ver detalle
   const [page, setPage] = useState(1); // pagina

   useEffect(() => {
      setAreas(props.areas);
      setEquipos(props.equipos);
   }, [props.areas, props.equipos]);

   useEffect(() => {
      setTipoUnidad(props.tipoUnidad);
   }, [props.tipoUnidad]);

   async function selectPage(page) {
      setPage(page);
   }

   function updateProp(tip) {
      props.updateJson(tip);
   }

   function addArea() {
      if (newArea !== '') {
         if (!tipoUnidad.TipoUnidadAreas) {
            tipoUnidad.TipoUnidadAreas = [];
         }

         const areasList = tipoUnidad.TipoUnidadAreas;

         areasList.push({
            IdArea: newArea,
            IdTipoUnidadArea:
               Math.ceil(Math.random() * 100) * Math.ceil(Math.random() * 100),
            LineaNueva: 1,
         });

         updateProp({
            tipoUnidad: {
               ...tipoUnidad,
               TipoUnidadAreas: areasList,
            },
         });

         setNewArea('');
      }
   }

   function deleteArea(area) {
      let areasList = tipoUnidad.TipoUnidadAreas;

      areasList = areasList.filter(
         x => x.IdTipoUnidadArea !== area.IdTipoUnidadArea
      );

      updateProp({
         tipoUnidad: {
            ...tipoUnidad,
            TipoUnidadAreas: areasList,
         },
      });
   }

   function addEquipo() {
      if (newEquipo !== '') {
         if (!tipoUnidad.TipoUnidadEquipos) {
            tipoUnidad.TipoUnidadEquipos = [];
         }

         const eqList = tipoUnidad.TipoUnidadEquipos;

         eqList.push({
            IdTipoUnidadEquipo:
               Math.ceil(Math.random() * 100) * Math.ceil(Math.random() * 100),
            IdTipoUnidadArea: selectedArea,
            IdEquipo: newEquipo,
            LineaNueva: 1,
         });

         updateProp({
            tipoUnidad: {
               ...tipoUnidad,
               TipoUnidadEquipos: eqList,
            },
         });

         setNewEquipo('');
      }
   }

   function deleteEquipo(equipo) {
      let eqList = tipoUnidad.TipoUnidadEquipos;

      eqList = eqList.filter(
         x => x.IdTipoUnidadEquipo !== equipo.IdTipoUnidadEquipo
      );

      updateProp({
         tipoUnidad: {
            ...tipoUnidad,
            TipoUnidadEquipos: eqList,
         },
      });
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-16"></div>
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Nombre:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={tipoUnidad ? tipoUnidad.Nombre : ''}
                     onChange={event => {
                        let tip = tipoUnidad;
                        tip.Nombre = event.target.value;
                        updateProp({
                           tipoUnidad: tip,
                        });
                     }}
                     autoComplete="off"
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Observaciones:</b>
                  </p>
               </div>
               <div className="column full">
                  <textarea
                     className="input"
                     value={tipoUnidad ? tipoUnidad.Observaciones : ''}
                     onChange={event => {
                        let tip = tipoUnidad;
                        tip.Observaciones = event.target.value;
                        updateProp({
                           tipoUnidad: tip,
                        });
                     }}
                     required
                  ></textarea>
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Metros:</b>
                  </p>
               </div>
               <div className="column column-small">
                  <input
                     type="text"
                     className="input input-modals"
                     value={tipoUnidad ? tipoUnidad.Metros : ''}
                     onChange={event => {
                        let tip = tipoUnidad;
                        tip.Metros = event.target.value;
                        updateProp({
                           tipoUnidad: tip,
                        });
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
               <div className="column label-middle">
                  <p>
                     <b>Plano:</b>
                  </p>
               </div>
               <div className="column column-small">
                  <input
                     type="text"
                     className="input input-modals"
                     value={tipoUnidad ? tipoUnidad.Plano : ''}
                     onChange={event => {
                        let tip = tipoUnidad;
                        tip.Plano = event.target.value;
                        updateProp({
                           tipoUnidad: tip,
                        });
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  {selectedArea === 0 ? (
                     <p>
                        <b>Nueva área:</b>
                     </p>
                  ) : (
                     <p>
                        <b>Nuevo equipo:</b>
                     </p>
                  )}
               </div>
               <div className="column column-small">
                  <div className="row align-center">
                     {selectedArea === 0 ? (
                        <React.Fragment>
                           <select
                              className="input input-modals"
                              value={newArea}
                              onChange={event => {
                                 setNewArea(event.target.value);
                              }}
                              disabled={selectedArea !== 0}
                           >
                              <option value="" disabled>
                                 Elija un áreas
                              </option>
                              {areas.map((ar, key) => (
                                 <option key={key} value={ar.IdArea}>
                                    {ar.NombreArea}
                                 </option>
                              ))}
                           </select>
                           &nbsp; &nbsp;
                           <Link
                              to="#"
                              className="color-black"
                              onClick={() => {
                                 addArea();
                              }}
                           >
                              <i className="fas fa-plus-circle"></i>
                           </Link>
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <select
                              className="input input-modals"
                              value={newEquipo}
                              onChange={event => {
                                 setNewEquipo(event.target.value);
                              }}
                              disabled={selectedArea === 0}
                           >
                              <option value="" disabled>
                                 Elija un equipo
                              </option>
                              {equipos.map((eq, key) => (
                                 <option key={key} value={eq.IdEquipo}>
                                    {eq.NombreEquipo}
                                 </option>
                              ))}
                           </select>
                           &nbsp; &nbsp;
                           <Link
                              to="#"
                              className="color-black"
                              onClick={() => {
                                 addEquipo();
                              }}
                           >
                              <i className="fas fa-plus-circle"></i>
                           </Link>
                        </React.Fragment>
                     )}
                  </div>
               </div>
               <div className="column label-middle">
                  {selectedArea === 0 ? null : (
                     <Link
                        to="#"
                        className="color-black weight-bold"
                        onClick={() => {
                           setPage(1);
                           setSelectedArea(0);
                        }}
                     >
                        <i className="fas fa-arrow-left"></i>
                        &nbsp;Áreas
                     </Link>
                  )}
               </div>
               <div className="column column-small">
                  <div className="row justify-center align-center">
                     <div className="white-space-8"></div>
                  </div>
               </div>
            </div>
            <div className="white-space-16" />
            <div className="row full align-center">
               <div className="column card-table full align-center card-table-modal">
                  <div className="table-responsive full">
                     {selectedArea === 0 ? (
                        <table>
                           <thead>
                              <tr>
                                 <th className="text-left">Área</th>
                                 <th className="text-center th-lg">Equipos</th>
                                 <th className="text-center th-lg">Eliminar</th>
                              </tr>
                           </thead>
                           <tbody>
                              {(tipoUnidad.TipoUnidadAreas
                                 ? tipoUnidad.TipoUnidadAreas.length
                                 : 0) === 0 ? (
                                 <tr>
                                    <td className="text-center" colSpan="3">
                                       Sin datos que mostrar
                                    </td>
                                 </tr>
                              ) : (
                                 tipoUnidad.TipoUnidadAreas.slice(
                                    (page - 1) * 5,
                                    page * 5
                                 ).map((area, key) => (
                                    <tr key={key}>
                                       <td className="text-left">
                                          <select
                                             className="input input-modals full"
                                             value={area.IdArea}
                                             onChange={event => {
                                                let array =
                                                   tipoUnidad.TipoUnidadAreas.map(
                                                      arChange => {
                                                         if (
                                                            arChange.IdTipoUnidadArea ===
                                                            area.IdTipoUnidadArea
                                                         ) {
                                                            arChange.IdArea =
                                                               event.target.value;
                                                         }
                                                         return arChange;
                                                      }
                                                   );
                                                updateProp({
                                                   tipoUnidad: {
                                                      ...tipoUnidad,
                                                      TipoUnidadAreas: array,
                                                   },
                                                });
                                             }}
                                          >
                                             {areas.map((ar, key) => (
                                                <option
                                                   key={key}
                                                   value={ar.IdArea}
                                                >
                                                   {ar.NombreArea}
                                                </option>
                                             ))}
                                          </select>
                                       </td>
                                       <td className="text-center">
                                          <button
                                             className=" btn-small btn-empty"
                                             type="button"
                                             onClick={() => {
                                                setPage(1);
                                                setSelectedArea(
                                                   area.IdTipoUnidadArea
                                                );
                                             }}
                                          >
                                             <i className="fas fa-chevron-circle-right"></i>
                                          </button>
                                       </td>
                                       <td className="text-center">
                                          <button
                                             className=" btn-small btn-empty"
                                             type="button"
                                             onClick={() => {
                                                deleteArea(area);
                                             }}
                                          >
                                             <i className="font-small fas fa-trash-alt" />
                                          </button>
                                       </td>
                                    </tr>
                                 ))
                              )}
                           </tbody>
                        </table>
                     ) : (
                        <table>
                           <thead>
                              <tr>
                                 <th className="text-left">Equipo</th>
                                 <th className="text-center th-lg">Eliminar</th>
                              </tr>
                           </thead>
                           <tbody>
                              {(tipoUnidad.TipoUnidadEquipos
                                 ? tipoUnidad.TipoUnidadEquipos.length
                                 : 0) === 0 ? (
                                 <tr>
                                    <td className="text-center" colSpan="2">
                                       Sin datos que mostrar
                                    </td>
                                 </tr>
                              ) : (
                                 tipoUnidad.TipoUnidadEquipos.filter(
                                    eq => eq.IdTipoUnidadArea === selectedArea
                                 )
                                    .slice((page - 1) * 5, page * 5)
                                    .map((equipo, key) => (
                                       <tr key={key}>
                                          <td className="text-left">
                                             <select
                                                className="input input-modals full"
                                                value={equipo.IdEquipo}
                                                onChange={event => {
                                                   let array =
                                                      tipoUnidad.TipoUnidadEquipos.map(
                                                         eqChange => {
                                                            if (
                                                               eqChange.IdTipoUnidadEquipo ===
                                                               equipo.IdTipoUnidadEquipo
                                                            ) {
                                                               eqChange.IdEquipo =
                                                                  event.target.value;
                                                            }
                                                            return eqChange;
                                                         }
                                                      );
                                                   updateProp({
                                                      tipoUnidad: {
                                                         ...tipoUnidad,
                                                         TipoUnidadEquipos:
                                                            array,
                                                      },
                                                   });
                                                }}
                                             >
                                                {equipos.map((eq, key) => (
                                                   <option
                                                      key={key}
                                                      value={eq.IdEquipo}
                                                   >
                                                      {eq.NombreEquipo}
                                                   </option>
                                                ))}
                                             </select>
                                          </td>
                                          <td className="text-center">
                                             <button
                                                className=" btn-small btn-empty"
                                                type="button"
                                                onClick={() => {
                                                   deleteEquipo(equipo);
                                                }}
                                             >
                                                <i className="font-small fas fa-trash-alt" />
                                             </button>
                                          </td>
                                       </tr>
                                    ))
                              )}
                           </tbody>
                        </table>
                     )}
                  </div>
               </div>
            </div>
            <div className="row justify-center align-center">
               <Paginator
                  pages={Math.ceil(
                     (selectedArea === 0
                        ? tipoUnidad.TipoUnidadAreas
                           ? tipoUnidad.TipoUnidadAreas.length
                           : 0
                        : tipoUnidad.TipoUnidadEquipos
                        ? tipoUnidad.TipoUnidadEquipos.filter(
                             eq => eq.IdTipoUnidadArea === selectedArea
                          ).length
                        : 0) / 5
                  )}
                  setPage={selectPage.bind(this)}
               />
            </div>
         </div>
      </div>
   );
};

export default ModalTipoUnidad;

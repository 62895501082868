import React, { useEffect, useState } from 'react';

import ItemInput from './../ItemInput';
import ItemOptions from './../ItemOptions';
import ItemCheckbox from '../ItemCheckbox';
import { costsControlApi } from '../../../../api';

const MaterialDetails = (props) => {
   const { item, onEditItem, extraProps } = props;
   const [categories, setCategories] = useState([]);
   const [isLoadingCategories, setIsLoadingCateogires] = useState(true);

   const isItemActive = item.id_status === 1;

   const fetchCategories = async () => {
      const items = await costsControlApi.getMaterialsCategories(
         item.type_of_material?.id || extraProps.type_of_material
      );
      setCategories(items);
   };

   useEffect(() => {
      fetchCategories();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (!categories || categories.length === 0) return;
      setIsLoadingCateogires(false);
   }, [categories]);

   return (
      <>
         <ItemInput
            value={item.name}
            label="Nombre"
            onValueUpdate={onEditItem}
            attributeToChange="name"
         />
         <ItemInput
            value={item.code}
            label="Código"
            onValueUpdate={onEditItem}
            attributeToChange="code"
         />
         <ItemOptions
            label="Categoría"
            options={categories}
            select={item.category_of_material}
            onChange={onEditItem}
            attributeToChange="category_of_material"
            isLoading={isLoadingCategories}
         />
         <ItemInput
            value={item.quantity}
            type="number"
            label="Cantidad"
            onValueUpdate={onEditItem}
            attributeToChange="quantity"
         />
         <ItemInput
            value={item.price}
            type="number"
            label="Precio"
            onValueUpdate={onEditItem}
            attributeToChange="price"
         />
         <ItemInput
            value={item.max}
            type="number"
            label="Max Inventario"
            onValueUpdate={onEditItem}
            attributeToChange="max"
         />
         <ItemInput
            value={item.min}
            type="number"
            label="Min Inventario"
            onValueUpdate={onEditItem}
            attributeToChange="min"
         />
         <div className="white-space-16" />
         <ItemCheckbox
            label="Activo"
            checked={isItemActive}
            onValueUpdate={onEditItem}
            attributeToChange="id_status"
         />
         <ItemInput
            value={item.comments}
            label="Comentarios"
            onValueUpdate={onEditItem}
            attributeToChange="comments"
         />
      </>
   );
};

export default MaterialDetails;

import React, { useEffect, useState } from 'react';

const TablePerfiles = props => {
   const [perfiles, setPerfiles] = useState([]);
   const [loadingPerfiles, setLoadingPerfiles] = useState(false);

   useEffect(() => {
      setPerfiles(props.perfiles);
      setLoadingPerfiles(props.loadingPerfiles);
   }, [props.perfiles, props.loadingPerfiles]);

   return (
      <div className="table-responsive full">
         <table>
            <thead>
               <tr>
                  <th className="text-left">Perfil</th>
                  <th className="text-center th-lg"></th>
                  <th className="text-center th-lg"></th>
               </tr>
            </thead>
            <tbody>
               {loadingPerfiles ? (
                  <tr>
                     <td className="text-center" colSpan="3">
                        <i className="fas fa-spinner fa-spin"></i>
                     </td>
                  </tr>
               ) : perfiles ? (
                  perfiles.length === 0 ? (
                     <tr>
                        <td className="text-center" colSpan="3">
                           Sin datos que mostrar
                        </td>
                     </tr>
                  ) : (
                     perfiles
                        .slice((props.page - 1) * 15, props.page * 15)
                        .map((perfil, key) => (
                           <tr key={key}>
                              <td>{perfil.NombrePerfil}</td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.setPerfil(perfil);
                                    }}
                                 >
                                    <i className="font-small fas fa-edit" />
                                 </button>
                              </td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.setPerfilDelete(perfil);
                                    }}
                                 >
                                    <i className="font-small fas fa-trash-alt" />
                                 </button>
                              </td>
                           </tr>
                        ))
                  )
               ) : null}
            </tbody>
         </table>
      </div>
   );
};
export default TablePerfiles;

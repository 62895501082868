import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

const TableHistorial = (props) => {
   const [solicitudes, setSolicitudes] = useState([]);
   const [loadingSolicitudes, setLoadingSolicitudes] = useState(false);

   useEffect(() => {
      setSolicitudes(props.solicitudes);
   }, [props.solicitudes]);

   useEffect(() => {
      setLoadingSolicitudes(props.loadingSolicitudes);
   }, [props.loadingSolicitudes]);

   return (
      <div className="table-responsive full">
         <table>
            <thead>
               <tr>
                  <th className="text-center">No Solicitud</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Fecha Visita</th>
                  <th className="text-center">Finalizada</th>
                  <th className="text-center">Proyecto</th>
                  <th className="text-center">Unidad</th>
                  <th className="text-center th-md"></th>
               </tr>
            </thead>
            <tbody>
               {loadingSolicitudes ? (
                  <tr>
                     <td className="text-center" colSpan="7">
                        <i className="fas fa-spinner fa-spin"></i>
                     </td>
                  </tr>
               ) : solicitudes ? (
                  solicitudes.length === 0 ? (
                     <tr>
                        <td className="text-center" colSpan="7">
                           Sin datos que mostrar
                        </td>
                     </tr>
                  ) : (
                     solicitudes
                        .slice((props.page - 1) * 15, props.page * 15)
                        .map((solicitud, key) => (
                           <tr key={key}>
                              <td className="text-center">{solicitud.NoSolicitud}</td>
                              <td className="text-center">{solicitud.Estado}</td>
                              <td className="text-center">
                                 {solicitud.FechaVisita
                                    ? moment(solicitud.FechaVisita).format(
                                         'DD-MM-YYYY HH:mm'
                                      )
                                    : null}
                              </td>
                              <td className="text-center">
                                 {solicitud.FechaFinaliza
                                    ? moment(solicitud.FechaFinaliza).format(
                                         'DD-MM-YYYY HH:mm'
                                      )
                                    : null}
                              </td>
                              <td className="text-center">{solicitud.NombreProyecto}</td>
                              <td className="text-center">{solicitud.Numero}</td>
                              <td className="text-center">
                                 <button
                                    className=" btn-small btn-empty"
                                    type="button"
                                    onClick={() => {
                                       props.setSolicitud(solicitud);
                                    }}>
                                    <i className="font-small fas fa-eye" />
                                 </button>
                              </td>
                           </tr>
                        ))
                  )
               ) : null}
            </tbody>
         </table>
      </div>
   );
};
export default TableHistorial;

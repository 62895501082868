import React from 'react';

const ItemInput = (props) => {
   const {
      value = '',
      label,
      type,
      onValueUpdate,
      attributeToChange,
      disabled = false,
   } = props;
   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-16" />

            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>{`${label}: `}</b>
                  </p>
               </div>

               <div className="column full">
                  <input
                     disabled={disabled}
                     type={type || 'text'}
                     className="input input-modals"
                     value={value || ''}
                     onChange={(event) =>
                        onValueUpdate(
                           type === 'number'
                              ? parseFloat(event.target.value)
                              : event.target.value,
                           attributeToChange
                        )
                     }
                     autoComplete="off"
                     minLength={2}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
export default ItemInput;

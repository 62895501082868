import React, { useEffect } from 'react';
import ItemInput from '../ItemInput';
import ItemCheckbox from '../ItemCheckbox';

const StaffCategoriesDetails = props => {
   const { item, onEditItem } = props;
   const isItemActive = item.id_status === 1;

   useEffect(() => {
      if (!item) onEditItem(1, 'id_status');
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <ItemInput
            value={item.name}
            label="Nombre"
            onValueUpdate={onEditItem}
            attributeToChange="name"
         />
         <div className="white-space-16" />
         <ItemCheckbox
            label="Activo"
            checked={isItemActive}
            onValueUpdate={onEditItem}
            attributeToChange="id_status"
         />
      </>
   );
};

export default StaffCategoriesDetails;

// node_module
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import cogoToast from 'cogo-toast';
import WOW from 'wowjs';
// generales
import { Consumer } from '../context';
import Request from '../core/httpClient';
import DinamicModal from '../components/modal/DinamicModal';
import Controls from '../components/Controls';
import Sidebar from '../components/Sidebar';
import Paginator from '../components/Paginator';
// componentes
import TableNotificaciones from '../components/notificaciones/tables/tableNotificaciones';
import ModalNotificacion from '../components/notificaciones/modal/modalNotificacion';
import ModalHistorialEnvio from '../components/notificaciones/modal/historialEnvio';

const req = new Request();

class SolicitudesAbiertas extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 1,
         notificaciones: [],
         loadingNotificaciones: false,
         modalName: 'modalNotificacion',
         savingModal: false,
         newMode: true,
         notificacion: {},
         saveModal: this.createNotificacion.bind(this),
         loadingProyectos: false,
         loadingUnidades: false,
         proyectos: [],
         unidades: [],
         personas: [],
         logNotificaciones: [],
         loadingLog: false,
      };
   }

   async componentDidMount() {
      new WOW.WOW({
         live: false,
      }).init();
      this.loadNotificaciones();
      this.loadUnidades();
      this.loadProyectos();
      this.loadPropietarios();
   }

   // load

   async loadNotificaciones() {
      this.setState({
         loadingNotificaciones: true,
      });

      const requestApi = await req.get('notificaciones/get', {});
      if (!requestApi.empty && !requestApi.error) {
         this.setState({
            notificaciones: requestApi.data,
         });
      } else {
         this.setState({
            notificaciones: [],
         });
      }
      this.setState({
         loadingNotificaciones: false,
      });
   }

   async loadProyectos() {
      this.setState({
         loadingProyectos: true,
      });
      const response = await req.get('proyectos/get', {});
      if (!response.empty && !response.error) {
         this.setState({
            proyectos: response.data,
         });
      } else {
         this.setState({
            proyectos: [],
         });
      }
      this.setState({
         loadingProyectos: false,
      });
   }

   async loadUnidades() {
      this.setState({
         loadingUnidades: true,
      });
      const response = await req.get('proyectos/get/all/unidades', {});
      if (!response.empty && !response.error) {
         this.setState({
            unidades: response.data,
         });
      } else {
         this.setState({
            unidades: [],
         });
      }
      this.setState({
         loadingUnidades: false,
      });
   }

   async loadPropietarios() {
      this.setState({
         loadingPersonas: true,
      });
      const apiRequest = await req.get('propietarios/get/all', {});

      if (!apiRequest.error) {
         this.setState({
            personas: apiRequest.data,
         });
      } else {
         this.setState({
            personas: [],
         });
      }
      this.setState({
         loadingPersonas: false,
      });
   }

   async loadLogNotificaciones() {
      this.setState({
         loadingLog: true,
      });
      let notificacion = this.state.notificacion;
      let data = {
         IdNotificacion: notificacion.IdNotificacion,
      };

      let response = await req.get(
         'aplicacion/notificaciones/get/history',
         data
      );
      if (!response.error) {
         this.setState({
            logNotificaciones: response.data ? response.data : [],
         });
      } else {
         this.setState({
            logNotificaciones: [],
         });
      }
      this.setState({
         loadingLog: false,
      });
   }

   // generales

   async selectPage(page) {
      this.setState({ page: page });
   }

   async openModal(modalName) {
      let modal = document.getElementById(modalName || this.state.modalName);

      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async closeModal() {
      let modal = document.getElementById(this.state.modalName);
      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   async updateJson(json) {
      this.setState(json);
   }

   // notificaciones

   async openNewModal() {
      await this.setState({
         notificacion: {},
         saveModal: this.createNotificacion.bind(this),
         modalName: 'modalNotificacion',
      });
      this.openModal();
   }

   async setNotificacionHistory(ntc) {
      await this.setState({
         notificacion: {
            IdNotificacion: ntc.IdNotificacion,
            Mensaje: ntc.Mensaje,
            Status: ntc.Status,
         },
         saveModal: () => {},
         modalName: 'modalHistorial',
      });
      this.loadLogNotificaciones();
      this.openModal();
   }

   async sendNotificacion(ntc) {
      await this.setState({
         notificacion: {
            IdNotificacion: ntc.IdNotificacion,
            Mensaje: ntc.Mensaje,
            Status: ntc.Status,
         },
         saveModal: this.enviarNotificacion.bind(this),
         modalName: 'modalNotificacion',
      });
      this.openModal();
   }

   async createNotificacion(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let notificacion = this.state.notificacion;
      let data = {
         IdPersona: notificacion.IdPersona ? notificacion.IdPersona : 0,
         IdProyecto: notificacion.IdProyecto ? notificacion.IdProyecto : 0,
         IdUnidad: notificacion.IdUnidad ? notificacion.IdUnidad : 0,
         Mensaje: notificacion.Mensaje,
      };
      const requestApi = await req.post(
         'aplicacion/notificaciones/create',
         data
      );

      if (requestApi.created) {
         cogoToast.success('Notificación enviada con éxito.', {
            position: 'top-right',
         });
         this.loadNotificaciones();
         this.closeModal();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }

      this.setState({
         savingModal: false,
      });
   }

   async updateNotificacion(event) {
      event.preventDefault();
      this.setState({
         savingModal: true,
      });
      let notificacion = this.state.notificacion;
      let data = {
         IdNotificacion: notificacion.IdNotificacion,
         Mensaje: notificacion.Mensaje,
      };
      const requestApi = await req.post('notificaciones/update', data);
      if (requestApi.response) {
         cogoToast.success('Solicitud actualizada con éxito.', {
            position: 'top-right',
         });
         this.loadNotificaciones();
         this.closeModal();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }

      this.setState({
         savingModal: false,
      });
   }

   async enviarNotificacion(event) {
      event.preventDefault();

      this.setState({
         savingModal: true,
      });

      let notificacion = this.state.notificacion;
      const data = {
         IdNotificacion: notificacion.IdNotificacion,
         IdPersona: notificacion.IdPersona ? notificacion.IdPersona : 0,
         IdProyecto: notificacion.IdProyecto ? notificacion.IdProyecto : 0,
         IdUnidad: notificacion.IdUnidad ? notificacion.IdUnidad : 0,
         Mensaje: notificacion.Mensaje,
      };

      const requestApi = await req.post(
         'aplicacion/notificaciones/enviar',
         data
      );

      if (requestApi.error) {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });

         this.setState({
            savingModal: false,
         });

         return;
      }

      if (requestApi.completed) {
         cogoToast.success('Notificación enviada con éxito.', {
            position: 'top-right',
         });
         this.closeModal();
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }

      this.setState({
         savingModal: false,
      });
   }

   render() {
      return (
         <div className="column justify-center align-center proyectos">
            <Helmet>
               <title>ELEVA Capital Group | Notificaciones</title>
            </Helmet>

            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>
                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Notificaciones</h3>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls
                              newHandler={this.openNewModal.bind(this)}
                              showFilter={true}
                           ></Controls>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center wow fadeIn">
                           <div className="container">
                              <div className="column card-table full align-center">
                                 <TableNotificaciones
                                    loadingNotificaciones={
                                       this.state.loadingNotificaciones
                                    }
                                    notificaciones={this.state.notificaciones}
                                    page={this.state.page}
                                    setNotificacionHistory={this.setNotificacionHistory.bind(
                                       this
                                    )}
                                    sendNotificacion={this.sendNotificacion.bind(
                                       this
                                    )}
                                 />
                                 <div className="row">
                                    <div className="white-space-16"></div>
                                 </div>
                                 <div className="row justify-center align-center">
                                    <Paginator
                                       pages={Math.ceil(
                                          this.state.notificaciones.length / 15
                                       )}
                                       setPage={this.selectPage.bind(this)}
                                    />
                                 </div>
                                 <div className="row">
                                    <div className="white-space-16"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <DinamicModal
               idModal={'modalNotificacion'}
               sizeModalContainer={'mini'}
               title={'Enviar Notificación'}
               btnTextSuccess={'Enviar'}
               success={this.state.saveModal}
               showBtnSuccess={true}
               btnTextCancel={'Cerrar'}
               showBtnCancel={true}
               loadingSave={this.state.savingModal}
            >
               <ModalNotificacion
                  updateJson={this.updateJson.bind(this)}
                  notificacion={this.state.notificacion}
                  proyectos={this.state.proyectos}
                  unidades={this.state.unidades}
                  personas={this.state.personas}
               />
            </DinamicModal>
            <DinamicModal
               idModal={'modalHistorial'}
               sizeModalContainer={'normal'}
               title={'Historial Notificaciones'}
               btnTextSuccess={'Enviar'}
               success={this.state.saveModal}
               showBtnSuccess={true}
               btnTextCancel={'Cerrar'}
               showBtnCancel={true}
               loadingSave={this.state.savingModal}
            >
               <ModalHistorialEnvio
                  logNotificaciones={this.state.logNotificaciones}
                  loadingLog={this.state.loadingLog}
               />
            </DinamicModal>
         </div>
      );
   }
}
export default withRouter(Consumer(SolicitudesAbiertas));

import React from 'react';
import { formatMoney } from '../../../utils/formatMoney';

const ItemTable = (props) => {
   const { item, onEditClick, onDeleteClick, itemProperties } = props;

   const isPrice = (property) => {
      return property.toLowerCase() === 'total';
   };

   const getNestedProperty = (obj, propertyString) => {
      const properties = propertyString.split('.');
      return properties.reduce((accumulator, currentProperty) => {
         return accumulator && accumulator[currentProperty]
            ? accumulator[currentProperty]
            : null;
      }, obj);
   };
   return (
      <tr>
         {itemProperties ? (
            itemProperties.map((property, index) => (
               
               <td
                  className={isPrice(property) ? 'text-right' : 'text-left'}
                  key={property + index}>
                  {isPrice(property)
                     ? formatMoney(getNestedProperty(item, property))
                     : getNestedProperty(item, property)}
               </td>

            ))
         ) : (
            <td>{item.name}</td>
         )}

         <td className="text-center">
            <button className="btn-small btn-empty" onClick={onEditClick}>
               <i className="font-small fas fa-edit" />
            </button>
         </td>

         <td className="text-center">
            <button className="btn-small btn-empty" onClick={onDeleteClick}>
               <i className="font-small fas fa-trash-alt" />
            </button>
         </td>

      </tr>
   );
};

export default ItemTable;

import { costsControlApi } from '../api';
import MaterialCategoriesDetails from '../components/controlCostos/components/details/MaterialCategoriesDetails';
import MaterialDetails from '../components/controlCostos/components/details/MaterialDetails';
import ShoppingDetails from '../components/controlCostos/components/details/ShoppingDetails';
import StaffCategoriesDetails from '../components/controlCostos/components/details/StaffCategoriesDetails';
import { TaskCategoriesDetails } from '../components/controlCostos/components/details/TaskCategoriesDetails';
import StaffDetails from '../components/controlCostos/components/details/StaffDetails';
import TaskDetails from '../components/controlCostos/components/details/TaskDetails';
import ServiceOrder from '../components/controlCostos/components/details/ServiceOrder/ServiceOrder';
import ProvidersDetails from '../components/controlCostos/components/details/ProvidersDetails';

/*
Este objeto catalogs contiene información sobre varios catálogos de elementos que se pueden mostrar en una tabla de control de costos. Cada objeto dentro del array representa un catálogo y contiene las siguientes propiedades:

catalogName: nombre e identificador del catálogo
itemName: nombre singular del elemento del catálogo
label: etiqueta para mostrar en la tabla
detailsComponent: componente React para mostrar los detalles del elemento al agregar o editar
getItems: función asincrónica que devuelve los elementos del catálogo
createItem: función asincrónica para crear un nuevo elemento en el catálogo
saveItem: función asincrónica para guardar los cambios en un elemento existente en el catálogo
columns (opcional): array con los nombres de las columnas de la tabla
columnsProps (opcional): array con los nombres de las propiedades del elemento para mostrar en las columnas correspondientes

En caso de no pasar columns o columnsProps, la tabla mostrará por defecto el label como columna e item.name como prop
*/

const dinamicValidate = (item, properties) => {
   properties.forEach((prop) => {
      if (!item[prop]) return false;
   });
   return true;
};

const catalogs = [
   {
      catalogName: 'materials',
      itemName: 'material',
      label: 'Materiales',
      columns: ['Materiales', 'Inventario'],
      columnsProps: ['name', 'quantity'],
      detailsComponent: MaterialDetails,
      specificProperties: {
         type_of_material: 1,
      },
      getItems: async () =>
         await costsControlApi.getMaterialsAndTools().then((res) => res.materials),
      createItem: async (item) => await costsControlApi.createMaterials(item, 1),
      saveItem: async (item) => await costsControlApi.updateMaterials(item),
      deleteItem: async (id) => await costsControlApi.deleteMaterials(id),
      validateData: (item) => {
         return (
            item.code &&
            item.name &&
            item.category_of_material &&
            item.id_status &&
            item.max &&
            item.min
         );
      },
   },
   {
      catalogName: 'materialsCategories',
      itemName: 'categoría de material',
      label: 'Categorías Materiales',
      detailsComponent: MaterialCategoriesDetails,
      getItems: async () => await costsControlApi.getMaterialsCategories(1),
      createItem: async (item) =>
         await costsControlApi.createMaterialsCategories({
            Nombre: item.name,
            IdEstado: item.status || 1,
            IdTipoMaterial: item.type_of_material || 1,
            Action: 'INSERT',
         }),
      saveItem: async (item) => await costsControlApi.updateMaterialsCategories(item),
      deleteItem: async (id) => await costsControlApi.deleteMaterialsCategories(id),
      validateData: (item) => {
         return item.name;
      },
   },
   {
      catalogName: 'tools',
      itemName: 'herramienta',
      label: 'Herramientas',
      detailsComponent: MaterialDetails,
      specificProperties: {
         type_of_material: 2,
      },
      getItems: async () =>
         await costsControlApi.getMaterialsAndTools().then((res) => res.tools),
      createItem: async (item) => await costsControlApi.createMaterials(item, 2),
      saveItem: async (item) => await costsControlApi.updateMaterials(item),
      deleteItem: async (id) => await costsControlApi.deleteMaterials(id),
      validateData: (item) => {
         return (
            dinamicValidate(item, [
               'code',
               'name',
               'category_of_material',
               'id_status',
               'max',
               'min',
               'price',
            ]) &&
            typeof item.price === 'number' &&
            typeof item.max === 'number' &&
            typeof item.min === 'number'
         );
      },
   },
   {
      catalogName: 'toolsCategories',
      itemName: 'categoría de herramienta',
      label: 'Categorías Herramientas',
      detailsComponent: MaterialCategoriesDetails,
      getItems: async () => await costsControlApi.getMaterialsCategories(2),
      createItem: async (item) =>
         await costsControlApi.createMaterialsCategories({
            Nombre: item.name,
            IdEstado: item.status || 1,
            IdTipoMaterial: item.type_of_material || 2,
            Action: 'INSERT',
         }),
      saveItem: async (item) => await costsControlApi.updateMaterialsCategories(item),
      deleteItem: async (id) => await costsControlApi.deleteMaterialsCategories(id),
      validateData: (item) => {
         return item.name;
      },
   },
   {
      catalogName: 'providers',
      itemName: 'proveedor',
      label: 'Proveedores',
      detailsComponent: ProvidersDetails,
      getModalProps: async () => await costsControlApi.getProviderModalProps(),
      getItems: async () => await costsControlApi.getProviders(),
      createItem: async (item) => await costsControlApi.createProviders(item),
      saveItem: async (item) => await costsControlApi.updateProviders(item),
      deleteItem: async (id) => await costsControlApi.deleteProviders(id),
      validateData: (item) => dinamicValidate(item, ['name']),
   },
   {
      catalogName: 'staff',
      itemName: 'personal',
      label: 'Personal',
      detailsComponent: StaffDetails,
      getItems: async () => await costsControlApi.getStaff(),
      createItem: async (item) => await costsControlApi.createStaff(item),
      saveItem: async (item) => await costsControlApi.updateStaff(item),
      deleteItem: async (id) => await costsControlApi.deleteStaff(id),
      validateData: (item) => {
         return (
            item.name &&
            item.start_date &&
            item.start_date.length === 10 &&
            item.id_status &&
            item.cost &&
            item.region &&
            item.categories &&
            item.categories.length > 0
         );
      },
   },
   {
      catalogName: 'staffCategories',
      itemName: 'categoría de personal',
      label: 'Categorías Personal',
      detailsComponent: StaffCategoriesDetails,
      getItems: async () => await costsControlApi.getStaffCategories(),
      createItem: async (item) => await costsControlApi.createStaffCategories(item),
      saveItem: async (item) => await costsControlApi.updateStaffCategories(item),
      deleteItem: async (id) => await costsControlApi.deleteStaffCategories(id),
      validateData: (item) => item && item.name && item.name.length > 0,
   },
   {
      catalogName: 'taskCategories',
      itemName: 'categoría de tarea',
      label: 'Categorias Tareas',
      detailsComponent: TaskCategoriesDetails,
      getItems: async () => await costsControlApi.getTaskCategories(),
      createItem: async (item) => await costsControlApi.createTaskCategories(item),
      saveItem: async (item) => await costsControlApi.updateTaskCategories(item),
      deleteItem: async (id) => await costsControlApi.deleteTaskCategories(id),
      validateData: (item) => item && item.name && item.name.length > 0,
   },
   {
      catalogName: 'shopping',
      itemName: 'compra',
      label: 'Compras',
      columns: ['Fecha', 'Folio', 'Proveedor', 'Total'],
      columnsProps: ['date', 'invoice', 'provider', 'total'],
      detailsComponent: ShoppingDetails,
      getItems: async () => await costsControlApi.getPurchases(),
      createItem: async (item) => await costsControlApi.createPurchases(item),
      saveItem: async (item) => await costsControlApi.updatePurchases(item),
      deleteItem: async (id) => await costsControlApi.deletePurchases(id),
      validateData: (item) =>
         item.provider && item.invoice && item.materials && item.materials.length > 0,
   },
   {
      catalogName: 'tasks',
      itemName: 'tarea',
      label: 'Tareas',
      columns: ['Tarea', 'Categoria'],
      columnsProps: ['name', 'category.name'],
      detailsComponent: TaskDetails,
      getItems: async () => await costsControlApi.getTasksList(),
      createItem: async (item) => await costsControlApi.createTask(item),
      saveItem: async (item) => await costsControlApi.updateTask(item),
      deleteItem: async (id) => await costsControlApi.deleteTask(id),
      validateData: (item) =>
         item.name.length > 0 &&
         item.id_status &&
         item.materials.length > 0 &&
         item.personal_categories.length > 0,
   },
   {
      catalogName: 'serviceOrders',
      itemName: 'orden de servicio',
      label: 'Ordenes de servicio',
      detailsComponent: ServiceOrder,
      columns: [
         'No. OS',
         'No. solicitud',
         'Descripción',
         'Estado',
         'Fecha Inicio',
         'Fecha Fin',
         'Proyecto',
         'Unidad',
      ],
      columnsProps: [
         'id',
         'request',
         'description',
         'status.name',
         'start_date',
         'end_date',
         'project',
         'unity',
      ],
      getItems: async () => await costsControlApi.getOrdersList(),
      createItem: async (item) => await costsControlApi.createOrder(item),
      saveItem: async (item) => await costsControlApi.updateOrder(item),
      deleteItem: async (id) => await costsControlApi.deleteOrder(id),
      validateData: (item) =>
         (item.solicitud || item.id_request) &&
         dinamicValidate(item, ['description', 'type_of_service', 'status', 'tasks']) &&
         (item.type_of_service.id === 2
            ? item.provider !== null && item.provider !== undefined
            : true),
   },
];

const catalogTabs = catalogs
   .filter(
      (catalog) =>
         catalog.catalogName !== 'shopping' && catalog.catalogName !== 'serviceOrders'
   )
   .map((catalog) => ({
      catalogName: catalog.catalogName,
      label: catalog.label,
   }));

const catalogDetails = catalogs.reduce((acc, catalog) => {
   acc[catalog.catalogName] = {
      catalogName: catalog.catalogName,
      label: catalog.label,
      columns: catalog.columns,
      columnsProps: catalog.columnsProps,
      itemName: catalog.itemName,
      detailsComponent: catalog.detailsComponent,
      specificProperties: catalog.specificProperties,
      getModalProps: catalog.getModalProps,
      createItem: catalog.createItem,
      getItems: catalog.getItems,
      saveItem: catalog.saveItem,
      deleteItem: catalog.deleteItem,
      validateData: catalog.validateData,
   };
   return acc;
}, {});

export { catalogTabs, catalogDetails };

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';

import { Consumer } from '../../../context';
import Request from '../../../core/httpClient';
import Paginator from '../../Paginator';
import DinamicModal from '../../modal/DinamicModal';
import DeleteModal from '../../modal/DeleteModal';
import ModalCatalogo from '../modal/modalCatalogo';
import ModalEquipoDetalles from '../modal/modalEquipoDetalles';

const req = new Request();

const TableEquipos = (props) => {
   const [equipos, setEquipos] = useState([]);
   const [problemas, setProblemas] = useState([]);
   const [savingModal, setSavingModal] = useState(false);
   const [currentEquipo, setCurrentEquipo] = useState({});
   const [page, setPage] = useState(1);
   const [dateOpenModal, setDateOpenModal] = useState();

   useEffect(() => {
      setEquipos(props.equipos);
      setProblemas(props.problemas);
   }, [props.equipos, props.problemas]);

   async function selectPage(page) {
      setPage(page);
   }

   async function openModal(equipo, id) {
      let modal = document.getElementById(id);

      setCurrentEquipo(equipo);
      setDateOpenModal(moment());

      if (modal) {
         modal.classList.remove('hide-dinamic-modal');
         modal.classList.add('show-modal');
      }
   }

   async function updateEquipo(event) {
      event.preventDefault();
      setSavingModal(true);
      const requestApi = await req.post('config/update/equipo', currentEquipo);
      if (requestApi.response) {
         cogoToast.success('Equipo guardada con éxito.', {
            position: 'top-right',
         });
         closeModal('editEquipo');
         props.loadEquipos();
         setCurrentEquipo({});
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      setSavingModal(false);
   }

   async function deleteEquipo(event) {
      event.preventDefault();
      setSavingModal(true);
      let data = {
         IdEquipo: currentEquipo.IdEquipo,
      };
      const requestApi = await req.post('config/delete/equipo', data);
      if (requestApi.response) {
         cogoToast.success('Equipo eliminado con éxito.', {
            position: 'top-right',
         });
         closeModal('deleteModal');
         props.loadEquipos();
         setCurrentEquipo({});
      } else {
         cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
            position: 'top-right',
         });
      }
      setSavingModal(false);
   }

   async function closeModal(id) {
      let modal = document.getElementById(id);

      if (modal) {
         modal.classList.add('hide-dinamic-modal');
         modal.classList.remove('show-dinamic-modal');
      }
   }

   async function updateValue(value) {
      setCurrentEquipo({
         ...currentEquipo,
         ...value,
      });
   }

   return (
      <React.Fragment>
         {
            <div className="table-responsive full">
               <table>
                  <thead>
                     <tr>
                        <th className="text-left">Equipos</th>
                        <th className="text-center">Detalles</th>
                        <th className="text-center th-lg"></th>
                        <th className="text-center th-lg"></th>
                     </tr>
                  </thead>
                  <tbody>
                     {props.loadingEquipos ? (
                        <tr>
                           <td className="text-center" colSpan="3">
                              <i className="fas fa-spinner fa-spin"></i>
                           </td>
                        </tr>
                     ) : (
                        equipos.slice((page - 1) * 15, page * 15).map((equipo, key) => (
                           <tr key={key}>
                              <td>{equipo.NombreEquipo}</td>
                              <td className="text-center">
                                 <button
                                    type="button"
                                    className="btn-small btn-empty"
                                    onClick={() => {
                                       openModal(equipo, 'addEquipoDEtalle');
                                    }}>
                                    <i className="fas fa-tasks"></i>
                                 </button>
                              </td>
                              <td className="text-center">
                                 <button
                                    className="btn-small btn-empty"
                                    onClick={() => {
                                       openModal(equipo, 'editEquipo');
                                    }}>
                                    <i className="font-small fas fa-edit" />
                                 </button>
                              </td>
                              <td className="text-center">
                                 <button
                                    className="btn-small btn-empty"
                                    onClick={() => {
                                       openModal(equipo, 'deleteModal');
                                    }}>
                                    <i className="font-small fas fa-trash-alt" />
                                 </button>
                              </td>
                           </tr>
                        ))
                     )}
                  </tbody>
               </table>
            </div>
         }
         <div className="row">
            <div className="white-space-16"></div>
         </div>
         <div className="row justify-center align-center">
            <Paginator
               pages={Math.ceil(equipos.length / 15)}
               setPage={selectPage.bind(this)}
            />
         </div>
         <DinamicModal
            idModal={'addEquipoDEtalle'}
            sizeModalContainer={'small'}
            title={'Equipo Detalles'}>
            <ModalEquipoDetalles
               problemas={problemas}
               equipo={currentEquipo}
               dateOpenModal={dateOpenModal}
            />
         </DinamicModal>
         <DinamicModal
            idModal={'editEquipo'}
            sizeModalContainer={'mini'}
            title={'Editar equipo'}
            btnTextSuccess={'Guardar'}
            success={updateEquipo}
            showBtnSuccess={true}
            btnTextCancel={'Cancelar'}
            showBtnCancel={true}
            loadingSave={savingModal}>
            <ModalCatalogo
               value={{ ...currentEquipo }}
               updateValue={updateValue.bind(this)}
            />
         </DinamicModal>
         <DeleteModal
            id="deleteModal"
            title="Eliminar"
            message="¿Está seguro de eliminar el equipo?"
            acceptMethod={deleteEquipo}
            savingModal={savingModal}
         />
      </React.Fragment>
   );
};
export default Consumer(TableEquipos);

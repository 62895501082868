/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

const Controls = props => {
   const [showBack, setShowBack] = useState(false);
   const [showFilter, setShowFilter] = useState(true);
   const [backLabel, setBackLabel] = useState('');

   useEffect(() => {
      setShowBack(props.showBack);
   }, [props]);

   useEffect(() => {
      setBackLabel(props.backLabel);
   }, [props]);

   useEffect(() => {
      setShowFilter(props.showFilter === undefined ? true : props.showFilter);
   }, [props]);

   return (
      <div className="container column full">
         {showBack ? (
            <div className="justify-start align-center full">
               <button
                  className="btn btn-empty btn-small color-white"
                  onClick={props.backHandler}
               >
                  <i className="fas fa-arrow-left"></i>
                  &nbsp; {backLabel ? backLabel : 'Atrás'}
               </button>
            </div>
         ) : (
            ''
         )}
         <div className="justify-end align-center full wow fadeIn">
            {props.children}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {showFilter ? (
               <button
                  className="btn btn-secondary btn-small color-white "
                  onClick={props.newHandler}
               >
                  <i className="fas fa-plus"></i>
                  &nbsp; Nuevo
               </button>
            ) : null}
         </div>
         {props.setFilter && (
            <div className="input-clean">
               <input
                  className="input input-filter"
                  type="text"
                  name="textFilter"
                  id="filterData"
                  placeholder="Buscar"
                  onChange={event => props.setFilter(event.target.value)}
               ></input>
               <i className="fas fa-search font-small"></i>
            </div>
         )}
      </div>
   );
};

export default Controls;

import Request from '../../core/httpClient';
const req = new Request();

export function loginSuccess(event) {
   localStorage.setItem('bdusr', JSON.stringify(event));
   this.setState({
      auth: true,
      token: event.token,
   });
}

export function logOut() {
   window.localStorage.setItem('bdusr', '{"auth": false}');
   window.localStorage.removeItem('menusr');
   window.localStorage.removeItem('menuActivo');
   window.localStorage.removeItem('menuParent');
   this.setState({
      auth: false,
      token: undefined,
   });
   window.location = '/login';
}

export async function getId(token) {
   if (token) {
      const res = await req.post('usuario/get/id', { token });
      if (res.id_usuario) {
         return res.id_usuario;
      }
   }
}

export async function loadUser() {
   let session = JSON.parse(window.localStorage.getItem('bdusr'));
   if (session) {
      const data = {
         token: session.token,
      };

      const user = await req.post('users/decode/token', data);

      return {
         ...user,
         token: session.token,
      };
   } else {
      return { error: true };
   }
}

import React, { useState, useEffect } from 'react';

const ModalProyecto = props => {
   const [proyecto, setProyecto] = useState({});

   useEffect(() => {
      setProyecto(props.proyecto);
   }, [props.proyecto]);

   function updateProyecto(pry) {
      props.updateJson({
         proyecto: pry,
      });
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-8"></div>
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Nombre:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={proyecto.Nombre ? proyecto.Nombre : ''}
                     onChange={event => {
                        let pry = proyecto;
                        pry.Nombre = event.target.value;
                        updateProyecto(pry);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Dirección:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={proyecto.Direccion ? proyecto.Direccion : ''}
                     onChange={event => {
                        let pry = proyecto;
                        pry.Direccion = event.target.value;
                        updateProyecto(pry);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Ciudad:</b>
                  </p>
               </div>
               <div className="column column-small">
                  <input
                     type="text"
                     className="input input-modals"
                     value={proyecto.Ciudad ? proyecto.Ciudad : ''}
                     onChange={event => {
                        let pry = proyecto;
                        pry.Ciudad = event.target.value;
                        updateProyecto(pry);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
               <div className="column label-middle">
                  <p>
                     <b>CP:</b>
                  </p>
               </div>
               <div className="column column-small">
                  <input
                     type="text"
                     className="input input-modals"
                     value={proyecto.CP ? proyecto.CP : ''}
                     onChange={event => {
                        let pry = proyecto;
                        pry.CP = event.target.value;
                        updateProyecto(pry);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Ubicación:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={proyecto.Ubicacion ? proyecto.Ubicacion : ''}
                     onChange={event => {
                        let pry = proyecto;
                        pry.Ubicacion = event.target.value;
                        updateProyecto(pry);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>No Unidades:</b>
                  </p>
               </div>
               <div className="column full">
                  <input
                     type="text"
                     className="input input-modals"
                     value={proyecto.Ubicacion ? proyecto.NoUnidades : ''}
                     onChange={event => {
                        let pry = proyecto;
                        pry.NoUnidades = event.target.value;
                        updateProyecto(pry);
                     }}
                     autoComplete="off"
                     minLength={2}
                     required
                  />
               </div>
            </div>
            <div className="white-space-8" />
         </div>
      </div>
   );
};
export default ModalProyecto;

/* eslint-disable eqeqeq */
import request from 'superagent';
import { URL_API } from './urlsApi';

const session = JSON.parse(window.localStorage.getItem('bdusr'));

class Request {
   async get(url, data) {
      const result = request
         .get(URL_API + url)
         .query(data)
         .set({
            api_key: '87882e138de18177515be74e7e098cd81a79cc44fcfb0097e55230b2280df6b1',
         })
         .set({
            auth: session ? session.token : '',
         })
         .then((res) => {
            return res.body;
         })
         .catch((err) => {
            return {
               error: true,
               message: err.message,
            };
         });
      return result;
   }

   async post(url, data) {
      const result = request
         .post(URL_API + url)
         .send(data)
         .set({
            api_key: '87882e138de18177515be74e7e098cd81a79cc44fcfb0097e55230b2280df6b1',
         })
         .set({
            auth: session ? session.token : '',
         })
         .then((res) => {
            return res.body;
         })
         .catch((err) => {
            return {
               error: true,
               message: err.message,
            };
         });
      return result;
   }

   async put(url, data) {
      const result = request
         .put(URL_API + url)
         .send(data)
         .set({
            api_key: '87882e138de18177515be74e7e098cd81a79cc44fcfb0097e55230b2280df6b1',
         })
         .set({
            auth: session.token ? session.token : '',
         })
         .then((res) => {
            return res.body;
         })
         .catch((err) => {
            return {
               error: true,
               message: err.message,
            };
         });
      return result;
   }

   async patch(url, data) {
      return await request
         .patch(URL_API + url)
         .send(data)
         .set({
            api_key: '87882e138de18177515be74e7e098cd81a79cc44fcfb0097e55230b2280df6b1',
         })
         .set({
            auth: session.token ? session.token : '',
         })
         .then((res) => {
            return res.body;
         })
         .catch((err) => {
            return {
               error: true,
               message: err.message,
            };
         });
   }

   async postFile(url, files, data) {
      const response = await new Promise((res) => {
         const postRequest = request.post(`${URL_API}${url}`);

         if (Array.isArray(files)) {
            files.forEach((file, index) => {
               if (file) {
                  postRequest.attach(`file_${index + 1}`, file);
               }
            });
         }
         if (data) {
            const keys = Object?.keys(data) || [];
            keys.forEach((key) => {
               if (data[key] != undefined || data[key] != null) {
                  postRequest.field(key, data[key]);
               }
            });
         }

         postRequest
            .set(
               'api_key',
               '87882e138de18177515be74e7e098cd81a79cc44fcfb0097e55230b2280df6b1'
            )
            .set({
               auth: session ? session.token : '',
            })
            .set('Accept', 'application/json')
            .then((resp) => {
               res(
                  resp.body || {
                     error: true,
                     message: 'error indefinido',
                  }
               );
            })
            .catch((error) => {
               res(error);
            });
      });

      return response;
   }

   delete(url, data) {
      const result = request
         .delete(URL_API + url)
         .query(data)
         .set({
            api_key: '87882e138de18177515be74e7e098cd81a79cc44fcfb0097e55230b2280df6b1',
         })
         .set({
            auth: session ? session.token : '',
         })
         .then((res) => {
            return res.body;
         })
         .catch((err) => {
            return {
               error: true,
               message: err.message,
            };
         });
      return result;
   }
}

export default Request;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cogoToast from 'cogo-toast';

import Request from '../../../core/httpClient';

const req = new Request();

const ModalEquipoDetalles = (props) => {
   const { equipo, problemas, dateOpenModal } = props;

   const [loadingData, setLoadingData] = useState(false);
   const [equipoProblemas, setEquipoProblemas] = useState([]);
   const [newEquipoDetalle, setNewEquipoDetalle] = useState(0);
   const [loadingAdd, setLoadingAdd] = useState(false);
   const [loadRemoving, setLoadRemoving] = useState(false);

   useEffect(() => {
      (async () => {
         setNewEquipoDetalle(0);
         await getEquipoProblemas();
      })();
   }, [dateOpenModal]);

   async function getEquipoProblemas() {
      try {
         if (equipo.IdEquipo > 0) {
            setLoadingData(true);

            const response = await req.get('config/get/equipo/problemas', {
               IdEquipo: equipo.IdEquipo,
            });

            if (!response.error || !response.empty) {
               setEquipoProblemas(response.data);
            } else {
               cogoToast.error(`Ha ocurrido un error: ${response.message}`, {
                  position: 'top-right',
               });
               setEquipoProblemas([]);
            }

            setLoadingData(false);
         }
      } catch (error) {
         setLoadingData(false);
         setEquipoProblemas([]);
      }
   }

   async function addEquipoDetalle(event) {
      try {
         if (newEquipoDetalle) {
            setLoadingAdd(true);

            event.preventDefault();

            let data = {
               IdEquipo: equipo.IdEquipo,
               IdProblema: newEquipoDetalle,
            };

            let requestApi = await req.post('config/create/equipo/problema', data);

            if (requestApi.response) {
               cogoToast.success('Detalle agregado con éxito.', {
                  position: 'top-right',
               });
               await getEquipoProblemas();
            } else if (requestApi.existe) {
               cogoToast.error(`Ha ocurrido un error: ${requestApi.existe}`, {
                  position: 'top-right',
               });
            } else {
               cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
                  position: 'top-right',
               });
            }

            setNewEquipoDetalle(0);
            setLoadingAdd(false);
         }
      } catch (error) {
         setLoadingAdd(false);
      }
   }

   async function deleteEquipoDetalle(item) {
      try {
         setLoadRemoving(item.IdEquipoTipoProblema);

         let data = {
            IdEquipoTipoProblema: item.IdEquipoTipoProblema,
         };

         let requestApi = await req.post('config/delete/equipo/problema', data);

         if (requestApi.response) {
            cogoToast.success('Detalle eliminado con éxito.', {
               position: 'top-right',
            });
            await getEquipoProblemas();
         } else {
            cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {
               position: 'top-right',
            });
         }

         setLoadRemoving(false);
      } catch (error) {
         setLoadRemoving(false);
      }
   }

   return (
      <div className="full row align-center">
         <div className="column full">
            <div className="white-space-16"></div>
            <div className="row full align-center">
               <div className="column label-normal">
                  <p>
                     <b>Detalle:</b>
                  </p>
               </div>
               <div className="row full align-center">
                  <div className="column full">
                     <select
                        className="input input-modals"
                        value={newEquipoDetalle}
                        onChange={(event) => {
                           setNewEquipoDetalle(event.target.value);
                        }}>
                        <option value={0} disabled>
                           Elija un detalle
                        </option>
                        {problemas.map((item, key) => (
                           <option key={key} value={item.IdProblema}>
                              {item.NombreProblema}
                           </option>
                        ))}
                     </select>
                  </div>
                  &nbsp; &nbsp;
                  <Link
                     to="#"
                     className="color-black"
                     onClick={!loadingAdd ? addEquipoDetalle.bind(this) : null}>
                     {loadingAdd ? (
                        <i className="fas fa-spinner fa-spin"></i>
                     ) : (
                        <i className="fas fa-plus-circle"></i>
                     )}
                  </Link>
               </div>
            </div>
            <div className="white-space-16" />
            <div className="row full align-center">
               <div className="column card-table full align-center card-table-modal">
                  <div className="table-responsive full">
                     <table>
                        <thead>
                           <tr>
                              <th className="text-left">Detalle</th>
                              <th className="text-center th-lg">Eliminar</th>
                           </tr>
                        </thead>
                        <tbody>
                           {loadingData ? (
                              <tr>
                                 <td colSpan="3">
                                    <div className="white-space-8"></div>
                                    <div className="justify-center">
                                       <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    <div className="white-space-8"></div>
                                 </td>
                              </tr>
                           ) : equipoProblemas?.length === 0 ? (
                              <tr>
                                 <td className="text-center" colSpan="3">
                                    Sin datos que mostrar
                                 </td>
                              </tr>
                           ) : (
                              equipoProblemas?.map((item, i) => {
                                 return (
                                    <tr key={i}>
                                       <td>{item.NombreProblema}</td>
                                       <td className="text-center">
                                          {loadRemoving === item.IdEquipoTipoProblema ? (
                                             <i className="fas fa-spinner fa-spin"></i>
                                          ) : (
                                             <i
                                                className="fas fa-times-circle color-red cursor-pointer space-4"
                                                onClick={deleteEquipoDetalle.bind(
                                                   this,
                                                   item
                                                )}></i>
                                          )}
                                       </td>
                                    </tr>
                                 );
                              })
                           )}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default React.memo(ModalEquipoDetalles);

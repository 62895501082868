import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router-dom';
import WOW from 'wowjs';
import cogoToast from 'cogo-toast';
import moment from 'moment-timezone';

import { Consumer } from '../context';
import { catalogTabs, catalogDetails } from '../utils/controlCostsCatalog';

import ModalContainer from '../components/controlCostos/ModalContainer';
import Controls from '../components/Controls';
import Sidebar from '../components/Sidebar';
import CatalogTable from '../components/controlCostos/CatalogTable';
import DetailsSelector from '../components/controlCostos/components/DetailsSelector';
import { costsControlApi } from '../api';
import { manipulateDate } from '../utils/manipulateDate';

class ControlOrdenesServicio extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         page: 0,
         catalogData: {},
         catalogItems: [],
         catalogFilteredItems: [],
         ordersStatus: [],
         selectedOrderStatus: '',
         startDateFilter: null,
         endDateFilter: null,
         showModal: false,
         isSaving: false,
         newItemValue: {},
         searchInput: '',
      };

      this.catalogTabs = catalogTabs;
      this.catalogDetails = catalogDetails;
   }

   setItems = (catalogItems) => {
      this.setState({ catalogItems, catalogFilteredItems: catalogItems });
   };

   formatDate(date) {
      return date.toISOString().split('T')[0];
   }

   async handleInitialDates() {
      const startDate = manipulateDate(new Date(), -30);
      const endDate = manipulateDate(new Date(), 0);
      this.handleChangeDate(startDate, 'start');
      this.handleChangeDate(endDate, 'end');
   }

   async componentDidMount() {
      this.handleSelectCatalog('serviceOrders');
      this.handleInitialDates();
      new WOW.WOW({
         live: false,
      }).init();
   }

   async componentDidUpdate(_, prevState) {
      if (!this.state.catalogItems) return;

      if (prevState.catalogItems !== this.state.catalogItems) {
         await this.filterItemsByDate(
            this.state.startDateFilter,
            this.state.endDateFilter
         );
      }

      if (this.state.ordersStatus.length === 0) {
         const prevCatalogItems = prevState.catalogItems || [];
         const currentCatalogItems = this.state.catalogItems;
         if (prevCatalogItems !== currentCatalogItems) {
            const orderFilters = await costsControlApi.getOrdersFilters();
            this.setState({ ordersStatus: orderFilters });
         }
      }

      if (prevState.selectedOrderStatus !== this.state.selectedOrderStatus) {
         await this.filterItemsByStatus(this.state.selectedOrderStatus);
      }

      if (
         prevState.startDateFilter !== this.state.startDateFilter ||
         prevState.endDateFilter !== this.state.endDateFilter
      ) {
         if (this.state.startDateFilter && this.state.endDateFilter)
            await this.filterItemsByDate(
               this.state.startDateFilter,
               this.state.endDateFilter
            );
      }
   }

   async handleSelectCatalog(catalog) {
      const catalogData = this.catalogDetails[catalog];
      this.setState({
         catalogData: catalogData,
      });
   }

   async handleEditItem(newValue, attrToChange) {
      this.setState((prevState) => ({
         newItemValue: {
            ...prevState.newItemValue,
            [attrToChange]: newValue,
         },
      }));
   }

   async handleShowModal() {
      this.setState({
         showModal: !this.state.showModal,
      });
   }

   async handleCreateItem(e) {
      e.preventDefault();
      const { catalogData, newItemValue } = this.state;
      if (!catalogData.validateData(newItemValue)) {
         cogoToast.error('Por favor, ingrese los datos faltantes', {
            position: 'top-right',
         });
         return;
      }
      this.setState({
         isSaving: true,
      });
      await catalogData.createItem(newItemValue);
      this.setState({
         isSaving: false,
      });

      this.handleShowModal();
      this.setState({
         catalogItems: await catalogData.getItems(),
      });

      this.setState({
         newItemValue: {},
      });
   }

   async handleChangeDate(date, dateFilter) {
      const parsedDate = new Date(Date.parse(date));
      const utcDate = new Date(
         parsedDate.getUTCFullYear(),
         parsedDate.getUTCMonth(),
         parsedDate.getUTCDate(),
         parsedDate.getUTCHours(),
         parsedDate.getUTCMinutes(),
         parsedDate.getUTCSeconds()
      );

      if (dateFilter === 'start')
         this.setState({
            startDateFilter: utcDate,
            startDate: date,
         });
      else
         this.setState({
            endDateFilter: utcDate,
            endDate: date,
         });
   }

   async handleChangeOrderStatus(status) {
      this.setState({ selectedOrderStatus: status });
   }

   async filterItemsByDate(startDate, endDate) {
      if (!startDate || !endDate) return;
      const items = this.state.catalogItems;

      const parsedItems = items.map((item) => {
         const [day, month, year] = item.start_date.split('/');
         const formatedDate = `${month}/${day}/${year}`;
         const parsedDate = new Date(Date.parse(formatedDate));

         return {
            ...item,
            start_date: parsedDate,
         };
      });

      const filteredItems = parsedItems.filter((item) => {
         return item.start_date >= startDate && item.start_date <= endDate;
      });

      const revertedItems = filteredItems.map((item) => {
         return {
            ...item,
            start_date: moment(item.start_date).format('DD/MM/YYYY'),
         };
      });

      this.setState({
         catalogFilteredItems: revertedItems,
      });
   }

   async filterItemsByStatus(status) {
      if (status === 'todos') {
         this.setState({ catalogFilteredItems: this.state.catalogItems });
         return;
      }

      const items = this.state.catalogItems;
      const filteredItems = items.filter((item) => item.status.id === parseInt(status));

      this.setState({ catalogFilteredItems: filteredItems });
   }

   render() {
      return (
         <div className="column justify-center align-center catalogo">
            <Helmet>
               <title>ELEVA Capital Group | Catálogos</title>
            </Helmet>
            <div className="row full">
               <Sidebar />
               <div className="column align-center full">
                  <div className="row">
                     <div className="white-space-48"></div>
                  </div>

                  <div className="row full justify-center wow fadeIn">
                     <div className="container justify-start align-center">
                        <h3 className="color-secondary">Control costos</h3>
                        <h3 className="color-secondary">&nbsp;/&nbsp;</h3>
                        <h4 className="color-darkgray">Ordenes de servicio</h4>
                     </div>
                  </div>
                  <div className="row">
                     <div className="white-space-8"></div>
                  </div>
                  <div className="row full justify-center">
                     <div className="column full">
                        <div className="row">
                           <div className="white-space-8"></div>
                        </div>
                        <div className="row justify-center align-center">
                           <Controls
                              newHandler={this.handleShowModal.bind(this)}
                              showFilter={true}>
                              <div
                                 className="row align-center"
                                 style={{ marginRight: '20px' }}>
                                 <div className="column label-normal">
                                    <p style={{ marginRight: '10px' }}>
                                       <b>{`Desde:`}</b>
                                    </p>
                                 </div>
                                 <div className="column ">
                                    <input
                                       className="input"
                                       type="date"
                                       value={this?.state?.startDate || ''}
                                       onChange={(e) =>
                                          this.handleChangeDate(e.target.value, 'start')
                                       }
                                    />
                                 </div>
                              </div>

                              <div
                                 className="row align-center"
                                 style={{ marginRight: '20px' }}>
                                 <div className="column label-normal">
                                    <p style={{ marginRight: '10px' }}>
                                       <b>{`Hasta:`}</b>
                                    </p>
                                 </div>
                                 <div className="column ">
                                    <input
                                       className="input"
                                       type="date"
                                       value={this?.state?.endDate || ''}
                                       onChange={(e) =>
                                          this.handleChangeDate(e.target.value)
                                       }
                                    />
                                 </div>
                              </div>

                              <div className="row align-center">
                                 <div className="column label-normal">
                                    <p style={{ marginRight: '10px' }}>
                                       <b>{`Estado:`}</b>
                                    </p>
                                 </div>
                                 <div className="column">
                                    <select
                                       className="input input-modals"
                                       defaultValue={0}
                                       onChange={(e) =>
                                          this.handleChangeOrderStatus(e.target.value)
                                       }>
                                       <option value={0} disabled>
                                          Seleccionar
                                       </option>
                                       <option value={'todos'}>Todos</option>
                                       {this.state.ordersStatus.map((status, index) => (
                                          <option
                                             value={status.id}
                                             key={`${status.id}-${index}`}>
                                             {status.name}
                                          </option>
                                       ))}
                                    </select>
                                 </div>
                              </div>
                           </Controls>
                        </div>
                        <div className="row">
                           <div className="white-space-16"></div>
                        </div>
                        <div className="row full justify-center">
                           <div className="container">
                              <div
                                 className="column card-table full align-center table-side wow fadeIn"
                                 data-wow-delay="0.3s">
                                 {/* Tablas */}
                                 <div className="mt-search input-clean">
                                    <input
                                       type="text"
                                       placeholder="Buscar"
                                       className="input input-filter"
                                       onChange={(e) => {
                                          this.setState({
                                             searchInput: e.target.value,
                                          });
                                       }}
                                    />
                                    <i className="fas fa-search font-small" />
                                 </div>
                                 <CatalogTable
                                    catalog={this.state.catalogData}
                                    items={
                                       this.state.catalogFilteredItems ||
                                       this.state.catalogItems
                                    }
                                    setItems={this.setItems}
                                    search={this.state.searchInput}
                                    editModalClassName="dinamic-modal-super-height"
                                 />
                                 <div className="row">
                                    <div className="white-space-8"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {this.state.showModal && (
                  <ModalContainer
                     size={'normal'}
                     title={`Añadir ${this.state.catalogData.itemName}`}
                     btnSuccessText="Guardar"
                     onCancel={this.handleShowModal.bind(this)}
                     onClose={this.handleShowModal.bind(this)}
                     onSuccess={this.handleCreateItem.bind(this)}
                     isLoading={this.state.isSaving}
                     className="dinamic-modal-super-height">
                     <DetailsSelector
                        select={this.state.catalogData.detailsComponent}
                        item={this.state.newItemValue}
                        onEditItem={this.handleEditItem.bind(this)}
                     />
                  </ModalContainer>
               )}
            </div>
         </div>
      );
   }
}
export default withRouter(Consumer(ControlOrdenesServicio));
